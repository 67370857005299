import React from "react";
import { Col, Container, Button, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../Context/AuthContext";
import { ConfigService } from "../../../Services/systemConfigService";
import { UserService } from "../../../Services/userService";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { PageBody } from "../../../Component/Loader";
import { phoneRegExp } from "../../../Util/Constants/constant";

const Styles = styled.div``;

export const Edit_user = ({ match }) => {
  const { alert } = React.useContext(AuthContext);
  const [roles, setRoles] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getRoles = async () => {
    updateLoader(true, "Loading");
    const roles = await ConfigService.getroles();
    setRoles(roles.data.data);
    updateLoader(false);
  };
  const getUser = async () => {
    updateLoader(true, "Fetching User");
    const response = await UserService.getuser(match.params.id);
    setUser(response.data.data);
    updateLoader(false);
  };
  React.useEffect(() => {
    getRoles();
    getUser();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />

        <Container className="mt-5">
          <h5 className="form-h mb-3">
            {" "}
            <strong>Edit User Details</strong>{" "}
          </h5>

          {user !== null ? (
            <Formik
              initialValues={{
                first_name: user.first_name,
                last_name: user.last_name,
                phonenumber: user.phonenumber,
                role_id: user.role_id || "",
                status: user.status,
                email: user.email,
              }}
              validationSchema={Yup.object({
                first_name: Yup.string()
                  .trim()
                  .required("first name is a required field"),

                last_name: Yup.string()
                  .trim()
                  .required("last name is a required field"),

                phonenumber: Yup.string()
                  .required("phone number is a required field")
                  .max(14, "phone number must be 14 digits maximum")
                  .min(10, "phone number must be 11 digits minimum")
                  .matches(phoneRegExp, "Phone number is not valid"),

                role_id: Yup.number()
                  .min(1, "please select from the options")
                  .required("role is a required field"),

                email: Yup.string().email("Invalid email address").required(),
              })}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                const response = await UserService.updateuser(user.id, values);
                if (response.status === 200) {
                  setSubmitting(false);
                  alert.success(response.data.message);
                  history.push("/users");
                } else {
                  if (response.response.status === 404)
                    alert.error(response.response.data.message);
                  if (response.response.data.data.email)
                    alert.error(response.response.data.data.email);
                  if (response.response.data.data.phonenumber)
                    alert.error(response.response.data.data.phonenumber);
                }
              }}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                dirty,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        User Status{" "}
                        <small className="text-muted">
                          ( activate or deactivate )
                        </small>{" "}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                      >
                        <option value={1}>Activate</option>
                        <option value={0}>Deactivate</option>
                      </Form.Control>
                      {touched.status && errors.status ? (
                        <div className="text-danger">{errors.status}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} md={5}></Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        First name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      {touched.first_name && errors.first_name ? (
                        <div className="text-danger">{errors.first_name}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Last name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      {touched.last_name && errors.last_name ? (
                        <div className="text-danger">{errors.last_name}</div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Phone number <span className="text-danger">*</span>
                      </Form.Label>
                      {/* <InputGroup className="mb-2"> */}
                      {/* <InputGroup.Prepend>
                          <InputGroup.Text>
                            <strong>+</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend> */}
                      <Form.Control
                        type="text"
                        name="phonenumber"
                        value={values.phonenumber}
                        onChange={handleChange}
                      />
                      {touched.phonenumber && errors.phonenumber ? (
                        <div className="text-danger">{errors.phonenumber}</div>
                      ) : null}
                      {/* </InputGroup> */}
                    </Form.Group>

                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Role <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="role_id"
                        value={values.role_id}
                        onChange={handleChange}
                      >
                        <option value="0">Choose...</option>
                        {roles.map((role, i) => (
                          <option value={role.id} key={i}>
                            {role.name}
                          </option>
                        ))}
                      </Form.Control>
                      {touched.role_id && errors.role_id ? (
                        <div className="text-danger">{errors.role_id}</div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={10}>
                      <Form.Label>
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {touched.email && errors.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Group>
                    <div className="mt-3 text-center">
                      <Link to="/users">
                        <Button className="btn-cancel m-3 shadow">
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        className="btn-save m-3 shadow"
                        disabled={!dirty}
                      >
                        Update{" "}
                        {isSubmitting ? (
                          <span
                            id="login_spinner"
                            className="fa fa-spin fa-spinner"
                          ></span>
                        ) : null}
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          ) : null}
        </Container>
      </Styles>
    </PageBody>
  );
};
