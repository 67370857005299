import {ApiClient} from '../Util/ApiClient'


export class TaskService{


    static async updatetask(id,body){
        try{
            const response = await ApiClient.patch(`/tasks/update-task/${id}`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async gettasks(){
        try{
            const response = await ApiClient.get('/tasks')
           return response
        }catch(err){
            return err
        }
       
    }

    static async getpending(){
        try{
            const response = await ApiClient.get('/tasks/get-pending/user')
           return response
        }catch(err){
            return err
        }
       
    }
    static async getcompleted(){
        try{
            const response = await ApiClient.get('/tasks/get-completed/user')
           return response
        }catch(err){
            return err
        }
       
    }

    static async getongoing(){
        try{
            const response = await ApiClient.get('/tasks/get-on-going/user')
           return response
        }catch(err){
            return err
        }
       
    }

    static async createtask(body){
        try{
            const response = await ApiClient.post('/tasks/create-task',body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async gettask(id){
        try{
            const response = await ApiClient.get(`/tasks/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async deletetask(id){
        try{
            const response = await ApiClient.delete(`/tasks/delete-task/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    //task in business for client
    static async updateBusinesstask(id,body){
        try{
            const response = await ApiClient.patch(`/tasks/update-task/${id}`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getBusinesstasks(id){
        try{
            const response = await ApiClient.get(`/client/tasks/0/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getClienttasks(id){
        try{
            const response = await ApiClient.get(`/client/tasks/${id}/0`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getClientpending(id){
        try{
            const response = await ApiClient.get(`/tasks/get-pending/user/${id}/0`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getClientcompleted(id){
        try{
            const response = await ApiClient.get(`/tasks/get-completed/user/${id}/0`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getClientongoing(id){
        try{
            const response = await ApiClient.get(`/tasks/get-on-going/user/${id}/0`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getBusinesspending(id){
        try{
            const response = await ApiClient.get(`/tasks/get-pending/user/0/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getBusinesscompleted(id){
        try{
            const response = await ApiClient.get(`/tasks/get-completed/user/0/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getBusinessongoing(id){
        try{
            const response = await ApiClient.get(`/tasks/get-on-going/user/0/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async createBusinesstask(body){
        try{
            const response = await ApiClient.post('/tasks/create-task',body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getBusinesstask(id){
        try{
            const response = await ApiClient.get(`/tasks/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async deleteBusinesstask(id){
        try{
            const response = await ApiClient.delete(`/tasks/delete-task/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }
   
    
}
