import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import product from "../../../../Assets/Img/products/shoes.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../Context/AuthContext";
// import { ConfigService } from '../../../../../Services/systemConfigService';
// import { AuthContext } from '../../../../../Context/AuthContext';
import { PageBody } from "../../../../Component/Loader";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { formattCurrency } from "../../../../Util/formattCurrency";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import { FolderMinus } from "react-feather";

const Styles = styled.div`
  @media print {
    .btn-create {
      display: none;
    }

    .table {
      padding: 0;
    }
  }

  .cover {
    margin-left: 220px;
    margin-right: 220px;
  }
  .bg-c {
    background: #1c3faa;
    color: white;
  }
`;

export const Receipt = ({ match }) => {
  const { user } = useContext(AuthContext);
  const [report, setReport] = React.useState([]);
  const [Tax, setTax] = useState(0);
  const { isLoading, message, updateLoader } = useLoadingMessage();

  console.log(Tax);

  const getSaleReport = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getSaleReport(
      match.params.order_id
    );
    setReport(response.data.data);
    updateLoader(false);
  };

  const getDefaultTax = async () => {
    updateLoader(true, "Loading");
    const res = await ConfigCustomerService.getdefaulttax(match.params.id);
    if (res.data !== undefined) {
      setTax(res.data.data.rate);
    }
    updateLoader(false);
  };

  const dateFormat = (data) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(data);
    return date.toLocaleDateString("en-US", options);
  };

  const calcIndivTax = (total_price, status) => {
    let price = total_price;
    let taxAmount = (price /= Tax / 100 + 1);
    let percentageAmount = (Tax / 100) * taxAmount;
    if (status === "isVat") return percentageAmount;
    if (status === "isTotal") return taxAmount;
  };

  const sub =
    report.items &&
    report.items.map((item) =>
      item.service
        ? report.total_paid
        : item.product
        ? item.price * item.quantity
        : null
    );

  let orderItems = "";
  report.items &&
    report.items.map((item) =>
      item.service
        ? (orderItems = "service")
        : item.product
        ? (orderItems = "product")
        : null
    );

  console.log(orderItems);

  const sub_total = sub && sub.length !== 0 && sub.reduce((a, b) => a + b);
  // const vat_total = vat && vat.reduce((a, b) => a + b);

  let price = sub_total;
  let total_amount = (price /= report.total_tax / 100 + 1);
  let percentageAmount = (report.total_tax / 100) * total_amount;

  console.log(report);

  useEffect(() => {
    getSaleReport();
    getDefaultTax();
  }, []);

  return (
    <Styles>
      <PageBody isLoading={isLoading} message={message}>
        <Navbar />

        <Container className="cover">
          <Col md={6}>
            <Button
              className="btn-create mr-3 mt-4"
              onClick={() => window.print()}
            >
              Print
            </Button>
            <Link to={`/sales/${match.params.id}`}>
              <Button className="btn-create mt-4">Continue shopping</Button>
            </Link>
          </Col>
          <Col md={6}></Col>
        </Container>

        <Container fluid className="mt-5">
          <div className="cover">
            <div className="card">
              <div className="card-body bg-c clearfix p-5">
                {/* <h4 className="card-title float-left">Shop name</h4> */}
                <h4 className="card-title float-right">RECEIPT</h4>

                <Table className="table table-borderless text-white mt-5">
                  <tbody>
                    <tr>
                      <th scope="col">Receipt No:</th>
                      <td scope="col">34232543543</td>
                    </tr>
                    <tr>
                      <th scope="col">Date:</th>
                      <td scope="col">{dateFormat(report.created_at)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="">
              <Table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Products/Service</th>
                    {orderItems === "product" && <th scope="col">Quantity</th>}
                    <th scope="col">Price/Cost</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Vat({Tax}%)</th>
                    <th scope="col">Total Amount</th>
                    {orderItems === "service" &&
                      report.total_amount !== report.total_paid && (
                        <th scope="col">Balance</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {report.items &&
                    report.items.map((item) => (
                      <tr>
                        <td scope="row">
                          {item.service
                            ? item.service.service_name
                            : item.product
                            ? item.product.product_name
                            : null}
                        </td>
                        {orderItems === "product" && (
                          <td>{report.items && item.quantity}</td>
                        )}
                        <td>
                          ₦{" "}
                          {item.service
                            ? formattCurrency(item.price)
                            : item.product
                            ? formattCurrency(item.price)
                            : null}
                        </td>
                        <td>{item.discount > 0 ? `₦ ${item.discount}` : ""}</td>
                        <td>
                          ₦{" "}
                          {item.service
                            ? formattCurrency(
                                calcIndivTax(report.total_paid, "isVat")
                              )
                            : item.product
                            ? formattCurrency(
                                calcIndivTax(
                                  item.price * item.quantity,
                                  "isVat"
                                )
                              )
                            : null}
                        </td>{" "}
                        <td>
                          ₦{" "}
                          {item.service
                            ? formattCurrency(
                                calcIndivTax(report.total_paid, "isTotal")
                              )
                            : item.product
                            ? formattCurrency(
                                calcIndivTax(
                                  item.price * item.quantity,
                                  "isTotal"
                                )
                              )
                            : null}
                        </td>
                        {orderItems === "service" &&
                          report.total_amount !== report.total_paid && (
                            <td>
                              ₦{" "}
                              {formattCurrency(
                                report.total_amount - report.total_paid
                              )}
                            </td>
                          )}
                      </tr>
                    ))}
                </tbody>
              </Table>

              <div className="clearfix">
                <div className="mt-5">
                  <strong className="text-muted">{`${user.first_name} ${user.last_name}`}</strong>
                  <p className="text-muted">
                    <em>{user.role.name}</em>
                  </p>
                </div>

                <div className="float-right">
                  <table className="table table-borderless">
                    <tbody>
                      {report && (
                        <>
                          <tr>
                            <th scope="row">Sub total:</th>
                            <td>₦{formattCurrency(total_amount)}</td>
                          </tr>
                          <tr>
                            <th scope="row">VAT:</th>
                            <td>₦{formattCurrency(percentageAmount)}</td>
                          </tr>
                          <tr>
                            <th scope="row">Total:</th>
                            <td>
                              ₦{" "}
                              {formattCurrency(total_amount + percentageAmount)}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </PageBody>
    </Styles>
  );
};
