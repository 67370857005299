import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Tab,
  Tabs,
  Row,
  Table,
  Dropdown,
  Badge,
  Pagination,
  DropdownButton,
} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import Product from "../../../../Assets/Img/products/shoes.png";
import * as moment from "moment";
// import { ClientSalesService } from '../../../../Services/clientSalesService';
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { AuthContext } from "../../../../Context/AuthContext";
import { PageBody } from "../../../../Component/Loader";
import { formattCurrency } from "../../../../Util/formattCurrency";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import { userType } from "../../../../redux/actionTypes";

const Styles = styled.div`
  .dropdown-toggle::after {
    display: none !important;
  }
`;

export const Sales = ({ match }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [reports, setReport] = useState([]);
  const [services, setServices] = useState([]);
  const [searchproduct, setSearchproduct] = useState("");
  const [searchservices, setSearchservices] = useState("");
  const { alert, user_type } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [cartNumber, setcartNumber] = useState();
  const [searchReports, setSearchReports] = useState("");
  const [searchManageReports, setSearchManageReports] = useState("");
  const [Tax, setTax] = useState(0);
  const [value, setValue] = useState("");
  const [select, setSelect] = useState("");

  const [activeTab, setActiveTab] = useState("Products");

  {(window.localStorage.getItem('sal')===null) && window.localStorage.setItem('sal', JSON.stringify("Products"))}

  const getProducts = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getproducts(match.params.id);
    setProducts(response.data.data);
    updateLoader(false);
  };

  console.log(process.env.REACT_APP_IMAGE_URL);

  const getSalesReport = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getSalesReport(
      match.params.id
    );
    setReport(response.data.data);
    updateLoader(false);
  };

  const getDefaultTax = async () => {
    updateLoader(true, "Fetching Customers");
    const res = await ConfigCustomerService.getdefaulttax(match.params.id);
    if (res.data !== undefined) {
      setTax(res.data.data.rate);
    }
    updateLoader(false);
  };

  const getProductCategories = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategories(response.data.data);
    updateLoader(false);
  };

  console.log(user_type);

  const addCart = (product) => {
    if (user_type === "admin")
      return alert.error("you don't permission to perform this action");
    if (product.quantity < 1)
      return alert.error("Can't add a product with 0 quantity in stock");
    let discount = 0;
    const discounts =
      product && product.discounts
        ? product.discounts.map((dis) => (discount += dis.percentage))
        : null;
    let discAmount = product.selling_price * (discount / 100);
    const products = JSON.parse(localStorage.getItem("products") || "[]");
    const item = {
      id: product.id,
      name: product.product_name,
      quantity: 1,
      price:
        discAmount !== 0
          ? product.selling_price - discAmount
          : product.selling_price,
      cost:
        discAmount !== 0
          ? product.selling_price - discAmount
          : product.selling_price,
      discount: discAmount,
      total_discount: discAmount,
      amount: product.selling_price,
      tax: Tax,
      quantity_in_stock: product.quantity,
    };

    const exist = products.find((item) => item.id === product.id);

    if (exist) {
      alert.error("added to cart already");
    } else {
      products.push(item);
      localStorage.setItem("products", JSON.stringify(products));
      const number = JSON.parse(localStorage.getItem("products") || "[]");
      setcartNumber(number.length);
      alert.success("added to cart successfully");
    }
  };
  const getServices = async () => {
    updateLoader(true, "Loading");
    const role = await ClientInventoryService.getservices(match.params.id);
    setServices(role.data.data);
    updateLoader(false);
  };

  const handleSelectChange = (e) => {
    console.log("se", select);
    setSelect(e.target.values);
    console.log(e.target.values);
    const newp = products.filter(
      (item) =>
        item.product_category.category_name.toString().indexOf("cloths") !== -1
    );
    setProducts(newp);
  };

  console.log("s", select);

  const handleFilter = (event) => {
    setValue(event.target.value);
  };

  console.log(value);

  const allProducts = products.filter((product) => {
    return (
      product.product_name
        .toLowerCase()
        .indexOf(searchproduct.toLowerCase()) !== -1
    );
  });

  const allServices = services.filter((service) => {
    return (
      service.service_name
        .toLowerCase()
        .indexOf(searchservices.toLowerCase()) !== -1
    );
  });

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    console.log("you select ", select);
  };

  const allReports = reports.filter((report) => {
    return (
      report.id
        .toString()
        .toLowerCase()
        .indexOf(searchReports.toLowerCase()) !== -1
    );
  });

  const allManageReports = reports.filter((report) => {
    return (
      report.id
        .toString()
        .toLowerCase()
        .indexOf(searchManageReports.toLowerCase()) !== -1
    );
  });

  useEffect(() => {
    getServices();
    getProducts();
    getSalesReport();
    getDefaultTax();
    getProductCategories(); 
    const actTab = window.localStorage.getItem('sal');
    setActiveTab(JSON.parse(actTab));
   
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('sal', JSON.stringify(activeTab));
  });

  
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />
        <Container fluid className="mt-5">
          <Tabs defaultActiveKey={activeTab} 
          activeTab={activeTab}
          onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="Products" title="Products">
              <Form inline className="mb-4">
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      value={searchproduct}
                      onChange={(e) => setSearchproduct(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  {/* <form onSubmit={handleFilterSubmit}>
                    <div className="input-group">
                      <Form.Control
                        as="select"
                        name="filter"
                        onChange={handleSelectChange}
                        value={select}
                      >
                        {categories.map((cat, i) => (
                          <option key={i} value={cat.category_name}>
                            {cat.category_name}
                          </option>
                        ))}{" "}
                      </Form.Control>
                    </div> */}
                  <div className="input-group">
                    <Form.Control as="select" name="filter">
                      <option value={0}>All</option>
                      <option value="male">some</option>
                      <option value="female">others</option>
                    </Form.Control>
                  </div>
                  {/* <input type="submit" value="Submit" /> */}
                  {/* </form> */}
                </Col>
                <Col md={3}>
                  <Link to={`/cart/${match.params.id}`}>
                    <h4>
                      <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                      <sup>
                        <Badge variant="light shadow text-danger rounded-circle p-2">
                          {JSON.parse(localStorage.getItem("products") || "[]")
                            .length || cartNumber}
                        </Badge>
                      </sup>
                    </h4>
                  </Link>
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Products</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {allProducts < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allProducts.map((product, i) =>
                    product.status !== 0 ? (
                      <>
                        {" "}
                        <Col md={4}>
                          <Card className="product-item">
                            <Row className="no-gutters">
                              <Col md={6}>
                                <img
                                  className="card-img-top h-100 w-100"
                                  src={
                                    product.image
                                      ? `${process.env.REACT_APP_IMAGE_URL}${product.image}`
                                      : Product
                                  }
                                  alt="product"
                                />
                              </Col>
                              <Col md={6}>
                                <Card.Body>
                                  <Card.Title>
                                    {" "}
                                    {product.product_name}
                                  </Card.Title>
                                  <Card.Text className="text-danger">
                                    N {formattCurrency(product.selling_price)}
                                  </Card.Text>
                                  <Card.Text>
                                    ID : {product.product_id}
                                  </Card.Text>
                                  <div className="product-desc">
                                    <small>
                                      {product.product_descripition}
                                    </small>
                                    <small>
                                      {" "}
                                      <strong>
                                        Available in store : {product.quantity}
                                      </strong>{" "}
                                    </small>
                                  </div>
                                  <div className="clearfix mt-3">
                                    <Button
                                      className="btn bg-blue"
                                      onClick={() => addCart(product)}
                                    >
                                      Add to cart
                                    </Button>
                                  </div>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </>
                    ) : null
                  )
                )}
              </Row>
            </Tab>

            <Tab eventKey="Services" title="Services">
              <Form inline className="mb-4">
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      value={searchservices}
                      onChange={(e) => setSearchservices(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="input-group">
                    <Form.Control as="select" name="filter">
                      <option value={0}>All</option>
                      <option value="male">some</option>
                      <option value="female">others</option>
                    </Form.Control>
                  </div>
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Services</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {allServices < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allServices.map((service, i) =>
                    service.status !== 0 ? (
                      <>
                        <Col md={4}>
                          <Card>
                            <Card.Body>
                              <Card.Title className="float-left">
                                {" "}
                                <strong>{service.service_name}</strong>{" "}
                              </Card.Title>
                              <div className="clearfix"></div>

                              <h5 className="text-danger">
                                ₦ {formattCurrency(service.cost_of_service)}
                              </h5>
                              <div className="text-truncate">
                                <small>{service.description}</small>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="clearfix mt-3">
                                  {service.part_payment > 0 ? (
                                    <p className="txt-color">Part payment</p>
                                  ) : (
                                    <p className="text-muted">
                                      No Part payment
                                    </p>
                                  )}
                                </div>
                                <div className="clearfix mt-3">
                                  <Link
                                    to={`/service/${match.params.id}/${service.id}`}
                                  >
                                    {user_type === "admin" ? null : (
                                      <Button className="btn bg-blue">
                                        Pay
                                      </Button>
                                    )}
                                  </Link>{" "}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    ) : null
                  )
                )}
              </Row>
            </Tab>

            <Tab eventKey="payment" title="Manage payment">
              <Form inline className="mb-4">
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search order by id"
                      name="search"
                      value={searchManageReports}
                      onChange={(e) => setSearchManageReports(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <Form.Control as="select" name="filter">
                      <option value={0}>All</option>
                      <option value="male">some</option>
                      <option value="female">others</option>
                    </Form.Control>
                  </div> */}
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Payment</li>
                    </ul>
                  </div>
                </Col>
              </Form>
              <Table striped bordered variant="light" re>
                <thead>
                  <tr>
                    <th>ORDER_ID</th>
                    <th>COST</th>
                    <th>AMOUNT PAID</th>
                    <th>BALANCE</th>
                    <th>TIME</th>
                    {user_type === "admin" ? null : <th>ACTION</th>}
                  </tr>
                </thead>
                <tbody>
                  {allManageReports < 1 ? (
                    <div className="text-center m-auto">
                      <h1>No record found</h1>
                    </div>
                  ) : (
                    allManageReports.map((report, i) =>
                      report.total_paid &&
                      report.total_amount !== report.total_paid ? (
                        <>
                          <tr>
                            <td>
                              <strong> {report.id}</strong>
                            </td>
                            <td>₦ {formattCurrency(report.total_amount)}</td>
                            <td>
                              ₦
                              {report.total_paid &&
                                formattCurrency(report.total_paid)}
                            </td>
                            <td>
                              ₦
                              {report.total_paid &&
                                formattCurrency(
                                  report.total_amount - report.total_paid
                                )}
                            </td>
                            <td>{moment(report.created_at).fromNow()} </td>
                            <td>
                              {user_type === "admin" ? null : (
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="lite"
                                    size="sm"
                                    className="shadow"
                                  >
                                    <span>
                                      <i
                                        className="fa fa-ellipsis-v font-weight-bold"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link
                                        to={`/manage-payment/${match.params.id}/${report.id}`}
                                      >
                                        Manage
                                      </Link>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item>Delete</Dropdown.Item> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )
                  )}
                </tbody>
              </Table>
            </Tab>

            <Tab eventKey="Report" title="Order report">
              <Form inline className="mb-4">
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search order by id"
                      name="search"
                      value={searchReports}
                      onChange={(e) => setSearchReports(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <Form.Control as="select" name="filter">
                      <option value={0}>Daily</option>
                      <option value="download">Monthly</option>
                      <option value="clear">Yearly</option>
                    </Form.Control>
                  </div> */}
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Reports</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              {/* {allReports < 1 ? (
                <div className="text-center m-auto">
                  <h1>No record found</h1>
                </div>
              ) : ( */}
              <Table striped bordered variant="light" re>
                <thead>
                  <tr>
                    <th>ORDER_ID</th>
                    <th>COST</th>
                    <th>CUSTOMER</th>
                    <th>AMOUNT PAID</th>
                    <th>TIME</th>
                    {user_type === "admin" ? null : <th>ACTION</th>}
                  </tr>
                </thead>
                <tbody>
                  {allReports < 1 ? (
                    <div className="text-center m-auto">
                      <h1>No record found</h1>
                    </div>
                  ) : (
                    allReports.map((report, i) => (
                      <tr key={i}>
                        <td>
                          <strong>{report.id}</strong>
                        </td>
                        <td>₦{formattCurrency(report.total_amount)}</td>
                        <td>
                          {report.customer &&
                            report.customer.first_name +
                              report.customer.last_name}
                        </td>
                        <td>
                          ₦{" "}
                          {report.total_paid &&
                            formattCurrency(report.total_paid)}
                        </td>
                        <td>{moment(report.created_at).fromNow()} </td>
                        <td>
                          {user_type === "admin" ? null : (
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="lite"
                                size="sm"
                                className="shadow"
                              >
                                <span>
                                  <i
                                    className="fa fa-ellipsis-v font-weight-bold"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {/* <Dropdown.Item>
                              <Link
                                to={`/manage-payment/${match.params.id}/${report.id}`}
                              >
                                Manage
                              </Link>
                            </Dropdown.Item> */}
                                <Dropdown.Item>
                                  {" "}
                                  <Link
                                    to={`/sales/receipt/${match.params.id}/${report.id}`}
                                  >
                                    print
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              {/* )} */}
              {/* <Pagination>
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Next />
              </Pagination> */}
            </Tab>
          </Tabs>
        </Container>
      </Styles>
    </PageBody>
  );
};
