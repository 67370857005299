import React from "react";
import { Col, Container, Button, Card, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { BusinessService } from "../../../Services/businessService";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { businessThunk } from "../../../redux/action/business/business.thunk";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../../Context/AuthContext";

export const Business = ({ match }) => {
  const { logout, user, user_type, permissions } = React.useContext(
    AuthContext
  );
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [businessess, setBusinesses] = React.useState([]);
  const [active, setActive] = React.useState();
  const [searchbusiness, setSearchbusiness] = React.useState("");
  const { businesses, AllBusiness } = useSelector((state) => state.businesses);
  const dispatch = useDispatch();

  const loadBusineses = React.useCallback(async () => {
    updateLoader(true, "Fetching Businesses");
    if (match.params.id !== undefined) {
      await dispatch(businessThunk.loadBusineses(match.params.id));
      setActive(1);
      updateLoader(false);
    } else {
      await dispatch(businessThunk.loadBusineses());
      setActive(1);
      updateLoader(false);
    }
  }, [dispatch, match.params.id, updateLoader, businesses]);

  const getbusinessess = React.useCallback(async () => {
    updateLoader(true, "Fetching Businesses");
    if (match.params.id !== undefined) {
      await dispatch(businessThunk.getAll(match.params.id, businesses));
      setActive(1);
      updateLoader(false);
    } else {
      await dispatch(businessThunk.getBusinesses(businesses));
      setActive(1);
      updateLoader(false);
    }
  }, [dispatch, match.params.id, updateLoader, businesses]);

  // const getbusinessess = async () => {
  //   updateLoader(true, "Fetching Businesses");
  //   if (match.params.id !== undefined) {
  //     const response = await BusinessService.getClientbusinesses(
  //       match.params.id
  //     );
  //     setBusinesses(response.data.data);
  //     setActive(1);
  //     updateLoader(false);
  //   } else {
  //     const response = await BusinessService.getbusinesses();
  //     setBusinesses(response.data.data);
  //     setActive(1);
  //     updateLoader(false);
  //   }
  // };

  // const getactivebusinessess = async () => {
  //   updateLoader(true, "Fetching active Businesses");
  //   if (match.params.id !== undefined) {
  //     const response = await BusinessService.getClientactivebusiness(
  //       match.params.id
  //     );
  //     setBusinesses(response.data.data);
  //     setActive(1);
  //     updateLoader(false);
  //   } else {
  //     const response = await BusinessService.getactivebusiness();
  //     setBusinesses(response.data.data);
  //     setActive(2);
  //     updateLoader(false);
  //   }
  // };
  // const getinactivebusinessess = async () => {
  //   updateLoader(true, "Fetching Inactive Businesses");
  //   if (match.params.id !== undefined) {
  //     const response = await BusinessService.getClientinactivebusiness(
  //       match.params.id
  //     );
  //     setBusinesses(response.data.data);
  //     setActive(1);
  //     updateLoader(false);
  //   } else {
  //     const response = await BusinessService.getinactivebusiness();
  //     setBusinesses(response.data.data);
  //     setActive(3);
  //     updateLoader(false);
  //   }
  // };

  const getactivebusinessess = React.useCallback(async () => {
    updateLoader(true, "Fetching active Businesses");
    await dispatch(businessThunk.getAllActiveBusiness(businesses));
    setActive(2);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getinactivebusinessess = React.useCallback(async () => {
    updateLoader(true, "Fetching Inactive Businesses");
    await dispatch(businessThunk.getAllInActiveBusiness(businesses));
    setActive(3);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const allBusiness =
    AllBusiness.data &&
    AllBusiness.data.filter((business) => {
      return (
        business.business_name &&
        business.business_name
          .toLowerCase()
          .indexOf(searchbusiness.toLowerCase()) !== -1
      );
    });

  React.useEffect(() => {
    // getbusinessess();
    loadBusineses();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Nav />
      {allBusiness && (
        <>
          <Container className="mt-4 mb-3" fluid>
            <Form inline>
              <Col md={2}>
                <Link
                  to={`/create-business/${
                    match.params.id === undefined ? "" : match.params.id
                  }`}
                >
                  <Button className="btn-create shadow p-2  m-3">
                    Create Business
                  </Button>
                </Link>
              </Col>

              <Col md={2}>
                <div className="input-group">
                  <input
                    className="form-control py-2 border-right-0 border"
                    type="search"
                    placeholder="search"
                    name="search"
                    value={searchbusiness}
                    onChange={(e) => setSearchbusiness(e.target.value)}
                  />{" "}
                  <span className="input-group-append">
                    <div className="input-group-text bg-transparent">
                      <i className="fa fa-search"></i>
                    </div>
                  </span>
                </div>
              </Col>
              <Col className="" md={3}>
                <Button
                  className={
                    active === 1 ? "btn-filter-all shadow m-1" : "btn-filter"
                  }
                  onClick={() => {
                    getbusinessess();
                  }}
                >
                  All
                </Button>
                <Button
                  className={
                    active === 2 ? "btn-filter-all shadow m-1" : "btn-filter"
                  }
                  onClick={() => {
                    getactivebusinessess();
                  }}
                >
                  Active
                </Button>
                <Button
                  className={
                    active === 3 ? "btn-filter-all shadow m-1" : "btn-filter"
                  }
                  onClick={() => {
                    getinactivebusinessess();
                  }}
                >
                  Inactive
                </Button>
              </Col>
              <Col md={5}>
                <div className="breadcrum">
                  <ul>
                    <Link to="/dashboard">
                      <li>Home</li>
                    </Link>
                    {user_type === user.role.name ? null : (
                      <Link to={`/manage-client/${match.params.id}`}>
                        <li>Manage client</li>
                      </Link>
                    )}
                    <li>Business</li>
                  </ul>
                </div>
              </Col>
              {/* <Col md={3}></Col> */}
            </Form>
          </Container>
          <Container fluid>
            <Row>
              {allBusiness < 1 ? (
                <div className="text-center m-auto">
                  <h3>No record found</h3>
                </div>
              ) : (
                allBusiness.map((business, i) => (
                  <Col md={4} key={i} className="mb-3">
                    <Card className="b-right">
                      <Card.Body>
                        <Card.Title className="mb-4">
                          <strong className="text-danger">
                            {business.business_name}
                          </strong>
                        </Card.Title>

                        <div className="float-left">
                          <Link to={`/manage-business/${business.id}`}>
                            <Button className="btn-create no-b shadow">
                              Manage
                            </Button>
                          </Link>
                        </div>
                        <div className="float-right">
                          <Link to={`/edit-business/${business.id}`}>
                            <Button className="btn-create no-b shadow">
                              Edit
                            </Button>
                          </Link>
                        </div>
                        <div className="clearfix"></div>

                        <div className=" clearfix mt-3">
                          <div className="float-left">
                            <small className="text-muted">RC Number</small>
                            <p>{business.registration_no}</p>
                          </div>

                          <div className="float-right">
                            {/* <small className="text-muted">
                              Subscription status{" "}
                            </small>
                            <p>
                              {business.client.subscription_status
                                ? "PAID"
                                : "TRIAL"}
                            </p> */}
                          </div>
                        </div>

                        <div className="clearfix">
                          <div className="float-left">
                            <small className="text-muted">
                              Next payment date
                            </small>
                            <p>12/03/2023</p>
                          </div>

                          <div
                            style={{
                              paddingRight: "44px",
                            }}
                            className="float-right"
                          >
                            <small className="text-muted">User status</small>
                            <p>
                              {business.status === 1 ? "ACTIVE" : "INACTIVE"}
                            </p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Container>
        </>
      )}
    </PageBody>
  );
};
