import React from "react";
import { Col, Container, Card, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
// import User from '../../../Assets/Img/user-icon.png';
import Users from "../../../Assets/Img/icons/users.png";
import Clients from "../../../Assets/Img/icons/clients.png";
import Business from "../../../Assets/Img/icons/business.png";
import Task from "../../../Assets/Img/icons/task.png";
import Reports from "../../../Assets/Img/icons/reports.png";
import Config from "../../../Assets/Img/icons/config.png";

const Styles = styled.div``;

export const Manage_client = ({ match }) => {
  return (
    <Styles>
      <Nav />
      <Container className="mt-4">
        <Form inline>
          <Col md={12}>
            <div className="breadcrum float-right">
              <ul>
                <Link to="/dashboard">
                  <li>Home</li>
                </Link>
                <li>Manage client</li>
              </ul>
            </div>
          </Col>
        </Form>
      </Container>
      <Container>
        <Row className="mb-3">
          <Col xs={6} md={3}>
            <Link to={`/clients-users/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Users} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Users</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col xs={6} md={3}>
            <Link to={`/clients-customers/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Clients} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Customers</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col xs={6} md={3}>
            <Link to={`/clients-business/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Business} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Business</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col xs={6} md={3}>
            <Link to={`/clients-tasks/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Task} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Tasks</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={3}>
            <Link to={`/clients-reports/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Reports} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Reports</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          {/* <Col xs={6} md={3}>
            <Link to={`/clients-settings/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Config} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Configuration</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col> */}

          <Col xs={6} md={3}>
            <Link to={`/clients-payment/${match.params.id}`}>
              <Card border="light">
                <Card.Body className="text-center">
                  <img className="dash-icon" src={Config} alt="users icon" />
                  <h6>
                    {" "}
                    <strong>Payment</strong>{" "}
                  </h6>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};
