export const constant = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const permission = {
  user_view: 1,
  user_edit: 2,
  user_delete: 3,
  user_create: 4,
  user_activate: 5,
  user_deactivate: 6,
  client_view: 8,
  client_edit: 9,
  client_delete: 10,
  client_create: 11,
  client_activate: 12,
  client_deactivate: 13,
  task_view: 15,
  task_edit: 16,
  task_delete: 17,
  task_create: 18,
  task_activate: 19,
  task_deactivate: 20,
  config_view: 22,
  config_edit: 23,
  config_delete: 24,
  config_create: 25,
  config_activate: 26,
  config_deactivate: 27,
  business_view: 29,
  business_edit: 30,
  business_delete: 31,
  business_create: 32,
  business_activate: 33,
  business_deactivate: 34,
};

export const permission_client = {
  client_user_view: 36,
  client_user_edit: 37,
  client_user_create: 38,
  client_user_activate: 39,
  client_user_delete: 40,
  client_user_deactivate: 41,
  client_task_view: 42,
  client_task_edit: 43,
  client_task_create: 44,
  client_task_activate: 45,
  client_task_deactivate: 46,
  client_task_delete: 47,
  client_customer_view: 48,
  client_customer_edit: 49,
  client_customer_create: 50,
  client_customer_activate: 51,
  client_customer_deactivate: 52,
  client_customer_delete: 53,
  client_business_view: 54,
  client_business_edit: 55,
  client_business_create: 56,
  client_business_activate: 57,
  client_business_deactivate: 58,
  client_business_delete: 59,
  client_inventory_view: 60,
  client_inventory_edit: 61,
  client_inventory_create: 62,
  client_inventory_activate: 63,
  client_inventory_deactivate: 64,
  client_inventory_delete: 65,
  client_sale_view: 66,
  client_sale_edit: 67,
  client_sale_create: 68,
  client_sale_activate: 69,
  client_sale_deactivate: 70,
  client_sale_delete: 71,
  client_report_view: 72,
  client_report_edit: 73,
  client_report_create: 74,
  client_report_activate: 75,
  client_report_deactivate: 76,
  client_report_delete: 77,
  client_config_view: 78,
  client_config_edit: 79,
  client_config_create: 80,
  client_config_activate: 81,
  client_config_deactivate: 82,
  client_config_delete: 83,
};

export const roles = {
  Admin: "admin",
  Client: "client",
  Client_user:"client_user"
};

export const NigerianBanks = [
  {
    id: "1",
    name: "Access Bank",
    code: "044",
  },
  {
    id: "2",
    name: "Citibank",
    code: "023",
  },
  {
    id: "3",
    name: "Diamond Bank",
    code: "063",
  },
  {
    id: "4",
    name: "Dynamic Standard Bank",
    code: "",
  },
  {
    id: "5",
    name: "Ecobank Nigeria",
    code: "050",
  },
  {
    id: "6",
    name: "Fidelity Bank Nigeria",
    code: "070",
  },
  {
    id: "7",
    name: "First Bank of Nigeria",
    code: "011",
  },
  {
    id: "8",
    name: "First City Monument Bank",
    code: "214",
  },
  {
    id: "9",
    name: "Guaranty Trust Bank",
    code: "058",
  },
  {
    id: "10",
    name: "Heritage Bank Plc",
    code: "030",
  },
  {
    id: "11",
    name: "Jaiz Bank",
    code: "301",
  },
  {
    id: "12",
    name: "Keystone Bank Limited",
    code: "082",
  },
  {
    id: "13",
    name: "Providus Bank Plc",
    code: "101",
  },
  {
    id: "14",
    name: "Polaris Bank",
    code: "076",
  },
  {
    id: "15",
    name: "Stanbic IBTC Bank Nigeria Limited",
    code: "221",
  },
  {
    id: "16",
    name: "Standard Chartered Bank",
    code: "068",
  },
  {
    id: "17",
    name: "Sterling Bank",
    code: "232",
  },
  {
    id: "18",
    name: "Suntrust Bank Nigeria Limited",
    code: "100",
  },
  {
    id: "19",
    name: "Union Bank of Nigeria",
    code: "032",
  },
  {
    id: "20",
    name: "United Bank for Africa",
    code: "033",
  },
  {
    id: "21",
    name: "Unity Bank Plc",
    code: "215",
  },
  {
    id: "22",
    name: "Wema Bank",
    code: "035",
  },
  {
    id: "23",
    name: "Zenith Bank",
    code: "057",
  },
];
