import React, { useContext } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Jumbotron as Jumbo,
  Card,
  Table,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../Context/AuthContext";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import product from "../../../../Assets/Img/products/shoes.png";
// import {Customers} from "../Customer/Customers.js";
import { ClientUserService } from "../../../../Services/clientUserService";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
// import { ClientCustomerService } from "../../../../Services/clientCustomerService";
// import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { ClientReportService } from "../../../../Services/clientReportService";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const Business_reports = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();

  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [customers, setCustomers] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);

  // const [users, setUsers] = React.useState([]);
  // const [topSellers, setTopSellers] = React.useState([]);
  // const [locations, setLocations] = React.useState([]);
  // const [locations2, setLocations2] = React.useState([]);
  // // const [location2, setLocation2] = React.useState([]);

  // const [values1,setValues]=React.useState('');
  // const [values2,setValues2]=React.useState('');

  // const handleSelection1=(e)=>{
  //   setValues(locations[parseInt(e, 10)])
  //   setLocations2(locations.filter((el)=>locations.indexOf(el)!==(parseInt(e,10))))
  // }

  // const handleSelection2=(e)=>{
  //   setValues2(locations2[parseInt(e, 10)-1])
  // }
  // const nameCatQuantSetter = ([top5, ids, names, categories]) => {
  //   const nameCatQuant = []
  //   const positions = []
  //   //find an occurrence of each of the top5 in ids, and find its position in the order of sales (ids)
  //   top5.forEach((el) => {
  //     positions.push(ids.indexOf(parseInt(el[0], 10)))
  //   }
  //   )
  //   // use its position to find its corresponding data in the various arrays
  //   //notice that quantity is different, because it is not in a separate array, but a column in top5
  //   var counter = 0
  //   positions.forEach((el) => {
  //     const obj = {};
  //     obj.name = names[el];
  //     obj.cat = categories[el];
  //     obj.quant = top5[counter][1]
  //     counter++
  //     nameCatQuant.push(obj)
  //   })
  //   return nameCatQuant
  //   //nameCatQuant is an array of the objects
  //   //each object has keys name(name), category(cat), quantity(quant)
  // }

  // const sortdata = (sales) => {
  //   const ids = []
  //   const names = []
  //   const categories = []
  //   sales.forEach((el) => {
  //     // items is an array within sales that records the products sold
  //     if (el.items.length !== 0) {
  //       (el.items).forEach((item) => {
  //         ids.push(item.product_id)//collect ids for each item sold
  //         categories.push(item.product.product_category_id)//collect category ids for each item sold
  //         names.push(item.product.product_name)//collect names for each item sold
  //         //since they are all pushed in the same order, names[i] corresponds with category[i] and ids[i]
  //       })
  //     }
  //   })
  //   //tally records each id and the number of sales it has
  //   const tally = {}
  //   for (var i = 0; i < ids.length; i++) {
  //     var num = ids[i];
  //     tally[num] = tally[num] ? tally[num] + 1 : 1;
  //   }
  //   const sortedtally = Object.entries(tally).sort((a, b) => b[1] - a[1])//sort the tally in descending order, saved as a 2D array where each[0]=id and each[1]=numberofoccurrences
  //   const top5 = sortedtally.splice(0, 5)//selects the highest occurring 5, and saves them
  //   const data = [top5, ids, names, categories]
  //   return data
  // }

  // const getsalerecords = async () => {
  //   updateLoader(true, "Fetching Report");

  //   const response = await ClientInventoryService.getSalesReport(match.params.id);
  //   const sales = response.data.data //saves the relevant part of the data from the backend
  //   const data = sortdata(sales) //manipulates the records from sales to get the relevant data that we need to render
  //   const nameCatQuant = nameCatQuantSetter(data) //organises the extracted data into an array of objects so that this can be used as the state, which would then be looped through to render the relevant information
  //   setTopSellers(nameCatQuant) //sets the data as the state
  //   updateLoader(false);
  // };

  // const getbusinessreport = async () => {
  //   updateLoader(true, "Fetching Report");
  //   const response = await ClientUserService.getusers(match.params.id);
  //   const no_of_users = response.data.data.length

  //   setUsers(no_of_users);

  //   updateLoader(false);
  // };

  // const getcustomerreport = async () => {
  //   updateLoader(true, "Fetching Report");
  //   const response = await ClientCustomerService.getusers(match.params.id);
  //   const no_of_customers = response.data.data.length

  //   setCustomers(no_of_customers);

  //   updateLoader(false);
  // };

  // const getInventoryLocations = async () => {
  //   updateLoader(true, "Fetching Locations");
  //   const response = await ClientInventoryService.getlocations(match.params.id);
  //   const locationsData = response.data.data
  //   const locations=[]
  //   locationsData.forEach((el)=>locations.push(el.inventory_location))
  //   setLocations(locations)
  //   updateLoader(false);
  // };

  // const compareBusinesses = async () => {
  //   updateLoader(true, "Fetching Report");
  //   const response = await ClientReportService.compareBusinesses(0, 1);
  //   console.log(response);
  //   updateLoader(false);
  // };

  const getClientReport = async () => {
    updateLoader(true, "Fetching Report");
    const response = await ClientReportService.getBusinessReport(
      match.params.id
    );
    const reportData = response.data.data;
    setReportData(reportData);
    // console.log(reportData)
    updateLoader(false);
  };
  React.useEffect(() => {
    //   getbusinessreport();
    //   getcustomerreport();
    //   getsalerecords();
    //   getInventoryLocations();
    getClientReport();
    // compareBusinesses();
  }, []);

  return (
    <Styles>
      <Navbar />
      <Jumbo fluid></Jumbo>
      <Container fluid>
        <Form inline>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={4}>
            <div className="breadcrum">
              <ul>
                <Link to="/dashboard">
                  <li>Home</li>
                </Link>
                <Link to={`/manage-business/${match.params.id}`}>
                  <li>Business</li>
                </Link>
                <li>Report</li>
              </ul>
            </div>
          </Col>
        </Form>
      </Container>

      <Container className="mt-3" fluid>
        <Card className="text-center">
          <Card.Body>
            <Row>
              <Col md={6}>
                <h3>{reportData.users}</h3>
                <Card.Text className="text-danger">Users</Card.Text>
              </Col>
              <Col md={6}>
                <h3>{reportData.customers}</h3>

                <Card.Text className="text-danger">Customers</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row className="mt-4 mb-4">
          <Col md={12}>
            <h5 className="text-center">Top-Selling Products</h5>
            <Table striped bordered variant="light">
              <thead>
                <tr>
                  <th>PRODUCT NAME</th>
                  <th>CATEGORY</th>
                  <th>QUANTITY</th>
                </tr>
              </thead>
              <tbody>
                {reportData.sales !== undefined &&
                  reportData.sales.map((el) => (
                    <tr>
                      <td>
                        <img
                          className="card-img-top product-img-circle"
                          src={product}
                          alt="product"
                        />
                        <small>
                          {" "}
                          <strong>
                            {el.product_name}
                            {/* {topSellers.length !== 0 && el.name} */}
                          </strong>
                        </small>
                      </td>
                      <td>{el.category_name}</td>
                      {/* <td>{topSellers.length !== 0 && el.cat}</td> */}
                      <td>{el.total_quantity}</td>
                      {/* <td>{topSellers.length !== 0 && el.quant}</td> */}
                    </tr>
                  ))}

                {/* {topSellers.length !== 0 && topSellers.map((el) => (
                  <tr>
                    <td>
                      <img
                        className="card-img-top product-img-circle"
                        src={product}
                        alt="product"
                      />
                      <small>
                        {" "}
                        <strong>

                          {topSellers.length !== 0 && el.name}
                        </strong>
                      </small>
                    </td>
                    <td>{topSellers.length !== 0 && el.cat}</td>
                    <td>{topSellers.length !== 0 && el.quant}</td>

                  </tr>
                ))} */}
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* <Button className="m-3 btn-create">Compare store</Button> */}
        {/* <Formik
          initialValues={{ inventory_location: "" }}
          validationSchema={Yup.object({
            inventory_location: Yup.string().required(
              "inventory location is required"
            ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const response = await ConfigCustomerService.creattaxes(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              history.push("/manage-business");
            } else {
              alert.error("compare not created");
            }
          }}> */}

        {/* {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}> */}
        {/* <Form.Row>
                <h5 className=" mb-3">
                  {" "}
                  <strong>Please select shops to compare</strong>{" "}
                </h5>
              </Form.Row> */}

        {/* <DropdownButton
      alignRight
      title="Inventory Location 1"
      id="dropdown-menu-align-right"
      onSelect={handleSelection1}
        >
          
              {
               (locations.length!==0)&&(
            locations.map((el)=>(
              <Dropdown.Item eventKey={locations.indexOf(el)}>{el}</Dropdown.Item>
            ))
          )}
              
      </DropdownButton>
      <h4>{values1}</h4>








      <DropdownButton
      alignLeft
      title="Inventory Location 2"
      id="dropdown-menu-align-left"
      onSelect={handleSelection2}
        >
          
              {
               (locations2.length!==0)&&(
            locations2.map((el)=>(
              <Dropdown.Item eventKey={locations.indexOf(el)}>{el}</Dropdown.Item>
            ))
          )}
              
      </DropdownButton>
      <h4>Location2 {values2}</h4> */}

        {/* <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Inventory location <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="inventory_location"
                    value={values.inventory_location}
                    onChange={handleChange}
                  >
                    <option value="0">Choose...</option>
                    <option>Abuja</option>
                  </Form.Control>
                  {touched.inventory_location && errors.inventory_location ? (
                    <div className="text-danger">
                      {errors.inventory_location}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Inventory location <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="inventory_location"
                    value={values.inventory_location}
                    onChange={handleChange}
                  >
                    <option value="0">Choose...</option>
                    <option>Abuja</option>
                  </Form.Control>
                  {touched.inventory_location && errors.inventory_location ? (
                    <div className="text-danger">
                      {errors.inventory_location}
                    </div>
                  ) : null}
                </Form.Group>
              </Form.Row> */}

        {/* <Form.Group>
                <div className="mt-3 text-center">
                  <Link to="/business-settings">
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Generate
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group> */}
        {/* </Form> */}
        {/* )} */}
        {/* </Formik> */}
      </Container>
    </Styles>
  );
};
