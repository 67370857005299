import React from "react";
import { Col, Container, Button, Form, Accordion, Card } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { permission, permission_client } from "../../../Util/Constants/constant";
import { ConfigService } from "../../../Services/systemConfigService";
import { AuthContext } from "../../../Context/AuthContext";

const Styles = styled.div`
  .card {
    border: 2px solid #1c3faa;
  }
`;

export const Roles = () => {
  const history = useHistory();
  const { alert } = React.useContext(AuthContext);
  const per = Object.values(permission);

  return (
    <Styles>
      <Nav />
      <Container className="mt-5">
        <h5 className="form-h mb-3">
          {" "}
          <strong>Create New Role</strong>{" "}
        </h5>
        <Formik
          initialValues={{ permissions: [], name: "" }}
          validationSchema={Yup.object({
            name: Yup.string().trim().required(),
            permissions: Yup.string().required(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const response = await ConfigService.createrole(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              history.push("/settings");
            } else {
              alert.error(response.response.data.message);
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="10">
                  <Form.Label>
                    Role name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {touched.name && errors.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="10">
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        Users
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="All"
                                    type={type}
                                    id={`inline-${type}-0`}
                                    name="permissions"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "permissions",
                                        per.map(String)
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    name="permissions"
                                    value={permission.user_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-2`}
                                    name="permissions"
                                    value={permission.user_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-3`}
                                    name="permissions"
                                    value={permission.user_deactivate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-4`}
                                    name="permissions"
                                    value={permission.user_activate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-5`}
                                    name="permissions"
                                    value={permission.user_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-6`}
                                    name="permissions"
                                    value={permission.user_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        Clients
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-7`}
                                    name="permissions"
                                    value={permission.client_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-8`}
                                    name="permissions"
                                    value={permission.client_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-9`}
                                    name="permissions"
                                    value={permission.client_deactivate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-10`}
                                    name="permissions"
                                    value={permission.client_activate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-11`}
                                    name="permissions"
                                    value={permission.client_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-12`}
                                    name="permissions"
                                    value={permission.client_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="2">
                        Business
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-13`}
                                    name="permissions"
                                    value={permission.business_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-14`}
                                    name="permissions"
                                    value={permission.business_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-15`}
                                    name="permissions"
                                    value={permission.business_deactivate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-16`}
                                    name="permissions"
                                    value={permission.business_activate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-17`}
                                    name="permissions"
                                    value={permission.business_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-18`}
                                    name="permissions"
                                    value={permission.business_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        Config
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-19`}
                                    name="permissions"
                                    value={permission.config_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-20`}
                                    name="permissions"
                                    value={permission.config_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-21`}
                                    name="permissions"
                                    value={permission.config_deactivate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-22`}
                                    name="permissions"
                                    value={permission.config_activate}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-23`}
                                    name="permissions"
                                    value={permission.config_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-24`}
                                    name="permissions"
                                    value={permission.config_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* 
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="4">
                        Report
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                        <Card.Body>
                       
                            <Form.Row>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="View" type={type} id={`inline-${type}-21`} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Edit" type={type} id={`inline-${type}-22`} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Deactivate" type={type} id={`inline-${type}-23`} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Activate" type={type} id={`inline-${type}-24`} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Create" type={type} id={`inline-${type}-25`} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                            </Form.Row>
                        
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}

                    {/* <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="5">
                        Task
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                        <Card.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="View" type={type} id={`inline-${type}-25`} name='permissions' value={permission.task_view} onChange={handleChange} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Edit" type={type} id={`inline-${type}-26`} name='permissions' value={permission.task_edit} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Deactivate" type={type} id={`inline-${type}-27`} name='permissions' value={permission.task_deactivate} onChange={handleChange}/>                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Activate" type={type} id={`inline-${type}-28`} name='permissions' value={permission.task_activate} onChange={handleChange}/>                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Create" type={type} id={`inline-${type}-29`} name='permissions' value={permission.task_create} onChange={handleChange}/>                                    
                                        </div>
                                    ))}
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}

                    {/* <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="6">
                        Profile
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="View" type={type} id={`inline-${type}-31`} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Edit" type={type} id={`inline-${type}-32`} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Deactivate" type={type} id={`inline-${type}-33`} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Activate" type={type} id={`inline-${type}-34`} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Create" type={type} id={`inline-${type}-35`} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>  */}




<Card>
                      <Accordion.Toggle as={Card.Header} eventKey="6">
                        Inventory
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-25`}
                                    name="permissions"
                                    value={
                                      permission_client.client_inventory_view
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-26`}
                                    name="permissions"
                                    value={
                                      permission_client.client_inventory_edit
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-27`}
                                    name="permissions"
                                    value={
                                      permission_client.client_inventory_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-28`}
                                    name="permissions"
                                    value={
                                      permission_client.client_inventory_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-29`}
                                    name="permissions"
                                    value={
                                      permission_client.client_inventory_create
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-30`}
                                    name="permissions"
                                    value={
                                      permission_client.client_inventory_delete
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="7">
                        Sales
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="7">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-31`}
                                    name="permissions"
                                    value={permission_client.client_sale_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-32`}
                                    name="permissions"
                                    value={permission_client.client_sale_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-33`}
                                    name="permissions"
                                    value={
                                      permission_client.client_sale_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-34`}
                                    name="permissions"
                                    value={
                                      permission_client.client_sale_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-35`}
                                    name="permissions"
                                    value={permission_client.client_sale_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-36`}
                                    name="permissions"
                                    value={permission_client.client_sale_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="8">
                      Business  Config
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="8">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-37`}
                                    name="permissions"
                                    value={permission_client.client_config_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-38`}
                                    name="permissions"
                                    value={permission_client.client_config_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-39`}
                                    name="permissions"
                                    value={
                                      permission_client.client_config_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-40`}
                                    name="permissions"
                                    value={
                                      permission_client.client_config_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-41`}
                                    name="permissions"
                                    value={
                                      permission_client.client_config_create
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-42`}
                                    name="permissions"
                                    value={
                                      permission_client.client_config_delete
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="9">
                        Business Users
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="9">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-43`}
                                    name="permissions"
                                    value={permission_client.client_user_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-44`}
                                    name="permissions"
                                    value={permission_client.client_user_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-45`}
                                    name="permissions"
                                    value={
                                      permission_client.client_user_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-46`}
                                    name="permissions"
                                    value={
                                      permission_client.client_user_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-47`}
                                    name="permissions"
                                    value={permission_client.client_user_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-48`}
                                    name="permissions"
                                    value={permission_client.client_user_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="10">
                        Business Customers
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="10">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-49`}
                                    name="permissions"
                                    value={
                                      permission_client.client_customer_view
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-50`}
                                    name="permissions"
                                    value={
                                      permission_client.client_customer_edit
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-51`}
                                    name="permissions"
                                    value={
                                      permission_client.client_customer_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-52`}
                                    name="permissions"
                                    value={
                                      permission_client.client_customer_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-53`}
                                    name="permissions"
                                    value={
                                      permission_client.client_customer_create
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-54`}
                                    name="permissions"
                                    value={
                                      permission_client.client_customer_delete
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>




                  </Accordion>
                  {touched.permissions && errors.permissions ? (
                    <div className="text-danger">{errors.permissions}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link to="/settings">
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </Styles>
  );
};
