import {ApiClient} from '../Util/ApiClient'


export class UserService{


    static async updateuser(id,body){
        try{
            const response = await ApiClient.patch(`/users/update-user/${id}`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getclientusers(id){
        try{
            const response = await ApiClient.get(`/users/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }


    static async getusers(){
        try{
            const response = await ApiClient.get(`/users`)
           return response
        }catch(err){
            return err
        }
       
    }


    static async getclientactiveusers(id){
        try{
            const response = await ApiClient.get(`/users/active/get/active-users/${id}/0`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getclientinactiveusers(id){
        try{
            const response = await ApiClient.get(`/users/inactive/get/inactive-users/${id}/0`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getactiveusers(){
        try{
            const response = await ApiClient.get(`/users/active/get/active-users`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getinactiveusers(){
        try{
            const response = await ApiClient.get('/users/inactive/get/inactive-users')
           return response
        }catch(err){
            return err
        }
       
    }

    static async createuser(body){
        try{
            const response = await ApiClient.post('/users/create-user',body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getuser(id){
        try{
            const response = await ApiClient.get(`/users/get-user/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

   
    
}
