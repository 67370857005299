import React, { useContext } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Jumbotron as Jumbo,
  Card,
  Table,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../Component/Layouts/Navbar";
import * as Yup from "yup";
import { AuthContext } from "../../../Context/AuthContext";

import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { ConfigCustomerService } from "../../../Services/systemConfigClient";
import product from "../../../Assets/Img/products/shoes.png";
import { ClientReportService } from "../../../Services/clientReportService";

import { BusinessService } from "../../../Services/businessService";
const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const Admin_client_report = ({ match }) => {
  const { alert, logout, user, user_type, permissions } = useContext(
    AuthContext
  );

  const history = useHistory();

  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [users, setUsers] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [businesses, setBusinesses] = React.useState([]);
  const [list1, setList1] = React.useState([]);
  const [list2, setList2] = React.useState([]);
  const [value1, setValue1] = React.useState([]);
  const [value2, setValue2] = React.useState([]);
  const [bus1id, setBus1id] = React.useState([]);
  const [bus2id, setBus2id] = React.useState([]);
  const [comparisons, setComparisons] = React.useState([]);
  const [bus1Name, setBus1Name] = React.useState([]);
  const [bus2Name, setBus2Name] = React.useState([]);

  const getBusinesses = async () => {
    updateLoader(true, "Fetching Businesses");
    if (match.params.id !== undefined) {
      const response = await BusinessService.getClientbusinesses(
        match.params.id
      );
      setBusinesses(response.data.data);
      setList1(response.data.data);
      updateLoader(false);
    } else {
      const response = await BusinessService.getbusinesses();
      setBusinesses(response.data.data);
      setList1(response.data.data);
      updateLoader(false);
    }
  };

  console.log(user);
  console.log(businesses);

  const resetValues = () => {
    setList1(businesses);
    setList2([]);
    setValue1("");
    setValue2("");
    setBus1id("");
    setBus2id("");
  };

  const compareBusinesses = async () => {
    if (bus1id.length !== 0) {
      updateLoader(true, "Fetching Report");
      const response = await ClientReportService.compareBusinesses(
        bus1id,
        bus2id
      );
      setComparisons(response.data.data);
      setBus1Name(value1);
      setBus2Name(value2);
      resetValues();
      updateLoader(false);
    }
  };

  const getClientReport = async () => {
    updateLoader(true, "Fetching Report");
    if (match.params.id !== undefined) {
      const response = await ClientReportService.getClientReport(
        match.params.id
      );
      const reportData = response.data.data;
      setReportData(reportData);
      updateLoader(false);
    } else {
      const response = await ClientReportService.getReport();
      const reportData = response.data.data;
      setReportData(reportData);
      updateLoader(false);
    }
  };

  const handleSelection1 = (e) => {
    setValue1(businesses[e].business_name);
    setBus1id(businesses[e].id);
    setList2(
      businesses.filter((el) => businesses.indexOf(el) !== parseInt(e, 10))
    );
  };

  const handleSelection2 = (e) => {
    setValue2(businesses[e].business_name);
    setBus2id(businesses[e].id);
    setList1(
      businesses.filter((el) => businesses.indexOf(el) !== parseInt(e, 10))
    );
  };

  React.useEffect(() => {
    getClientReport();
    getBusinesses();
  }, []);

  return (
    <Styles>
      <Navbar />
      <Jumbo fluid></Jumbo>
      <Container fluid>
        <Form inline>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={4}>
            <div className="breadcrum">
              <ul>
                <Link to="/dashboard">
                  <li>Home</li>
                </Link>
                {user_type === user.role.name ? null : (
                  <Link to={`/manage-client/${match.params.id}`}>
                    <li>Manage client</li>
                  </Link>
                )}
                {/* <Link to={`/manage-client/${match.params.id}`}>
                  <li>Manage client</li>
                </Link> */}
                <li>Report</li>
              </ul>
            </div>
          </Col>
        </Form>
      </Container>

      <Container fluid>
        <Card className="text-center">
          <Card.Body>
            <Row>
              <Col md={3}>
                <h3>{reportData.users}</h3>
                <Card.Text className="text-danger">Users</Card.Text>
              </Col>
              <Col md={3}>
                <h3>{reportData.customers}</h3>
                <Card.Text className="text-danger">Customers</Card.Text>
              </Col>
              <Col md={3}>
                <h3>{reportData.customers}</h3>
                <Card.Text className="text-danger">Products</Card.Text>
              </Col>
              <Col md={3}>
                <h3>{reportData.customers}</h3>
                <Card.Text className="text-danger">Amount Made</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row className="mt-4 mb-4">
          <Col md={12}>
            <h5 className="text-center">Top-Selling Products</h5>
            <Table striped bordered variant="light">
              <thead>
                <tr>
                  <th>PRODUCT NAME</th>
                  <th>CATEGORY</th>
                  <th>QUANTITY</th>
                </tr>
              </thead>
              <tbody>
                {reportData.sales !== undefined &&
                  reportData.sales.map((el) => (
                    <tr>
                      <td>
                        <img
                          className="card-img-top product-img-circle"
                          src={product}
                          alt="product"
                        />
                        <small>
                          {" "}
                          <strong>{el.product_name}</strong>
                        </small>
                      </td>
                      <td>{el.category_name}</td>
                      <td>{el.total_quantity}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="mt-4 mb-4">
          <Col md={5}>
            <h4>Select First Business to Compare</h4>
            <DropdownButton
              alignRight
              title="Business 1"
              id="dropdown-menu-align-right"
              onSelect={handleSelection1}
            >
              {list1.length === 0 && (
                <h6>There are no businesses registered for this client.</h6>
              )}
              {list1.length !== 0 &&
                list1.map((el) => (
                  <Dropdown.Item eventKey={businesses.indexOf(el)}>
                    {el.business_name}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
            <h5>{value1}</h5>
          </Col>

          <Col md={5}>
            <h4>Select Second Business to Compare</h4>
            <DropdownButton
              alignLeft
              title="Business 2"
              id="dropdown-menu-align-left"
              onSelect={handleSelection2}
            >
              {bus1id.length === 0 && <p>Please select First Business first</p>}
              {bus1id.length !== 0 && list2.length === 0 && (
                <p>Client needs at least two businesses to enable comparison</p>
              )}
              {list2.length !== 0 &&
                list2.map((el) => (
                  <Dropdown.Item eventKey={businesses.indexOf(el)}>
                    {el.business_name}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
            <h5>{value2}</h5>
          </Col>
        </Row>

        {bus2id.length !== 0 && (
          <Button onClick={() => compareBusinesses()}>Generate</Button>
        )}

        <Row className="mt-4 mb-4">
          {comparisons.length !== 0 && (
            <Col md={12}>
              <h5 className="text-center">{bus1Name}</h5>
              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {comparisons.business_1.map((el) => (
                    <tr>
                      <td>
                        <small>
                          {" "}
                          <strong>{el.product_name}</strong>
                        </small>
                      </td>
                      <td>{el.total_quantity}</td>
                      <td>{el.price}</td>
                      <td>{el.price}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <small>
                        {" "}
                        <strong>Total Income</strong>
                      </small>
                    </td>
                    <td></td>
                    <td></td>
                    <td>{comparisons.business_1_income}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          )}
        </Row>

        <Row className="mt-4 mb-4">
          {comparisons.length !== 0 && (
            <Col md={12}>
              <h5 className="text-center">{bus2Name}</h5>
              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {comparisons.business_2.map((el) => (
                    <tr>
                      <td>
                        <small>
                          {" "}
                          <strong>{el.product_name}</strong>
                        </small>
                      </td>
                      <td>{el.total_quantity}</td>
                      <td>{el.price}</td>
                      <td>{el.total}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <small>
                        {" "}
                        <strong>Total Income</strong>
                      </small>
                    </td>
                    <td></td>
                    <td></td>
                    <td>{comparisons.business_2_income}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          )}
        </Row>

        {/* <Button className="m-3 btn-create">Compare store</Button>
        <Formik
          initialValues={{ inventory_location: "" }}
          validationSchema={Yup.object({
            inventory_location: Yup.string().required(
              "inventory location is required"
            ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const response = await ConfigCustomerService.creattaxes(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              history.push("/manage-business");
            } else {
              alert.error("compare not created");
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <h5 className=" mb-3">
                  {" "}
                  <strong>Please select shop to compare</strong>{" "}
                </h5>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Inventory location <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="inventory_location"
                    value={values.inventory_location}
                    onChange={handleChange}
                  >
                    <option value="0">Choose...</option>
                    <option>Abuja</option>
                  </Form.Control>
                  {touched.inventory_location && errors.inventory_location ? (
                    <div className="text-danger">
                      {errors.inventory_location}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Inventory location <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="inventory_location"
                    value={values.inventory_location}
                    onChange={handleChange}
                  >
                    <option value="0">Choose...</option>
                    <option>Abuja</option>
                  </Form.Control>
                  {touched.inventory_location && errors.inventory_location ? (
                    <div className="text-danger">
                      {errors.inventory_location}
                    </div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link to={`/manage-client`}>
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Generate
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik> */}
      </Container>
    </Styles>
  );
};
