import React from 'react';
import { Button, Navbar, Nav, Jumbotron, Container, Col, Row, Card, ListGroup, CardDeck } from 'react-bootstrap';
import feature1 from '../../Assets/Img/icons/users.png';
import groupImg from '../../Assets/Img/group-view.png';
import finance from '../../Assets/Img/icons/finance.png';
import decision from '../../Assets/Img/icons/decision.png';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ConfigService } from "../../Services/systemConfigService";

const Styles = styled.div`

.jumbotron-1{
  height: 100vh;
  background: linear-gradient(48deg, rgba(28,63,170,1) 0%, rgba(28,62,168,1) 59%, rgba(14,32,85,1) 100%);
  padding: 0;
  border-radius: 0;
}

.jumbotron{
  background: linear-gradient(48deg, rgba(28,63,170,1) 0%, rgba(28,62,168,1) 59%, rgba(14,32,85,1) 100%);
  padding: 0;
  border-radius: 0;
}

.navbar-dark .navbar-nav .nav-link{
  color: white;
}

.b-top{
  border-top: 4px solid #EF705D;
}

.card-body{
  background: #F5F5FD;
}

.list-group-item{
  border: none;
}

.middle-card{
    // height:430px;
    margin-top:-40px;
    border-top: 4px solid #EF705D;
  }

.btn-sign{
    align:center;
    border-radius: 50px;
    border: 1px solid #1C3FAA;
  }

`;

export const Home = () => {
  const [subscriptions, setSubscriptions] = React.useState([]);



  const getSubscriptions = async () => {
    const response = await ConfigService.getsubscriptions();
    setSubscriptions(response.data.data)
  };

  React.useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div>
      <Styles>

        <Jumbotron className="jumbotron-1">

          <Navbar expand="lg" bg="default" variant="dark">
            <Navbar.Brand href="#home">
              {/* <img  className="logo" src={Logo} alt="Logo"/> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto text-white">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
              </Nav>
              <div className="inline">
                <Link to="/Login">
                  <Button variant="outline-light btn-create m-2">Login</Button>
                </Link>

                {/* <Link to="/register">
              <Button variant="light shadow m-2">Get Started</Button>
          </Link> */}
              </div>
            </Navbar.Collapse>
          </Navbar>

          <Container fluid>

            <Row className="mt-5">
              <Col md={4}>
                <div className="pt-5">
                  <h2 className="text-white pt-5"> The Best Retail Software </h2>
                  <h1> <strong className="text-white"> Smart &  Seamless </strong> </h1>
                  <p className="text-white pt-4">
                    We provide room for stakeholders to manage their businesses and
                    control every aspect of it without being physically present.
          </p>

                  <Link to="/register">
                    <Button variant="outline-light btn-create mt-5 shadow">Get Started</Button>
                  </Link>


                </div>
              </Col>

              <Col md={8}>
                <div className="hero-sec"></div>
              </Col>
            </Row>
          </Container>

        </Jumbotron>

        <Container className="features-sec" id="features">

          <h5 className="text-center mt-5">
            <strong>Some Amazing Features</strong>
          </h5>

          <p className="text-center mb-5">
            It accommodates setting of par levels, thus ensuring that
            businesses are never out of stock or at risk of overselling.
          </p>

          <Row>
            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <div className="features-box m-auto p-2 shadow">
                    <img className="" src={feature1} width="100%" height="100%" alt="staff management" />
                  </div>
                  <Card.Title className="mt-4"> <strong>Staff management</strong> </Card.Title>
                  <Card.Text>
                    Seamless management of staff records
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <div className="features-box m-auto p-2 shadow">
                    <img className="" src={decision} width="100%" height="100%" alt="decision making" />
                  </div>
                  <Card.Title className="mt-4"> <strong>Decision making</strong> </Card.Title>
                  <Card.Text>
                    Make informed decision using business intelligent tools
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="text-center">
                <Card.Body>
                  <div className="features-box m-auto p-2 shadow">
                    <img className="" src={finance} width="100%" height="100%" alt="business finance" />
                  </div>
                  <Card.Title className="mt-4"> <strong>Business finances</strong> </Card.Title>
                  <Card.Text>
                    Manage your transactions/finances at a go.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Container>
        <div className="app-view mt-5"></div>

        <Container>
          <Row className="mt-5">
            <Col md={6}>
              <img className="" src={groupImg} width="100%" height="100%" alt="Logo" />
            </Col>

            <Col md={6}>
              <h3 className="mt-5">User Friendly, Reliable & Powerful</h3>

              <ListGroup variant="lite mt-2">
                <ListGroup.Item>
                  <i className="fa fa-check m-2"></i>
              Designed to help stakeholders manage transactions and reports.
              </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fa fa-check m-2"></i>
              Enables businesses to have a proper inventory.
              </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fa fa-check m-2"></i>
              Allows businesses to have a repository of products.
              </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fa fa-check m-2"></i>
              Improved  services and basic financial information.
              </ListGroup.Item>

              </ListGroup>
            </Col>
          </Row>

        </Container>

        <Container id="pricing">

          <h5 className="text-center mt-5"> <strong>Pricing</strong> </h5>
          <p className="text-center mb-5">
            Please select the plan which suites your
            business and experience amazing world class business management.
          </p>

          <CardDeck className="mt-5">
            {subscriptions.length !== undefined && subscriptions.map((el) => <Card className="text-center mt-5 b-top">
              <Card.Body className="bg-white">
                <Card.Title className="mt-3"> <strong>{el.name}</strong> </Card.Title>
                <Card.Text><sup>N</sup> <span className="display-4"><strong>{el.price}</strong></span>  <sub>7 days</sub> </Card.Text>
                <Card.Text>
                  <ListGroup variant="flush mt-4">
                    <ListGroup.Item> <small> Description: {el.description} </small> </ListGroup.Item>
                    <ListGroup.Item> <small> Number of Payments: {el.no_of_payments} </small> </ListGroup.Item>
                    <ListGroup.Item> <small> Plan type: {el.plan_type}</small></ListGroup.Item>
                    <ListGroup.Item> <small> Maximum number of products: {el.product_max}</small> </ListGroup.Item>
                    <ListGroup.Item> <small> Minimum number of products: {el.product_min}</small> </ListGroup.Item>
                  </ListGroup>
                  <Link to="/register">
                    <Button className="btn-action shadow m-3" >
                      Try Now
              </Button>
                  </Link>
                </Card.Text>
              </Card.Body>

            </Card>)}


            {/* <Card className="middle-card text-center shadow">
              <Card.Body className="bg-white">
                <Card.Title> <strong>STANDARD</strong> </Card.Title>
                <Card.Text><sup>N</sup> <span className="display-4"><strong>20,000</strong></span>  <sub>Monthly</sub> </Card.Text>
                <Card.Text>
                  <ListGroup variant="flush mt-5">
                    <ListGroup.Item> <small>200,000 Products{subscriptions.map((el) => <h1>{el}</h1>)}</small> </ListGroup.Item>
                    <ListGroup.Item> <small>Business management</small> </ListGroup.Item>
                    <ListGroup.Item> <small>Inventory management</small></ListGroup.Item>
                    <ListGroup.Item> <small>and many more</small> </ListGroup.Item>
                  </ListGroup>
                  <Link to="/register">
                    <Button className="btn-action shadow m-3" >
                      Purchase
              </Button>
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="text-center b-top"> 
              <Card.Body className="bg-white">
                <Card.Title> <strong>BASIC</strong> </Card.Title>
                <Card.Text><sup>N</sup> <span className="display-4"><strong>10,000</strong></span>  <sub>Monthly</sub> </Card.Text>
                <Card.Text>
                  <ListGroup>
                    <ListGroup.Item> <small>67,800 Products</small> </ListGroup.Item>
                    <ListGroup.Item> <small>Business management</small> </ListGroup.Item>
                    <ListGroup.Item> <small>Inventory management</small></ListGroup.Item>
                    <ListGroup.Item> <small>and many more</small> </ListGroup.Item>
                  </ListGroup>

                  <Link to="/register">
                    <Button className="btn-action shadow m-3" >
                      Purchase
              </Button>
                  </Link>

                </Card.Text>
              </Card.Body>

            </Card>*/}
          </CardDeck>
        </Container>

        <Jumbotron>

          <Container fluid>
            <Row className="mt-5" >
              <Col md={4}>
                <div className="pt-5 pb-4">
                  <h2 className="text-white  pt-5">
                    Get started for free today.
        </h2>

                  <p className="text-white pt-4">
                    Try it for free for 7days with amazing features
        </p>
                  <Link to="/register">
                    <Button variant="outline-light mt-5 shadow">Get Started</Button>
                  </Link>

                  <p></p>
                </div>
              </Col>

              <Col md={8}>
                <div className="img-sec">
                </div>
              </Col>
            </Row>
          </Container>
        </Jumbotron>

        <Container fluid className="mb-4" id="contact">
          <Row>
            {/* <Col md={6}>
            <h4>About</h4>
            <p align="justify">NRS provides room for stakeholders to manage 
            <br></br> their businesses and control every aspect 
            <br></br>of it without being physically present.
            </p>
          </Col> */}

            <Col md={12} className="text-center">
              <h4> <strong>Contact</strong> </h4>
              <p>Get in touch with us</p>
              <a href="mailto:info@nrs.needletech.ng"> <i className="fa fa-envelope-square"></i>  <strong>info@nrs.needletech.ng</strong>  </a> <br></br>
              <p></p>
              <a href="tel:092917825"> <i className="fa fa-phone"></i>  <strong> 092917825</strong>  </a> <br></br>
              <p></p>
              <i className="fa fa-twitter-square m-2"></i>
              <i className="fa fa-facebook-square m-2"></i>
              <i className="fa fa-instagram m-2"></i>
            </Col>
          </Row>
        </Container>

      </Styles>
    </div>
  );
}

// export const Home = () => (

//   <div>
//     const [a, setA]=React.useState([]); 
//   </div>

// )