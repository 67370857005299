import { ApiClient } from "../Util/ApiClient";

export class ClientUserService {
  static async updateuser(id, body) {
    try {
      const response = await ApiClient.patch(`/users/update-user/${id}`, body);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getusers(id) {
    try {
      const response = await ApiClient.get(`/users/0/${id}`);

      return response;
    } catch (err) {
      return err;
    }
  }

  static async getactiveusers(id) {
    try {
      const response = await ApiClient.get(
        `/users/active/get/active-users/0/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
  static async getinactiveusers(id) {
    try {
      const response = await ApiClient.get(
        `/users/inactive/get/inactive-users/0/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createuser(business_id, body) {
    try {
      const response = await ApiClient.post(
        `/users/create-user/${business_id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getuser(id) {
    try {
      const response = await ApiClient.get(`/users/get-user/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
}
