import React from "react";
import { Col, Container, Button, Form, Accordion, Card } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { permission_client } from "../../../../../Util/Constants/constant";
import { ConfigService } from "../../../../../Services/systemConfigService";
import { AuthContext } from "../../../../../Context/AuthContext";

const Styles = styled.div`
  .card {
    border: 2px solid #1c3faa;
  }
`;

export const Create_client_role = ({ match }) => {
  const history = useHistory();
  const { alert } = React.useContext(AuthContext);
  return (
    <Styles>
      <Nav />

      <Container className="mt-5">
        <h5 className="form-h mb-3">
          {" "}
          <strong>Create New Role</strong>{" "}
        </h5>
        <Formik
          initialValues={{ permission_clients: [], name: "" }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
            permission_clients: Yup.string().required("Required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.permissions = values.permission_clients;
            const response = await ConfigService.createclientrole(
              match.params.id,
              values
            );

            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              // history.push(`/business-settings/${match.params.id}`)
              await history.goBack();
            } else {
              alert.error(response.response.data.message);
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="10">
                  <Form.Label>
                    Role name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {touched.name && errors.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="10">
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        Inventory
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_inventory_view
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-2`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_inventory_edit
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-3`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_inventory_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-4`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_inventory_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-5`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_inventory_create
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-6`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_inventory_delete
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        Sales
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-7`}
                                    name="permission_clients"
                                    value={permission_client.client_sale_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-8`}
                                    name="permission_clients"
                                    value={permission_client.client_sale_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-9`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_sale_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-10`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_sale_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-11`}
                                    name="permission_clients"
                                    value={permission_client.client_sale_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-12`}
                                    name="permission_clients"
                                    value={permission_client.client_sale_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    {/* <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                        Report
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                        <Card.Body>
                        
                            <Form.Row>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="View" type={type} id={`inline-${type}-13`} name='permission_clients' value={permission_client.client_report_view} onChange={handleChange} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Edit" type={type} id={`inline-${type}-14`}name='permission_clients' value={permission_client.client_report_edit} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Deactivate" type={type} id={`inline-${type}-15`} name='permission_clients' value={permission_client.client_report_deactivate} onChange={handleChange} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Activate" type={type} id={`inline-${type}-16`} name='permission_clients' value={permission_client.client_report_activate} onChange={handleChange}/>                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Create" type={type} id={`inline-${type}-17`} name='permission_clients' value={permission_client.client_report_create} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>

                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Delete" type={type} id={`inline-${type}-18`} name='permission_clients' value={permission_client.client_report_delete} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                            </Form.Row>
                       
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
 */}
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        Config
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-19`}
                                    name="permission_clients"
                                    value={permission_client.client_config_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-20`}
                                    name="permission_clients"
                                    value={permission_client.client_config_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-21`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_config_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-22`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_config_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-23`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_config_create
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-24`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_config_delete
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="4">
                        Users
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-25`}
                                    name="permission_clients"
                                    value={permission_client.client_user_view}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-26`}
                                    name="permission_clients"
                                    value={permission_client.client_user_edit}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-27`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_user_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-28`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_user_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-29`}
                                    name="permission_clients"
                                    value={permission_client.client_user_create}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-30`}
                                    name="permission_clients"
                                    value={permission_client.client_user_delete}
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="5">
                        Customers
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <Form.Row>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="View"
                                    type={type}
                                    id={`inline-${type}-31`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_customer_view
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Edit"
                                    type={type}
                                    id={`inline-${type}-32`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_customer_edit
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Deactivate"
                                    type={type}
                                    id={`inline-${type}-33`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_customer_deactivate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="2" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Activate"
                                    type={type}
                                    id={`inline-${type}-34`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_customer_activate
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Create"
                                    type={type}
                                    id={`inline-${type}-35`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_customer_create
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="6">
                              {["checkbox"].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                  <Form.Check
                                    inline
                                    label="Delete"
                                    type={type}
                                    id={`inline-${type}-36`}
                                    name="permission_clients"
                                    value={
                                      permission_client.client_customer_delete
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              ))}
                            </Form.Group>
                          </Form.Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    {/* <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="6">
                        Task
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                        <Card.Body>
                       
                            <Form.Row>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="View" type={type} id={`inline-${type}-37`} name='permission_clients' value={permission_client.client_task_view} onChange={handleChange} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Edit" type={type} id={`inline-${type}-38`} name='permission_clients' value={permission_client.client_task_edit} onChange={handleChange}/>                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6" >
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Deactivate" type={type} id={`inline-${type}-39`} name='permission_clients' value={permission_client.client_task_deactivate} onChange={handleChange} />                                                              
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="2" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Activate" type={type} id={`inline-${type}-40`} name='permission_clients' value={permission_client.client_task_activate} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Create" type={type} id={`inline-${type}-41`} name='permission_clients' value={permission_client.client_task_create} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>

                                <Form.Group as={Col} md="3" xs="6">
                                    {['checkbox'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check inline label="Delete" type={type} id={`inline-${type}-42`} name='permission_clients' value={permission_client.client_task_delete} onChange={handleChange} />                                    
                                        </div>
                                    ))}
                                </Form.Group>
                            </Form.Row>
                     
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}
                  </Accordion>
                  {touched.permission_clients && errors.permission_clients ? (
                    <div className="text-danger">
                      {errors.permission_clients}
                    </div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link onClick={history.goBack}>
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </Styles>
  );
};
