import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { BusinessService } from "../../../Services/businessService";
import { AuthContext } from "../../../Context/AuthContext";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { roles } from "../../../Util/Constants/constant";

export const Edit_business = ({ match }) => {
  const { alert, user_type } = useContext(AuthContext);
  const [business, setBusiness] = useState([]);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getBusiness = async () => {
    updateLoader(true, "Fetching Business");
    const response = await BusinessService.getbusiness(match.params.id);
    setBusiness(response.data.data);
    updateLoader(false);
  };

  useEffect(() => {
    getBusiness();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Nav />

      <Container className="mt-5">
        {business !== null ? (
          <Formik
            initialValues={{
              business_name: business.business_name || "",
              registration_no: business.registration_no || "",
              no_of_branches: business.no_of_branches || "",
              no_of_products: business.no_of_products || "",
              status: business.status,
            }}
            validationSchema={Yup.object({
              business_name: Yup.string().trim().required("Required"),

              registration_no: Yup.string().trim().nullable(),

              no_of_branches: Yup.string().trim().required("Required"),

              no_of_products: Yup.string().trim().required("Required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const response = await BusinessService.updatebusiness(
                match.params.id,
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                if (user_type === roles.Admin) {
                  history.push(`/clients-business/${business.client_id}`);
                } else {
                  history.push("/business");
                }
              } else {
                alert.error("error updating business");
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              dirty,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <h5 className="form-h mb-3">
                  {" "}
                  <strong>Edit Business</strong>{" "}
                </h5>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Business Status{" "}
                      <small className="text-muted">
                        ( activate or deactivate )
                      </small>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option>Choose...</option>
                      <option value={1}>Activate</option>
                      <option value={0}>Deactivate</option>
                    </Form.Control>
                    {touched.status && errors.status ? (
                      <div className="text-danger">{errors.status}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Company name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="business_name"
                      value={values.business_name}
                      onChange={handleChange}
                    />
                    {touched.business_name && errors.business_name ? (
                      <div className="text-danger">{errors.business_name}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>Company registration number</Form.Label>
                    <Form.Control
                      type="text"
                      name="registration_no"
                      value={values.registration_no}
                      onChange={handleChange}
                    />
                    {touched.registration_no && errors.registration_no ? (
                      <div className="text-danger">
                        {errors.registration_no}
                      </div>
                    ) : null}
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Number of branches <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="no_of_branches"
                      value={values.no_of_branches}
                      onChange={handleChange}
                    />
                    {touched.no_of_branches && errors.no_of_branches ? (
                      <div className="text-danger">{errors.no_of_branches}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Number products <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="no_of_products"
                      value={values.no_of_products}
                      onChange={handleChange}
                    />
                    {touched.no_of_products && errors.no_of_products ? (
                      <div className="text-danger">{errors.no_of_products}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link
                      to={
                        user_type === roles.Admin
                          ? `/clients-business/${business.client_id}`
                          : "/business"
                      }
                    >
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={!dirty}
                    >
                      Update{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        ) : null}
      </Container>
    </PageBody>
  );
};
