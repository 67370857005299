import React from "react";
import { Col, Container, Button, Card, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../../Component/Layouts/Nav";
import User from "../../../../Assets/Img/user-icon.png";
import { PageBody } from "../../../../Component/Loader";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { businessUsersThunk } from "../../../../redux/action/business-users/business-users.thunk";
import { useDispatch, useSelector } from "react-redux";
import { permission_client} from "../../../../Util/Constants/constant";
import { AuthContext } from "../../../../Context/AuthContext";

const Styles = styled.div`
  .card-body p {
    font-size: 10px;
  }
`;
export const Business_users = ({ match }) => {
  const { permissions, user_type } = React.useContext(AuthContext);
  const [users, setUsers] = React.useState([]);
  const [active, setActive] = React.useState();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [search, setSearch] = React.useState("");
  const { businessUsers, AllBusinessUsers, businesUsers } = useSelector(
    (state) => state.businessUsers
  );
  const dispatch = useDispatch();


  const loadBUsers = React.useCallback(async () => {
    updateLoader(true, "Fetching Users");
    await dispatch(businessUsersThunk.loadBusers(match.params.id));
    setActive(1);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Users");
    await dispatch(
      businessUsersThunk.getAllBUsers(match.params.id, businessUsers)
    );
    setActive(1);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getactiveusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Active Users");
    await dispatch(businessUsersThunk.getAllActiveBusinessUsers(businessUsers));

    setActive(2);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getinactiveusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Inactive Users");
    await dispatch(
      businessUsersThunk.getAllInActiveBusinessUsers(businessUsers)
    );

    setActive(3);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const allUsers =
    AllBusinessUsers.data &&
    AllBusinessUsers.data.filter((user) => {
      return (
        user.first_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        user.last_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    });

  React.useEffect(() => {
    loadBUsers();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        {allUsers && (
          <>
            <Container className="mt-4" fluid>
              <Form inline>
                <Col md={2}>
                {permissions &&
                    permissions.includes(permission_client.client_user_create) === true ? (
                  <Link to={`/create-business-user/${match.params.id}`}>
                    <Button className="btn-create shadow p-2 m-3">
                      Create User
                    </Button>
                  </Link>
                    ):null  
                }
                </Col>

                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col className="" md={4}>
                  <Button
                    className={
                      active === 1 ? "btn-filter-all shadow m-1" : "btn-filter"
                    }
                    onClick={() => {
                      getusers();
                    }}
                  >
                    All
                  </Button>
                  <Button
                    className={
                      active === 2 ? "btn-filter-all shadow m-1" : "btn-filter"
                    }
                    onClick={() => {
                      getactiveusers();
                    }}
                  >
                    Active
                  </Button>
                  <Button
                    className={
                      active === 3 ? "btn-filter-all shadow m-1" : "btn-filter"
                    }
                    onClick={() => {
                      getinactiveusers();
                    }}
                  >
                    Inactive
                  </Button>
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Users</li>
                    </ul>
                  </div>
                </Col>
              </Form>
            </Container>
            <Container fluid>
              <Row className="mt-5">
                {allUsers < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allUsers.map((user, i) => (
                    <Col md={3} key={i} className="mb-5">
                      <Card className="text-center card-user">
                        <center>
                          <img
                            className="user-img"
                            src={User}
                            alt="user icon"
                          />
                        </center>
                        <Card.Body className="mt-4">
                          <p className="p-0 mt-3">
                            {" "}
                            <i
                              className={
                                user.status === 0
                                  ? "text-danger fa fa-circle"
                                  : "text-success fa fa-circle"
                              }
                            ></i>
                          </p>

                          <Card.Title>
                            <strong>
                              {user !== null
                                ? user.first_name + " " + user.last_name
                                : null}
                            </strong>
                          </Card.Title>

                          <small>{user.email}</small>
                          <p>{user.phonenumber}</p>
                          <em className="text-danger">
                            {user.role && user.role.name}
                          </em>
                          <p></p>
                          {permissions &&
                        permissions.includes(permission_client.client_user_edit) === true ? (
                          <Link
                            to={`/edit-business-user/${user.id}/${match.params.id}`}
                          >
                            <Button className="btn-create-two shadow m-3">
                              <small>Edit</small>
                            </Button>
                          </Link>
                    ):null}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Container>
          </>
        )}
      </Styles>
    </PageBody>
  );
};
