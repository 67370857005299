import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Card,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { ConfigService } from "../../../Services/systemConfigService";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { phoneRegExp } from "../../../Util/Constants/constant";
import { PageBody } from "../../../Component/Loader";
import { ClientService } from "../../../Services/clientService";
import { AuthContext } from "../../../Context/AuthContext";

// monthly and yearly for payment plan

export const Create_client = () => {
  const [navigate, setNavigate] = useState(1);
  const [toggle, settoggle] = useState(null);
  const { alert } = useContext(AuthContext);
  const [err, seterr] = useState(null);
  const [err2, seterr2] = useState(null);
  const [subscripitions, setSubscription] = useState([]);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getsubscriptionPlan = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getsubscriptions();

    setSubscription(response.data.data);
    updateLoader(false);
  };

  const handleNavigation = (val) => {
    setNavigate(val);
    if (val === 1) {
      settoggle(null);
    }
    if (val === 2) {
      settoggle(true);
    }
    if (val === 3) {
      settoggle(false);
    }
  };

  useEffect(() => {
    getsubscriptionPlan();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Nav />

      <Container>
        <Row>
          <div className="stepwizard mt-5">
            <div className="stepwizard-row setup-panel">
              <div className="stepwizard-step">
                <a
                  href="#1"
                  onClick={() => handleNavigation(1)}
                  type="button"
                  className="btn btn-circle"
                >
                  {" "}
                  1{" "}
                </a>
                <p>Personal Details</p>
              </div>

              <div className="stepwizard-step">
                <a
                  href="#2"
                  onClick={() => handleNavigation(2)}
                  type="button"
                  className="btn btn-circle"
                  disabled="disabled"
                >
                  {" "}
                  2{" "}
                </a>
                <p>Business Details</p>
              </div>

              <div className="stepwizard-step">
                <a
                  href="#3"
                  type="button"
                  onClick={() => handleNavigation(3)}
                  className="btn btn-circle"
                  disabled="disabled"
                >
                  {" "}
                  3{" "}
                </a>
                <p>Payment Details</p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Container className="mt-5">
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            payment_plan: "",
            business_name: "",
            registration_no: "",
            no_of_branches: "",
            no_of_products: "",
            subscription_plan_id: "",
            payment_mode: "",
            amount: "",
            payment_option: "",
            confirm_password: "",
          }}
          validationSchema={Yup.object({
            first_name: Yup.string()
              .trim()
              .required("first name is a required field"),

            middle_name: Yup.string().nullable(),

            last_name: Yup.string()
              .trim()
              .required("last name is a required field"),
            password: Yup.string()
              .max(12, "Must be 12 characters or less")
              .required("Required"),
            confirm_password: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "Passwords must match"
            ),

            email: Yup.string().email("Invalid email address").required(),

            business_name: Yup.string()
              .trim()
              .required("business name is a required field"),

            registration_no: Yup.string().trim().nullable(),

            no_of_branches: Yup.string()
              .trim()
              .required("branch number is a required field"),

            no_of_products: Yup.string()
              .trim()
              .required("number of product is a required field"),

            payment_option: Yup.string()
              .min(3, "please select an option")
              .required("payment plan is a required field "),
            payment_plan: Yup.string()
              .min(3, "please select an option")
              .nullable("payment mode is a required field"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            subscripitions.map((sub) => {
              if (
                values.no_of_products <= sub.product_max &&
                sub.product_min <= values.no_of_products
              ) {
                values.subscription_plan_id = sub.id;
              }
            });

            const data = [];
            const client = {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              payment_plan: values.payment_plan,
              gender: values.gender,
            };

            const business = {
              business_name: values.business_name,
              registration_no: values.registration_no,
              no_of_branches: values.no_of_branches,
              no_of_products: values.no_of_products,
            };

            const payment = {
              subscription_plan_id: values.subscription_plan_id,
              payment_mode: values.payment_mode,
            };

            data.push(client, business, payment);

            const response = await ClientService.registerclient(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              history.push("/login");
            } else {
              if (response.response.data.data.email)
                alert.error(response.response.data.data.email);
              if (response.response.data.data.phonenumber)
                alert.error(response.response.data.data.phonenumber);
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
            validateForm,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {navigate === 1 ? (
                <div id="part1">
                  <Form.Row>
                    <div role="alert">
                      {/* {err !== null ? (  <p className='text-danger alert alert-danger form-h'>Please fill all required fields</p>):null}  */}
                    </div>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        First name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      {/* {touched.first_name && errors.first_name ? ( */}
                      <div className="text-danger">{err && err.first_name}</div>
                      {/* ): null}                            */}
                    </Form.Group>

                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Last name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      {/* {touched.last_name && errors.last_name ? ( */}
                      <div className="text-danger">{err && err.last_name}</div>
                      {/* ): null} */}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        as="select"
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                      >
                        <option value={0}>Choose...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {/* {touched.email && errors.email ? ( */}
                      <div className="text-danger">{err && err.email}</div>
                      {/* ): null} */}
                    </Form.Group>
                  </Form.Row>
                  {/* 
                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Password <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <div className="text-danger">{err && err.password}</div>
                    </Form.Group>
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Confirm Password <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={handleChange}
                      />
                      <div className="text-danger">
                        {err && err.confirm_password}
                      </div>
                    </Form.Group>
                  </Form.Row> */}

                  <Form.Group>
                    <div className="text-center">
                      <Link to="/">
                        <Button className="btn-cancel m-3 shadow">
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        className="btn-save m-3 shadow"
                        onClick={() => {
                          validateForm().then((data) => {
                            if (
                              data.first_name ||
                              data.last_name ||
                              data.gender ||
                              data.email ||
                              data.address ||
                              data.confirm_password ||
                              data.password ||
                              data.phonenumber
                            ) {
                              seterr(data);
                            } else {
                              setNavigate(2);
                            }
                          });
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              ) : null}

              {navigate === 2 ? (
                <div className="part2">
                  <Form.Row>
                    <div role="alert">
                      {/* {err2 !== null ? (  <p className='text-danger alert alert-danger form-h'>Please fill all required fields</p>):null}  */}
                    </div>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Company name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="business_name"
                        value={values.business_name}
                        onChange={handleChange}
                      />
                      {/* {touched.business_name && errors.business_name ? ( */}
                      <div className="text-danger">
                        {err2 && err2.business_name}
                      </div>
                      {/* ): null}   */}
                    </Form.Group>

                    <Form.Group as={Col} md={5}>
                      <Form.Label>Company registration number</Form.Label>
                      <Form.Control
                        type="text"
                        name="registration_no"
                        value={values.registration_no}
                        onChange={handleChange}
                      />
                      {/* {touched.registration_no && errors.registration_no ? ( */}
                      <div className="text-danger">
                        {err2 && err2.registration_no}
                      </div>
                      {/* ): null}   */}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Number of branches{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="no_of_branches"
                        value={values.no_of_branches}
                        onChange={handleChange}
                      />
                      {/* {touched.no_of_branches && errors.no_of_branches  ? ( */}
                      <div className="text-danger">
                        {err2 && err2.no_of_branches}
                      </div>
                      {/* ): null}   */}
                    </Form.Group>

                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Number products <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="no_of_products"
                        value={values.no_of_products}
                        onChange={handleChange}
                      >
                        <option value="0">Choose...</option>
                        {subscripitions.map((sub, i) => (
                          <option value={sub.id} key={i}>
                            {"From " + sub.product_min + "-" + sub.product_max}
                          </option>
                        ))}
                      </Form.Control>
                      {touched.no_of_products && errors.no_of_products ? (
                        <div className="text-danger">
                          {err2 && err2.no_of_products}
                        </div>
                      ) : subscripitions.length < 1 ? (
                        <div className="text-danger">
                          you have to create product subscription plan first
                          {
                            <Link
                              style={{
                                color: "red",
                                textDecoration: "underline",
                              }}
                              to={`/settings`}
                            >
                              {" "}
                              here
                            </Link>
                          }
                        </div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>
                  <Form.Group>
                    <div className="mt-3 text-center">
                      <Button
                        className="btn-cancel m-3 shadow"
                        onClick={() => {
                          setNavigate(1);
                        }}
                      >
                        Previous
                      </Button>

                      <Button
                        className="btn-save m-3 shadow"
                        onClick={() => {
                          validateForm().then((data) => {
                            if (
                              data.business_name ||
                              data.registration_no ||
                              data.no_of_branches ||
                              data.no_of_products
                            ) {
                              seterr2(data);
                            } else {
                              setNavigate(3);
                            }
                          });
                        }}
                      >
                        Payment
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              ) : null}

              {navigate === 3 ? (
                <div className="part3">
                  <Form.Row className="justify-content-md-center">
                    <Col md={10}>
                      <Card className="p-5">
                        <Form.Row className="justify-content-md-center">
                          <Form.Group as={Col}>
                            <Form.Label>
                              Payment option{" "}
                              <span className="text-danger">*</span>{" "}
                              <small className="text-muted">
                                ( Trial or Pay Now ){" "}
                              </small>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="payment_option"
                              value={values.payment_option}
                              onChange={handleChange}
                              defaultValue="Choose..."
                            >
                              <option value={0}>Choose...</option>
                              <option value="trial">Trial</option>
                              {/* <option value="paid">Pay now</option> */}
                            </Form.Control>
                            {touched.payment_option && errors.payment_option ? (
                              <div className="text-danger">
                                {errors.payment_option}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Form.Row>

                        {values.payment_option === "paid" ? (
                          <Form.Row className="justify-content-md-center">
                            <Form.Group as={Col}>
                              <Form.Label>
                                Payment plan{" "}
                                <span className="text-danger">*</span>{" "}
                                <small className="text-muted">
                                  ( Monthly or Annually )
                                </small>
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="payment_plan"
                                value={values.payment_plan}
                                onChange={handleChange}
                              >
                                <option value={0}>Choose...</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                                {/* ))} */}
                              </Form.Control>
                              {touched.payment_plan && errors.payment_plan ? (
                                <div className="text-danger">
                                  {errors.payment_plan}
                                </div>
                              ) : null}
                            </Form.Group>
                          </Form.Row>
                        ) : null}
                        {values.payment_option === "paid" ? (
                          <>
                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col}>
                                <div className="shadow p-2">
                                  <h6>
                                    Subscription Plan:{" "}
                                    {subscripitions.map((sub) =>
                                      values.no_of_products == sub.id
                                        ? sub.name
                                        : null
                                    )}
                                  </h6>
                                  <h6>
                                    Total:{" "}
                                    {subscripitions.map((sub) =>
                                      values.no_of_products == sub.id
                                        ? values.payment_plan === "yearly"
                                          ? sub.price * 12
                                          : sub.price
                                        : null
                                    )}
                                  </h6>
                                </div>
                              </Form.Group>
                            </Form.Row>
                          </>
                        ) : null}

                        <Form.Group>
                          <div className="mt-4">
                            {values.payment_option !== "trial" ? (
                              <a
                                className="btn-pay"
                                disabled={isSubmitting}
                                href="http://devnrsapi.needletech.ng/payment-page"
                              >
                                <Button
                                  variant="secondary btn-action"
                                  size="lg"
                                  block
                                >
                                  PAY NOW
                                </Button>

                                {isSubmitting ? (
                                  <span
                                    id="login_spinner"
                                    className="fa fa-spin fa-spinner"
                                  ></span>
                                ) : null}
                              </a>
                            ) : (
                              <Button
                                type="submit"
                                variant="secondary btn-action"
                                size="lg"
                                block
                                disabled={isSubmitting}
                              >
                                CONTINUE
                                {isSubmitting ? (
                                  <span
                                    id="login_spinner"
                                    className="fa fa-spin fa-spinner"
                                  ></span>
                                ) : null}
                              </Button>
                            )}
                            <center>
                              <Button
                                className="btn-cancel m-3 shadow"
                                onClick={() => {
                                  setNavigate(2);
                                }}
                              >
                                Previous
                              </Button>
                            </center>
                          </div>
                        </Form.Group>
                      </Card>
                    </Col>
                  </Form.Row>
                </div>
              ) : null}
            </Form>
          )}
        </Formik>
      </Container>
    </PageBody>
  );
};
