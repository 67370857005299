import React from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { TaskService } from "../../../../Services/taskService";
// import { UserService } from '../../../../Services/userService';
import { AuthContext } from "../../../../Context/AuthContext";
import DatePicker from "react-datepicker";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../Component/Loader";
import { ClientUserService } from "../../../../Services/clientUserService";

const Styles = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
`;

export const Create_business_task = ({ match }) => {
  const [users, setUsers] = React.useState([]);
  const { alert } = React.useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getusers = async () => {
    updateLoader(true, "Loading");
    const response = await ClientUserService.getactiveusers(match.params.id);
    setUsers(response.data.data);
    updateLoader(false);
  };
  React.useEffect(() => {
    getusers();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />

        <Container className="mt-5">
          <h5 className="form-h mb-3">
            {" "}
            <strong>Create New Task</strong>{" "}
          </h5>

          <Formik
            initialValues={{
              title: "",
              priority: "",
              assignee_id: "",
              due_date: "",
              description: "",
            }}
            validationSchema={Yup.object({
              title: Yup.string().trim().required(),
              priority: Yup.string()
                .min(4, "please select an option")
                .required(),
              due_date: Yup.string()
                .required("due date is a required field")
                .nullable(),
              // assignee_id: Yup.number()
              //     .min(1,'please select from the options')
              //     .required('assignee is a required field'),
              description: Yup.string().nullable(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              values.business_id = match.params.id;
              const response = await TaskService.createBusinesstask(values);
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push(`/business-tasks/${match.params.id}`);
              } else {
                alert.error("error creating tasks");
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              setFieldValue,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Task title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter task title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    {touched.title && errors.title ? (
                      <div className="text-danger">{errors.title}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Priority <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="priority"
                      value={values.priority}
                      onChange={handleChange}
                    >
                      <option value={0}>Choose...</option>
                      <option value="critical">Critical</option>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </Form.Control>
                    {touched.priority && errors.priority ? (
                      <div className="text-danger">{errors.priority}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Projected due date <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <DatePicker
                      selected={values.due_date}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                      name="due_date"
                      minDate={new Date()}
                      onChange={(date) => setFieldValue("due_date", date)}
                    />
                    {touched.due_date && errors.due_date ? (
                      <div className="text-danger">{errors.due_date}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Assignee <span className="text-danger"></span>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="assignee_id"
                      value={values.assignee_id}
                      onChange={handleChange}
                    >
                      <option value="0">Choose...</option>
                      {users.map((user, i) => (
                        <option value={user.id} key={i}>
                          {user.first_name + " " + user.last_name}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.assignee_id && errors.assignee_id ? (
                      <div className="text-danger">{errors.assignee_id}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md="10">
                    <Form.Label>Task Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {touched.description && errors.description ? (
                    <div className="text-danger">{errors.description}</div>
                  ) : null}
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/business-tasks/${match.params.id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
