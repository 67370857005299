import React from "react";
import axios from "axios";
import { constant } from "./Constants/constant";
import { Redirect } from "react-router-dom";

export class ApiClient {
  static async request(method, url, body) {
    const BaseURL = process.env.REACT_APP_ROOT_URL + url;
    // https://nrsapi.needletech.ng
    // http://devnrsapi.needletech.ng
    // https://testnrsapi.needletech.ng
    // http://127.0.0.1:8000

    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const BodyRequest = body ? body : undefined;

    const request = await axios({
      method: method,
      url: BaseURL,
      headers: headers,
      data: BodyRequest,
    });

    return request;
  }

  static async get(url, body) {
    return await this.request(constant.GET, url, body);
  }
  static async post(url, body) {
    return await this.request(constant.POST, url, body);
  }
  static async put(url, body) {
    return await this.request(constant.PUT, url, body);
  }

  static async delete(url, body) {
    return await this.request(constant.DELETE, url, body);
  }

  static async patch(url, body) {
    return await this.request(constant.PATCH, url, body);
  }
  static async setToken(token) {
    await localStorage.setItem("token", token);
    return true;
  }

  static async unsetToken() {
    await localStorage.removeItem("token");
    return true;
  }
}

// axios.interceptors.response.use(
//   (res) => console.log("RES", res),
//   (err) => {
//     if (err.response.status === 401) {
//       return (
//         <>
//           <Redirect to="/login" />
//         </>
//       );
//     } else if (err.response.status === 404) {
//       return (
//         <>
//           <Redirect to="/login" />
//         </>
//       );
//     }
//     return Promise.reject(err);
//   }
// );
