import React, { useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Card,
  Jumbotron as Jumbo,
  img,
  Form,
} from "react-bootstrap";
import styled from "styled-components";
import Nav from "../../../Component/Layouts/Nav";
import userImg from "../../../Assets/Img/userImg.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../Context/AuthContext";
import { ConfigService } from "../../../Services/systemConfigService";
import { UserService } from "../../../Services/userService";
import { Link } from "react-router-dom";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { PageBody } from "../../../Component/Loader";
import { phoneRegExp } from "../../../Util/Constants/constant";
import { useHistory } from "react-router-dom";
import { roles as role } from "../../../Util/Constants/constant";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const EditProfile = () => {
  const { user, alert, refresh, user_type } = React.useContext(AuthContext);
  const [roles, setRoles] = React.useState([]);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getRoles = async () => {
    if (user_type === role.Admin) {
      updateLoader(true, "Fetching User");

      const roles = await ConfigService.getroles();
      setRoles(roles.data.data);
      updateLoader(false);
    }
    updateLoader(false);
  };
  useEffect(() => {
    getRoles();
  }, []);

  return (
    <Styles>
      <PageBody isLoading={isLoading} message={message}>
        <Nav />
        <Jumbo fluid></Jumbo>

        <Container>
          <Card>
            <Row className="p-5">
              <Col md={3}>
                <img
                  className=""
                  src={userImg}
                  alt="users"
                  height="150px"
                  width="150px"
                />
              </Col>

              <Col md={9}>
                <Formik
                  initialValues={{
                    first_name: user.first_name,
                    email: user.email,
                    last_name: user.last_name,
                    phonenumber: user.phonenumber,
                    role_id: user.role_id,
                  }}
                  validationSchema={Yup.object({
                    first_name: Yup.string()
                      .trim()
                      .required("first name is a required field"),

                    last_name: Yup.string()
                      .trim()
                      .required("last name is a required field"),

                    phonenumber: Yup.string()
                      .required("phone number is a required field")
                      .max(11, "Maximum of 11")
                      .min(10, "Minimum of 10")
                      .matches(phoneRegExp, "Phone number is not valid"),

                    role_id: Yup.number()
                      .min(1, "please select from the options")
                      .required("role is a required field"),

                    email: Yup.string()
                      .email("Invalid email address")
                      .required(),

                    // address: Yup.string().trim().required(),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    const response = await UserService.updateuser(
                      user.id,
                      values
                    );
                    if (response.status === 200) {
                      setSubmitting(false);
                      alert.success(response.data.message);
                      refresh();
                      history.push("/profile");
                    } else {
                      if (response.response.data.data.email)
                        alert.error(response.response.data.data.email);
                      if (response.response.data.data.phonenumber)
                        alert.error(response.response.data.data.phonenumber);
                    }
                  }}
                >
                  {({
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    dirty,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            First name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleChange}
                          />
                          {touched.first_name && errors.first_name ? (
                            <div className="text-danger">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            Last name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                          />
                          {touched.last_name && errors.last_name ? (
                            <div className="text-danger">
                              {errors.last_name}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            Phone number <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            // type="number"
                            name="phonenumber"
                            value={values.phonenumber}
                            onChange={handleChange}
                          />
                          {touched.phonenumber && errors.phonenumber ? (
                            <div className="text-danger">
                              {errors.phonenumber}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        {user_type === role.Admin ? (
                          <Form.Group as={Col}>
                            <Form.Label>
                              Role <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="role_id"
                              value={values.role_id}
                              onChange={handleChange}
                              disabled
                            >
                              <option value="0">Choose...</option>
                              {roles.map((role, i) => (
                                <option value={role.id} key={i}>
                                  {role.name}
                                </option>
                              ))}
                            </Form.Control>
                            {touched.role_id && errors.role_id ? (
                              <div className="text-danger">
                                {errors.role_id}
                              </div>
                            ) : null}
                          </Form.Group>
                        ) : null}
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            Email <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                        {touched.email && errors.email ? (
                          <div className="text-danger">{errors.email}</div>
                        ) : null}
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}></Form.Group>
                      </Form.Row>

                      <Form.Group>
                        <div className="mt-3 text-center">
                          <Link to="/profile">
                            <Button className="btn-cancel m-3 shadow">
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            type="submit"
                            className="btn-save m-3 shadow"
                            disabled={!dirty}
                          >
                            Update Profile{" "}
                            {isSubmitting ? (
                              <span
                                id="login_spinner"
                                className="fa fa-spin fa-spinner"
                              ></span>
                            ) : null}
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Card>
        </Container>
      </PageBody>
    </Styles>
  );
};
