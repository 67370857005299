import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Button, Form, InputGroup } from "react-bootstrap";
import Nav from "../../../Component/Layouts/Nav";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { ConfigService } from "../../../Services/systemConfigService";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { phoneRegExp } from "../../../Util/Constants/constant";
import { PageBody } from "../../../Component/Loader";
import { ClientService } from "../../../Services/clientService";
// import { UserService } from "../../../Services/userService";
import { AuthContext } from "../../../Context/AuthContext";

export const Edit_client = ({ match }) => {
  // const [toggle, settoggle] = useState(true);
  const [err, seterr] = useState(null);
  const history = useHistory();
  const [client, setClient] = useState(null);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const { alert } = useContext(AuthContext);
  const [subscripitions, setSubscription] = useState([]);

  const getclient = async () => {
    updateLoader(true, "Fetching Client");
    const response = await ClientService.getclient(match.params.id);
    setClient(response.data.data.client);
    updateLoader(false);
  };
  const getsubscriptionPlan = async () => {
    updateLoader(true, "Fetching Subscription Plan");
    const response = await ConfigService.getsubscriptions();

    setSubscription(response.data.data);
    updateLoader(false);
  };

  useEffect(() => {
    getsubscriptionPlan();
    getclient();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Nav />

      <Container>
        {/* <Row>
                    
                    <div className="stepwizard mt-5">
                        <div className="stepwizard-row setup-panel">
                        <div className="stepwizard-step">
                        <a href="#1" type="button" className="btn btn-circle" onClick={()=>{settoggle(true)}}> 1 </a>
                        <p>Personal Details</p>
                        </div>
                        <div className="stepwizard-step">
                        <a href="#2" type="button" className="btn btn-circle" disabled="disabled" onClick={()=>{settoggle(false)}}> 2 </a>
                        <p>Payment Details</p>
                        </div>
                        </div>
                    </div>
            </Row> */}
      </Container>
      <Container className="mt-5">
        {client !== null ? (
          <Formik
            initialValues={{
              first_name: client.user.first_name,
              last_name: client.user.last_name,
              phonenumber: client.user.phonenumber,
              email: client.user.email,
              status: client.user.status,
              address: client.user.address,

            }}
            validationSchema={Yup.object({
              first_name: Yup.string()
                .trim()
                .required("first name is a required field"),

              last_name: Yup.string()
                .trim()
                .required("last name is a required field"),

              phonenumber: Yup.string()
                .required("phone number is a required field")
                .max(14, "phone number must be 14 digits maximum")
                .min(10, "phone number must be 11 digits minimum")
                .matches(phoneRegExp, "Phone number is not valid"),


              email: Yup.string().email("Invalid email address").required(),

              address: Yup.string().trim().required(),
              subscription_plan_id: Yup.number()
                .min(1, "please select an option")
                .nullable("subscription plan is a required field"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const response = await ClientService.updateclient(
                client.id,
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push("/clients");
              } else {
                if (response.response.data.data.email)
                  alert.error(response.response.data.data.email);
                if (response.response.data.data.phonenumber)
                  alert.error(response.response.data.data.phonenumber);
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              validateForm,
              dirty,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Clients Status{" "}
                      <small className="text-muted">
                        ( activate or deactivate )
                      </small>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value={1}>Activate</option>
                      <option value={0}>Deactivate</option>
                    </Form.Control>
                    {touched.status && errors.status ? (
                      <div className="text-danger">{errors.status}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group as={Col} md={5}>
                   
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <div role="alert">
                    {err !== null ? (
                      <p className="text-danger alert alert-danger form-h">
                        Please fill all required fields
                      </p>
                    ) : null}
                  </div>
                  
                </Form.Row>
                <h5 className="form-h mb-3">
                  {" "}
                  <strong>Personal Details</strong>{" "}
                </h5>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      First name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                    />
                    {touched.first_name && errors.first_name ? (
                      <div className="text-danger">{errors.first_name}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Last name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                    />
                    {touched.last_name && errors.last_name ? (
                      <div className="text-danger">{errors.last_name}</div>
                    ) : null}
                  </Form.Group>

                  
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Phone number <span className="text-danger">*</span>
                    </Form.Label>

                    <InputGroup className="mb-2">
                      {/* <InputGroup.Prepend>
                        <InputGroup.Text>
                          <strong>+</strong>
                        </InputGroup.Text>
                      </InputGroup.Prepend> */}
                      <Form.Control
                        // type="number"
                        name="phonenumber"
                        value={values.phonenumber}
                        onChange={handleChange}
                      />
                      {touched.phonenumber && errors.phonenumber ? (
                        <div className="text-danger">{errors.phonenumber}</div>
                      ) : null}
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : null}
                  </Form.Group>
                  
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                 

                  
                </Form.Row>

                {/* <Form.Row className="justify-content-md-center">
                                <Form.Group as={Col} md={10}>
                                <Form.Label>Payment option <span className="text-danger">*</span> <small className="text-muted">( Trial or Pay Now ) </small></Form.Label>
                                <Form.Control as="select" name="payment_option" value={values.payment_option} onChange={handleChange} defaultValue="Choose...">
                                    <option value={0}>Choose...</option>
                                    <option value='trial'>Trial</option>
                                    <option value='paid'>Pay now</option>
                                </Form.Control>
                                {touched.payment_option && errors.payment_option ? (
                                <div className="text-danger">{errors.payment_option}</div>
                            ): null}
                                </Form.Group>
                        </Form.Row> */}

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={10}>
                    <Form.Label>
                      Address <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                    />
                    {touched.address && errors.address ? (
                      <div className="text-danger">{errors.address}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to="/clients">
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={!dirty}
                    >
                      Update{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        ) : null}
      </Container>
    </PageBody>
  );
};
