import { ClientService } from "../../../Services/clientService";
import { ClientCustomerService } from "../../../Services/clientCustomerService";
import { clientType } from "../../actionTypes";

export class ClientThunk {
  static getAll(clients) {
    return async (dispatch) => {
      try {
        if (clients.length === 0) {
          const response = await ClientService.getclients();

          if (response.data.data) {
            dispatch({ type: clientType.GET_ALL, payload: response.data });
            return;
          }
        } else {
          dispatch({ type: clientType.GET_ALL, payload: clients });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getClients(reduxBusiness) {
    return async (dispatch) => {
      try {
        let activeUsersss = {};
        if (reduxBusiness && reduxBusiness.length === 0) {
          const response = await ClientService.getclients();
          if (response.data.data) {
            dispatch({
              type: clientType.GET_ALL,
              payload: response.data,
            });
            return;
          }
        } else {
          activeUsersss.data = reduxBusiness;
          dispatch({
            type: clientType.GET_ALL,
            payload: reduxBusiness,
          });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllCustomer(id) {
    return async (dispatch) => {
      try {
        const response = await ClientCustomerService.getallcustomers(id);
        if (response.data.data) {
          dispatch({ type: clientType.GET_ALL, payload: response.data });
          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllClientCustomers(clients) {
    return async (dispatch) => {
      try {
        const response = await ClientCustomerService.getallauthcustomers();

        if (response.data.data) {
          dispatch({ type: clientType.GET_ALL, payload: response.data });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllActiveClient(reduxClient) {
    return async (dispatch) => {
      try {
        const activeUsersss = {};

        activeUsersss.data =
          reduxClient.data &&
          reduxClient.data.filter(
            (item) => item.status.toString().indexOf("1") !== -1
          );

        if (activeUsersss) {
          dispatch({ type: clientType.GET_ACTIVE, payload: activeUsersss });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllInActiveClient(reduxClient) {
    return async (dispatch) => {
      try {
        const inActiveUsersss = {};

        inActiveUsersss.data =
          reduxClient.data &&
          reduxClient.data.filter(
            (item) => item.status.toString().indexOf("0") !== -1
          );

        if (inActiveUsersss) {
          dispatch({ type: clientType.GET_INACTIVE, payload: inActiveUsersss });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllPaidClient(reduxClient) {
    return async (dispatch) => {
      try {
        const paidUsersss = {};

        paidUsersss.data =
          reduxClient.data &&
          reduxClient.data.filter(
            (item) => item.subscription_status.toString().indexOf("1") !== -1
          );

        console.log("paid", paidUsersss);

        if (paidUsersss) {
          dispatch({ type: clientType.GET_PAID, payload: paidUsersss });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
}
