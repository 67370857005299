import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../../Component/Loader";

const Styles = styled.div``;

export const Edit_category = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [categories, setcategory] = React.useState(null);

  const getProductCategory = async () => {
    updateLoader(true, "Fetching Category");
    const response = await ConfigCustomerService.getproductcategory(
      match.params.id
    );
    setcategory(response.data.data);
    updateLoader(false);
  };
  React.useEffect(() => {
    getProductCategory();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          {categories !== null ? (
            <Formik
              initialValues={{
                category_name: categories.category_name,
                available_in_all: categories.available_in_all,
              }}
              validationSchema={Yup.object({
                category_name: Yup.string()
                  .trim()
                  .required("category name is required"),
                available_in_all: Yup.string(),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                values.business_id = match.params.business_id;
                const response = await ConfigCustomerService.updateproductcategory(
                  match.params.id,
                  values
                );
                if (response.status === 200) {
                  alert.success(response.data.message);
                  setSubmitting(false);
                  history.push(
                    `/business-settings/${match.params.business_id}`
                  );
                } else {
                  alert.error(response.response.data.message);
                }
              }}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row className="justify-content-md-center">
                    <h5 className=" mb-3">
                      {" "}
                      <strong>Edit Category</strong>{" "}
                    </h5>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md="5">
                      <Form.Label>
                        Category name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter category name"
                        name="category_name"
                        value={values.category_name}
                        onChange={handleChange}
                      />
                      {touched.category_name && errors.category_name ? (
                        <div className="text-danger">
                          {errors.category_name}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  {/* <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col} md="5" >
                           
                        <div className="mb-3">
                                        <Form.Check inline label="Available in all store" type='checkbox' id='1' name='available_in_all' value={values.available_in_all} checked={values.available_in_all}  onChange={handleChange}/>                                                              
                                        </div>
                            {touched.available_in_all && errors.available_in_all ? (
                                <div className="text-danger">{errors.available_in_all}</div>
                            ): null} 
                        </Form.Group>
                        </Form.Row> */}

                  <Form.Group>
                    <div className="mt-3 text-center">
                      <Link
                        to={`/business-settings/${match.params.business_id}`}
                      >
                        <Button className="btn-cancel m-3 shadow">
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        className="btn-save m-3 shadow"
                        disabled={!dirty}
                      >
                        Update{" "}
                        {isSubmitting ? (
                          <span
                            id="login_spinner"
                            className="fa fa-spin fa-spinner"
                          ></span>
                        ) : null}
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          ) : null}
        </Container>
      </Styles>
    </PageBody>
  );
};
