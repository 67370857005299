import { ApiClient } from "../Util/ApiClient";

export class ClientSalesService {
  static async checkout(body) {
    try {
      const response = await ApiClient.post(
        "/client/orders/create-order",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  // static async getproducts() {
  //   try {
  //     const response = await ApiClient.get(`/client/inventory-locations/get`);
  //     return response;
  //   } catch (err) {
  //     return err;
  //   }
  // }

  static async createlocation(body) {
    try {
      const response = await ApiClient.post(
        "/client/inventory-locations/create-location",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updatelocation(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/inventory-locations/update-location/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async deletelocation(id) {
    try {
      const response = await ApiClient.delete(
        `/client/inventory-locations/delete-location/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statuslocation(id) {
    try {
      const response = await ApiClient.get(
        `​/client​/inventory-locations/change-status​/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getproducts() {
    try {
      const response = await ApiClient.get("/client/products");
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getproduct(id) {
    try {
      const response = await ApiClient.get(`/client/products/get/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createproduct(body) {
    try {
      const response = await ApiClient.post(
        "/client/products/create-product",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updateproduct(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/products/update-product/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statusproduct(id) {
    try {
      const response = await ApiClient.get(
        `​/client​/products/change-status​/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getservices() {
    try {
      const response = await ApiClient.get("/client/services");
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getservice(id) {
    try {
      const response = await ApiClient.get(`/client/services/get/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createservice(body) {
    try {
      const response = await ApiClient.post(
        "/client/services/create-service",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updateservice(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/services/update-service/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statusservice(id) {
    try {
      const response = await ApiClient.get(
        `​/client​/services​/change-status​/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async mangeclientpayment(body) {
    try {
      const response = await ApiClient.post(
        "/client/sale-payments/create-payment",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }
}
