import React, { useContext }  from 'react';
import { Col, Container, Button, Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Navbar from "../../../../../Component/Layouts/Navbar";
import {Formik} from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../../../../Context/AuthContext';
import { ConfigCustomerService } from '../../../../../Services/systemConfigClient';

const Styles = styled.div `

`

export const Create_tax = ({match}) => {
const {alert} = useContext(AuthContext)
const history = useHistory()
return(
    <Styles>
        <Navbar/>
        
        <Container className="mt-5">
       
                <Formik
                        initialValues={{ rate: '',isDefault:false}}
                        validationSchema={Yup.object({
                            rate: Yup.number()
                            .typeError('Tax rate must be a number')
                            .required("tax rate is required"),
                        
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            values.business_id = match.params.id
    
                            const response = await ConfigCustomerService.creattaxes(values)
                          if(response.status === 200){
                            alert.success(response.data.message)
                            setSubmitting(false);
                            history.push(`/business-settings/${match.params.id}`)
                        }else{
                            alert.error(response.response.data.message)
                        }
                        }}
                        >
                        {({isSubmitting,handleSubmit,handleChange,values,touched,errors}) => (
                        <Form onSubmit={handleSubmit}>
                            
                            <Form.Row className="justify-content-md-center">
                            <h5 className=" mb-3"> <strong>Create New Tax</strong> </h5>
                            </Form.Row>
                       
                       <Form.Row className="justify-content-md-center">
                            <Form.Group as={Col} md="5">
                            <Form.Label>Tax rate <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter tax rate" name='rate' value={values.rate} onChange={handleChange}/>
                            {touched.rate && errors.rate ? (
                                <div className="text-danger">{errors.rate}</div>
                            ): null} 
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col} md="5" >
                            <Form.Label>   <small>(Set Default)</small></Form.Label>
                           
                                        <div className="mb-3">
                                        <Form.Check inline label="Default" type='checkbox' id='1' name='isDefault'  onChange={handleChange}/>                                                              
                                        </div>
                                 
                            {touched.isDefault && errors.isDefault ? (
                                <div className="text-danger">{errors.isDefault}</div>
                            ): null} 
                        </Form.Group>
                        </Form.Row>

                        <Form.Group>
                            <div className="mt-3 text-center"> 
                            <Link to={`/business-settings/${match.params.id}`}>
                                <Button className="btn-cancel m-3 shadow" >
                                    Cancel
                                </Button>
                                </Link>
                                <Button type='submit' className="btn-save m-3 shadow" disabled={isSubmitting} >
                                Save {isSubmitting ? (<span id="login_spinner" className="fa fa-spin fa-spinner"></span>) :null}
                                </Button>              
                                </div>
                        </Form.Group>

                        </Form>
                        )}
                </Formik>
        </Container>
    
    </Styles>

)
}