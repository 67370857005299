import { UserService } from "../../../Services/userService";
import { userType } from "../../actionTypes";

export class usersThunk {
  static loadUsers(id) {
    return async (dispatch) => {
      try {
        if (id !== undefined) {
          const response = await UserService.getclientusers(id);
          console.log("1", response);
          if (response.data.data) {
            dispatch({
              type: userType.GET_ALL_USERS,
              payload: response.data,
            });
            return;
          }
        } else {
          const response = await UserService.getusers();
          console.log("2", response);
          if (response.data.data) {
            dispatch({
              type: userType.GET_ALL_USERS,
              payload: response.data,
            });
            return;
          }
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllUsers(reduxUsers) {
    return async (dispatch) => {
      // console.log("lala", reduxUsers);
      try {
        if (reduxUsers.length === 0) {
          const response = await UserService.getusers();
          console.log("HE", response);
          // const response = await UserService.getclientusers(id);
          if (response.data.data) {
            dispatch({
              type: userType.GET_ALL_USERS,
              payload: response.data,
            });
            return;
          }
        } else {
          dispatch({
            type: userType.GET_ALL_USERS,
            payload: reduxUsers,
          });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getClinetUser(id, reduxClinetUsers) {
    return async (dispatch) => {
      console.log("c", reduxClinetUsers);
      try {
        if (reduxClinetUsers.length === 0) {
          const response = await UserService.getclientusers(id);
          // const response = await UserService.getusers();
          console.log("c2", response);
          if (response.data.data) {
            dispatch({
              type: userType.GET_ALL_USERS,
              payload: response.data,
            });
            return;
          }
        } else {
          dispatch({
            type: userType.GET_ALL_USERS,
            payload: reduxClinetUsers,
          });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getUser(id, reduxUsers, users) {
    return async (dispatch) => {
      try {
        if (!users || users.data === undefined) {
          const response = await UserService.getuser(id);
          if (response.data.data) {
            dispatch({
              type: userType.GET_ONE_USER,
              payload: response.data,
            });
            return;
          }
        } else {
          const users = {};
          users.data =
            reduxUsers.data &&
            reduxUsers.data.find((item) => item.id.toString() === id);
          if (users) {
            dispatch({
              type: userType.GET_ONE_USER,
              payload: users,
            });

            return;
          }
          throw new Error("Client creation failed");
        }
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getActiveUsers(reduxUsers) {
    return async (dispatch) => {
      try {
        const activeUsersss = {};

        activeUsersss.data =
          reduxUsers.data &&
          reduxUsers.data.filter(
            (item) => item.status.toString().indexOf("1") !== -1
          );

        if (activeUsersss) {
          dispatch({
            type: userType.GET_ACTIVE_USERS,
            payload: activeUsersss,
          });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getInActiveUsers(reduxUsers) {
    return async (dispatch) => {
      try {
        const inActiveUsersss = {};

        inActiveUsersss.data =
          reduxUsers.data &&
          reduxUsers.data.filter(
            (item) => item.status.toString().indexOf("0") !== -1
          );

        if (inActiveUsersss) {
          dispatch({
            type: userType.GET_IN_ACTIVE_USERS,
            payload: inActiveUsersss,
          });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
}
