import { Clientreducer } from "./client/client.reducer";
import { BusinessReducer } from "./business/business.reducer";
import { businessUsersReducer } from "./business-users/business-users.reducer";
import { usersReducers } from "./users/users.reducer";

const AllReducers = {
  clients: Clientreducer,
  businesses: BusinessReducer,
  businessUsers: businessUsersReducer,
  users: usersReducers,
};

export default AllReducers;
