import React from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Row,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { AuthContext } from "../../../Context/AuthContext";
import { permission, roles } from "../../../Util/Constants/constant";
import * as moment from "moment";
import User from "../../../Assets/Img/user-icon.png";
import { ClientThunk } from "../../../redux/action/client/client.thunk";
import { useDispatch, useSelector } from "react-redux";

export const Clients = ({ match }) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [active, setActive] = React.useState();
  const [searchclient, setSearchclient] = React.useState("");
  const { permissions, user_type } = React.useContext(AuthContext);
  const [accordionText, setAccordionText] = React.useState(true);
  const [activeName, setActiveName] = React.useState(0);
  const { clients, allclients } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  console.log(clients);
  console.log(allclients);

  const getclients = async () => {
    updateLoader(
      true,
      `Fetching ${
        user_type === roles.Client || match.params.id !== undefined
          ? "Customers"
          : "Clients"
      }`
    );
    if (match.params.id !== undefined) {
      await dispatch(ClientThunk.getAllCustomer(match.params.id));
      setActive(1);
      updateLoader(false);
    } else {
      if (user_type === roles.Client) {
        await dispatch(ClientThunk.getAllClientCustomers(clients));
        setActive(1);
        updateLoader(false);
      } else {
        await dispatch(ClientThunk.getAll(clients));
        setActive(1);
        updateLoader(false);
      }
    }
  };

  const date = (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const getAllClients = React.useCallback(async () => {
    updateLoader(true, "Fetching Clients");
    await dispatch(ClientThunk.getClients(clients));
    setActive(1);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getactiveclients = React.useCallback(async () => {
    updateLoader(true, "Fetching Active Clients");
    await dispatch(ClientThunk.getAllActiveClient(clients));
    setActive(2);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getinactiveclients = React.useCallback(async () => {
    updateLoader(true, "Fetching InActive Clients");
    await dispatch(ClientThunk.getAllInActiveClient(clients));
    setActive(3);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const getpaidclients = React.useCallback(async () => {
    console.log("PAID");
    updateLoader(true, "Fetching Paid Clients");
    await dispatch(ClientThunk.getAllPaidClient(clients));
    setActive(4);
    updateLoader(false);
  }, [dispatch, updateLoader]);

  const allClients =
    allclients.data &&
    allclients.data.filter((client) => {
      if (match.params.id) {
        return (
          client.first_name &&
          client.first_name
            .toLowerCase()
            .indexOf(searchclient.toLowerCase()) !== -1
        );
      } else {
        if (user_type === roles.Admin) {
          return (
            client.user &&
            client.user.first_name
              .toLowerCase()
              .indexOf(searchclient.toLowerCase()) !== -1
          );
        } else {
          return (
            client.first_name &&
            client.first_name
              .toLowerCase()
              .indexOf(searchclient.toLowerCase()) !== -1
          );
        }
      }
    });

  const handleAccordionClick = (i) => {
    setAccordionText(!accordionText);
    setActiveName(i);
  };

  React.useEffect(() => {
    getclients();
  }, []);

  // React.useEffect(() => {
  //   getclients();
  // }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Nav />
      {allclients.length !== 0 && (
        <>
          <Container className="mt-4" fluid>
            <Form inline>
              <Col md={2}>
                {user_type === roles.Admin && match.params.id === undefined ? (
                  permissions &&
                  permissions.includes(permission.client_create) === true ? (
                    <Link to="/create-client">
                      <Button className="btn-create shadow p-2 m-3">
                        Create Client
                      </Button>
                    </Link>
                  ) : null
                ) : null}
              </Col>

              <Col md={3}>
                <div className="input-group">
                  <input
                    className="form-control py-2 border-right-0 border"
                    type="search"
                    placeholder="search"
                    name="search"
                    value={searchclient}
                    onChange={(e) => setSearchclient(e.target.value)}
                  />{" "}
                  <span className="input-group-append">
                    <div className="input-group-text bg-transparent">
                      <i className="fa fa-search"></i>
                    </div>
                  </span>
                </div>
              </Col>
              <Col className="" md={4}>
                <Button
                  className={
                    active === 1 ? "btn-filter-all shadow m-1" : "btn-filter"
                  }
                  onClick={() => {
                    getAllClients();
                  }}
                >
                  All
                </Button>
                {user_type === roles.Admin && match.params.id === undefined ? (
                  <>
                    <Button
                      className={
                        active === 2
                          ? "btn-filter-all shadow m-1"
                          : "btn-filter"
                      }
                      onClick={() => {
                        getactiveclients();
                      }}
                    >
                      Active
                    </Button>
                    <Button
                      className={
                        active === 3
                          ? "btn-filter-all shadow m-1"
                          : "btn-filter"
                      }
                      onClick={() => {
                        getinactiveclients();
                      }}
                    >
                      Inactive
                    </Button>
                    <Button
                      className={
                        active === 4
                          ? "btn-filter-all shadow m-1"
                          : "btn-filter"
                      }
                      onClick={() => {
                        getpaidclients();
                      }}
                    >
                      Paid
                    </Button>
                  </>
                ) : null}
              </Col>
              <Col md={3}>
                <div className="breadcrum">
                  <ul>
                    <Link to="/dashboard">
                      <li>Home</li>
                    </Link>
                    <li>Clients</li>
                  </ul>
                </div>
              </Col>
            </Form>
          </Container>
          <Container fluid>
            <Row>
              {match.params.id === undefined ? (
                user_type === roles.Admin ? (
                  allClients.data < 1 ? (
                    <div className="text-center m-auto">
                      <h3>No record found</h3>
                    </div>
                  ) : (
                    allClients.map((client, i) => (
                      <Col md={4} key={i}>
                        <Card className="b-right mb-3">
                          <Card.Body>
                            <Card.Title>
                              <strong>
                                {client.user.first_name +
                                  " " +
                                  client.user.last_name}
                              </strong>
                            </Card.Title>
                            <small>{client.user.email}</small>
                            <p>{client.user.phonenumber}</p>
                            <div className="float-left">
                              {user_type === roles.Admin &&
                              match.params.id === undefined ? (
                                <Link to={`/manage-client/${client.id}`}>
                                  <Button className="btn-create no-b shadow">
                                    Manage
                                  </Button>
                                </Link>
                              ) : null}
                            </div>
                            <div className="float-right">
                              {user_type === roles.Admin &&
                              match.params.id === undefined ? (
                                permissions &&
                                permissions.includes(permission.client_edit) ===
                                  true ? (
                                  <Link to={`/edit-client/${client.id}`}>
                                    <Button className="btn-create no-b shadow">
                                      Edit
                                    </Button>
                                  </Link>
                                ) : null
                              ) : null}
                            </div>
                            <div className="clearfix mb-3"></div>

                            <Accordion>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                onClick={() => {
                                  handleAccordionClick(client.id);
                                }}
                              >
                                <small>
                                  {" "}
                                  <i className="text-danger"></i>
                                  {!accordionText && activeName === client.id
                                    ? " - Minimize information"
                                    : " + Click to view more information"}
                                </small>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <hr></hr>
                                  <div className="clearfix mt-3">
                                    <div className="float-left">
                                      <small className="text-muted">
                                        Subscription plan
                                      </small>
                                      <p>
                                        {client.payment_option.toUpperCase()}
                                      </p>
                                    </div>

                                    <div className="float-right">
                                      <small className="text-muted">
                                        Subscription status{" "}
                                      </small>
                                      <p>
                                        {client.subscription_status
                                          ? "PAID"
                                          : "TRIAL"}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="clearfix">
                                    <div className="float-left">
                                      <small className="text-muted">
                                        Next payment date
                                      </small>
                                      <p>{date(client.next_payment_date)}</p>
                                    </div>

                                    <div
                                      style={{
                                        paddingRight: "44px",
                                      }}
                                      className="float-right"
                                    >
                                      <small className="text-muted">
                                        User status
                                      </small>
                                      <p>

                                        {client.status === 1
                                          ? "ACTIVE"
                                          : "INACTIVE"}
                                      </p>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Accordion>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  )
                ) : allClients < 1 ? (
                  <div className="text-center m-auto">
                    <h3>No record found</h3>
                  </div>
                ) : (
                  allClients.map((client, i) => (
                    <Col md={3} key={i} className="mb-5c mt-5">
                      <Card className="text-center card-user">
                        <center>
                          <img
                            className="user-img"
                            src={User}
                            alt="user icon"
                          />
                        </center>
                        <Card.Body className="mt-4">
                          <p className="p-0 mt-3">
                            {" "}
                            <i
                              className={
                                client.status === 0
                                  ? "text-danger fa fa-circle"
                                  : "text-success fa fa-circle"
                              }
                            ></i>
                          </p>

                          <Card.Title>
                            <strong>
                              {client !== null
                                ? client.first_name + " " + client.last_name
                                : null}
                            </strong>
                          </Card.Title>

                          <small>{client.email}</small>
                          <p>{client.phonenumber}</p>
                          {/* {user_type === roles.Admin ? */}
                          <em className="text-danger">
                            {client.role && client.role.name}
                          </em>
                          {/* :null} */}
                          <p></p>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )
              ) : allClients < 1 ? (
                <div className="text-center m-auto">
                  <h3>No record found</h3>
                </div>
              ) : (
                allClients.map((client, i) => (
                  <Col md={3} key={i} className="mb-5c mt-5">
                    <Card className="text-center card-user">
                      <center>
                        <img className="user-img" src={User} alt="user icon" />
                      </center>
                      <Card.Body className="mt-4">
                        <p className="p-0 mt-3">
                          {" "}
                          <i
                            className={
                              client.status === 0
                                ? "text-danger fa fa-circle"
                                : "text-success fa fa-circle"
                            }
                          ></i>
                        </p>

                        <Card.Title>
                          <strong>
                            {client !== null
                              ? client.first_name + " " + client.last_name
                              : null}
                          </strong>
                        </Card.Title>

                        <small>{client.email}</small>
                        <p>{client.phonenumber}</p>
                        {/* {user_type === roles.Admin ? */}
                        <em className="text-danger">
                          {client.role && client.role.name}
                        </em>
                        {/* :null} */}
                        <p></p>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Container>
        </>
      )}
    </PageBody>
  );
};
