import React, { useContext } from "react";
import {
  Col,
  Container,
  Button,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { ClientInventoryService } from "../../../../../Services/clientInventoryService";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../../Component/Loader";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";
import DatePicker from "react-datepicker";

const Styles = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
  .ml-5 {
    margin-left: 6rem !important;
  }
`;

export const Create_supply = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [products, setProduct] = React.useState([]);
  const [categories, setCategory] = React.useState(null);
  const [catName, setCatName] = React.useState("choose..");
  const [cat, setCat] = React.useState("");

  const getProductCategory = async () => {
    updateLoader(true, "Loading");
    const getcategory = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategory(getcategory.data.data);
    updateLoader(false);
  };

  const getProduct = async (category_id, category_name) => {
    setCatName(category_name);
    updateLoader(true, "Loading");
    const getproducts = await ClientInventoryService.getproductsByCategory(
      category_id
    );
    setProduct(getproducts.data.data);
    setCat(category_id);
    updateLoader(false);
  };

  React.useEffect(() => {
    getProductCategory();
  }, []);
  React.useEffect(() => {}, [products]);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          <Formik
            initialValues={{
              product_id: "",
              quantity: "",
              date_of_supply: "",
              product_category_id: cat,
              supplier_name: "",
            }}
            validationSchema={Yup.object({
              product_id: Yup.number()
                .min(1, "please select from the options")
                .required("product name is required"),

              date_of_supply: Yup.string().required("date is required"),

              quantity: Yup.string().required("quantity is required"),

              product_category_id: Yup.number()
                .min(1, "please select from the options")
                .required("products category is required"),

              supplier_name: Yup.string().required(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              values.business_id = match.params.id;
              console.log(values);
              const response = await ClientInventoryService.createsupply(
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push(`/inventory/${match.params.id}`);
              } else {
                alert.error(response.response.data.message);
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="">
                  <h5 className="mb-5 ml-5">
                    {" "}
                    <strong>Create New Request</strong>{" "}
                  </h5>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Product category <span className="text-danger">*</span>{" "}
                    </Form.Label>
                    {/* <Form.Control
                      as="select"
                      name="product_category_id"
                      value={values.product_category_id}
                      onChange={handleChange}
                      onClick={() => {
                        if (values.product_category_id >= 1)
                          getProduct(values.product_category_id);
                      }}
                    >
                      <option value="0">Choose...</option>
                      {categories.map((category, i) => (
                        <option
                          value={category.id}
                          onClick={() => getProduct(category.id)}
                          key={i}
                        >
                          {category.category_name}
                         </option>
                      ))}
                    </Form.Control> */}
                    <div
                      style={{
                        border: "1px solid #1C3FAA",
                        background: "white",
                        boxShadow: "0px 6px 20px 0px rgba(28, 63, 170, 0.1)",
                        height: "48px",
                        borderRadius: "9px",
                      }}
                    >
                      <DropdownButton
                        title={catName}
                        id="dropdown-item-button"
                        variant="light"
                        style={{
                          padding: "5px",
                        }}
                      >
                        <div className="">
                          {categories.map((cat, i) => (
                            <Dropdown.Item as="button">
                              <p
                                key={i}
                                onClick={() =>
                                  getProduct(cat.id, cat.category_name)
                                }
                              >
                                {cat.category_name}
                              </p>{" "}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                    </div>
                    {touched.product_category_id &&
                    errors.product_category_id ? (
                      <div className="text-danger">
                        {errors.product_category_id}
                      </div>
                    ) : categories.length < 1 ? (
                      <div className="text-danger">
                        you have to create product category first
                        {
                          <Link
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            to={`/create-category/${match.params.id}`}
                          >
                            {" "}
                            here
                          </Link>
                        }
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      {" "}
                      Product name<span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="product_id"
                      value={values.product_id}
                      onChange={handleChange}
                    >
                      <option value="0">Choose...</option>
                      {products.map((product, i) => (
                        <option value={product.id} key={i}>
                          {product.product_name}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.product_id && errors.product_id ? (
                      <div className="text-danger">{errors.product_id}</div>
                    ) : null}

                    {/* // ) : products.length < 1 ? (
                    //   <div className="text-danger">
                    //     you have to create product first @
                    //     {
                    //       <Link
                    //         style={{ color: "red" }}
                    //         to={`/create-product/${match.params.id}`}
                    //       >
                    //         here
                    //       </Link>
                    //     }
                    //   </div>
                    // ) : null} */}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Quantity<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter quantity"
                      name="quantity"
                      value={values.quantity}
                      onChange={handleChange}
                    />
                    {touched.quantity && errors.quantity ? (
                      <div className="text-danger">{errors.quantity}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Date <span className="text-danger">*</span>
                    </Form.Label>
                    <DatePicker
                      selected={values.date_of_supply}
                      dateFormat="MMMM d, yyyy"
                      className="form-control"
                      name="date_of_supply"
                      minDate={new Date()}
                      onChange={(date) => setFieldValue("date_of_supply", date)}
                    />{" "}
                    {touched.date_of_supply && errors.date_of_supply ? (
                      <div className="text-danger">{errors.date_of_supply}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={10}>
                    <Form.Label>
                      {" "}
                      Supplier<span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="supplier_name"
                      value={values.supplier_name}
                      onChange={handleChange}
                    />

                    {touched.supplier_name && errors.supplier_name ? (
                      <div className="text-danger">{errors.supplier_name}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/inventory/${match.params.id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
