import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";

const Styles = styled.div``;

export const Create_category = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Styles>
      <Navbar />

      <Container className="mt-5">
        <Formik
          initialValues={{ category_name: "", available_in_all: false }}
          validationSchema={Yup.object({
            category_name: Yup.string()
              .trim()
              .required("category name is required"),
            available_in_all: Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.business_id = match.params.id;
            const response = await ConfigCustomerService.createproductcategory(
              values
            );
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              await history.goBack();
            } else {
              alert.error(response.response.data.message);
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row className="justify-content-md-center">
                <h5 className=" mb-3">
                  {" "}
                  <strong>Create Category</strong>{" "}
                </h5>
              </Form.Row>

              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md="5">
                  <Form.Label>
                    Category name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category name"
                    name="category_name"
                    value={values.category_name}
                    onChange={handleChange}
                  />
                  {touched.category_name && errors.category_name ? (
                    <div className="text-danger">{errors.category_name}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link onClick={history.goBack}>
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </Styles>
  );
};
