import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Tab,
  Tabs,
  Row,
  Table,
  Dropdown,
  Pagination,
  DropdownButton,
  Accordion
} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import User from "../../../../Assets/Img/user-icon.png";
import { PageBody } from "../../../../Component/Loader";
import { ConfirmTemplate } from "../../../../Component/confirm";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import { AuthContext } from "../../../../Context/AuthContext";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { ConfigService } from "../../../../Services/systemConfigService";
import * as moment from "moment";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { permission_client } from "../../../../Util/Constants/constant";
import { removeUnderscores } from "../../../../Util/removeUnderscore";

import { saveAs } from "file-saver";

const Styles = styled.div`
  .dropdown-toggle::after {
    display: none !important;
  }

  .custom-ui {
    background: red;
  }
  .cap{ 
    text-transform: capitalize;
   }
`;

export const Business_settings = ({ match }) => {
  const [taxes, setTaxes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchLog, setSearchlog] = useState("");
  const [activeTab, setActiveTab] = useState("tax");
  // const [discounts,setDiscounts] = React.useState([])
  // const [searchlocation, setSearchlocation] = useState("");
  // const [searchAccount, setSearchAccount] = useState("");
  // const [searchTaxes, setSearchTaxes] = useState("");
  // const [searchPolicies, setSearchPolicies] = useState("");
  const [roles, setRoles] = useState([]);
  const { alert, permissions } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  //   const [search, setSearch] = useState("");


  {(window.localStorage.getItem('bus_set')===null) && window.localStorage.setItem('bus_set', JSON.stringify("tax"))}


  const date = (date) => {
    if (date) {
      return moment(date).fromNow();
    }
  };
  const getLocations = async () => {
    updateLoader(true, "Loading");
    const role = await ClientInventoryService.getlocations(match.params.id);
    setLocations(role.data.data);
    //     if(active === null || false){
    //    setFilter(role.data.data)
    //    setActive(true)
    //     }
    updateLoader(false);
  };
  const getTaxes = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.gettaxes(match.params.id);
    setTaxes(response.data.data);
    updateLoader(false);
  };
  const getLogs = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getlogs(match.params.id);
    setLogs(response.data.data);
    updateLoader(false);
  };
  const getAccounts = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigCustomerService.getaccounts(match.params.id);
    setAccounts(role.data.data);
    updateLoader(false);
  };

  const getPolicies = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigCustomerService.getreturnPolicies(match.params.id);
    setPolicies(role.data.data);
    updateLoader(false);
  };

  const getProductCategories = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategories(response.data.data);
    updateLoader(false);
  };

  const exportFile = async (e) => {
    e.preventDefault();
    const response = await ConfigCustomerService.exportFile(match.params.id);
    console.log(response);

    // const downloadedFile = await response.blob();
    // console.log(response)
    // const documentName = Math.random().toString(36).substring(7);
    // saveAs(downloadedFile, documentName + '.pdf');
    // alert.success("Downloaded Succesfully");
  };

  // const getDiscounts = async ()=>{
  //     updateLoader(true, "Loading");
  //     const response = await ConfigCustomerService.getdiscounts(match.params.id)
  //     setDiscounts(response.data.data)
  //     updateLoader(false);
  // }

  const getroles = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigService.getclientroles(match.params.id);
    setRoles(role.data.data);
    updateLoader(false);
  };

  const onChangeLocationStatus = (id) => {
    const request = async () => {
      updateLoader(true, "Changing Location Status");
      const response = await ClientInventoryService.statuslocation(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getTaxes();
      getAccounts();
      getPolicies();
      getProductCategories();
      getLocations();

      // getDiscounts()
      getroles();
      getLogs();
    };

    ConfirmTemplate(
      {
        title: "Change Location Status",
        message: "Are You Sure You Want To change This Location status",
      },
      request
    );
  };

  const onDeleteTax = (id) => {
    const request = async () => {
      updateLoader(true, "Deleting Tax Rate");
      const response = await ConfigCustomerService.deletetax(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getTaxes();
      getAccounts();
      getPolicies();
      getProductCategories();
      getLocations();

      // getDiscounts()
      getroles();
      getLogs();
    };

    ConfirmTemplate(
      {
        title: "Deleting Tax Rate",
        message: "Are You Sure You Want To Delete This Tax Rate",
      },
      request
    );
  };

  // const onDeleteAccount = (id) =>{
  //     const request = async ()=>{
  //         updateLoader(true, "Deleting Bank Account");
  //        const response =  await  ConfigCustomerService.deleteproductcategories(id)
  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             getTaxes()
  //             getAccounts()
  //             getPolicies()
  //          }

  //     ConfirmTemplate({title:"Deleting  Bank Account",message:"Are You Sure You Want To Delete This  Bank Account"},request)
  // }

  const onChangePolicyStatus = (id) => {
    const request = async () => {
      updateLoader(true, "Changing Return Policy Status");
      const response = await ConfigCustomerService.changestatuspolicy(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getTaxes();
      getAccounts();
      getPolicies();
      getProductCategories();
      getLocations();

      // getDiscounts()
      getroles();
      getLogs();
    };

    ConfirmTemplate(
      {
        title: "Change Return Policy Status",
        message: "Are You Sure You Want To change This policy status",
      },
      request
    );
  };

  // const onChangeDiscountStatus = (id,status) =>{
  //     const request = async ()=>{
  //         updateLoader(true, "Changing Discount Status");
  //         let response
  //         if(status){
  //             response =  await  ConfigCustomerService.deactivatediscount(id)
  //         }else{
  //             response =  await  ConfigCustomerService.activatediscount(id)
  //         }

  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             getTaxes()
  //             getAccounts()
  //             getPolicies()
  //             getProductCategories()
  //             getDiscounts()
  //             getroles()
  //             getLogs()
  //          }

  //     ConfirmTemplate({title:"Change Discount status",message:"Are You Sure You Want To change This Discount status"},request)
  // }
  const onChangeAccountStatus = (id) => {
    const request = async () => {
      updateLoader(true, "Changing  bank account status");
      const response = await ConfigCustomerService.changestatusaccount(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getTaxes();
      getAccounts();
      getPolicies();
      getProductCategories();
      getLocations();

      getroles();
      getLogs();
    };

    ConfirmTemplate(
      {
        title: "Change bank account status",
        message: "Are you sure you want to change this  bank account status",
      },
      request
    );
  };

  // const onDeletePolicy = (id) =>{
  //     const request = async ()=>{
  //         updateLoader(true, "Deleting return Policy");
  //        const response =  await  ConfigCustomerService.deletepolicy(id)
  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             getTaxes()
  //             getAccounts()
  //             getPolicies()
  //          }

  //     ConfirmTemplate({title:"Deleting return Policy",message:"Are You Sure You Want To Delete This return Policy"},request)
  // }

  // const allLocations = locations.filter((location) => {
  //   return (
  //     location.address.toLowerCase().indexOf(searchlocation.toLowerCase()) !==
  //     -1
  //   );
  // });

  // const allAccounts = accounts.filter((account) => {
  //   return (
  //     account.bank_name.toLowerCase().indexOf(searchAccount.toLowerCase()) !==
  //     -1
  //   );
  // });

  // const allPolicies = policies.filter((policy) => {
  //   return (
  //     policy.policy_name.toLowerCase().indexOf(searchPolicies.toLowerCase()) !==
  //     -1
  //   );
  // });

  // const allTaxes = taxes.filter((tax) => {
  //   return (
  //     tax.rate.toString().toLowerCase().indexOf(searchTaxes.toLowerCase()) !==
  //     -1
  //   );
  // });

  const allLogs = logs.filter((log) => {
    return (
      log.user.first_name
        .toString()
        .toLowerCase()
        .indexOf(searchLog.toLowerCase()) !== -1
    );
  });

  useEffect(() => {
    getTaxes();
    getAccounts();
    getPolicies();
    getProductCategories();
    getroles();
    getLogs();
    getLocations();
    









    const actTab = window.localStorage.getItem('bus_set');
    setActiveTab(JSON.parse(actTab));
   
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('bus_set', JSON.stringify(activeTab));
  });



  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />
        <Container fluid className="mt-5">
          <Tabs 
          
          
          
          
          
          
          
          
          
          
          defaultActiveKey={activeTab} 
          onSelect={(k) => setActiveTab(k)}
          
          >
            <Tab eventKey="tax" title="Tax">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_config_create
                  ) === true ? (
                    <Link to={`/create-tax/${match.params.id}`}>
                      <Button className="btn-create shadow">Add New Tax</Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchTaxes}
                      onChange={(e) => setSearchTaxes(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div> */}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
            <Form.Control as="select"  name="filter">
                    <option value={0}>All</option>
                    <option value="male">some</option>
                    <option value="female">others</option>
                 </Form.Control>
            </div> */}
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Configuration</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>TAX RATE</th>
                    <th> DEFAULT TAX RATE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* {allTaxes < 1 ? (
                    <div className="text-center m-auto">
                      <h1>No record found</h1>
                    </div>
                  ) : ( */}
                  {taxes.map((tax, i) => (
                    <tr key={i}>
                      <td>{tax.rate}%</td>
                      <td>{tax.isDefault ? "Default" : " "}</td>
                      <td className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="lite"
                            size="sm"
                            className="shadow"
                          >
                            <span>
                              <i
                                className="fa fa-ellipsis-v font-weight-bold"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {permissions &&
                            permissions.includes(
                              permission_client.client_config_edit
                            ) === true ? (
                              <Link
                                to={`/edit-tax/${tax.id}/${match.params.id}`}
                              >
                                <Dropdown.Item
                                  href={`/edit-tax/${tax.id}/${match.params.id}`}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Link>
                            ) : null}
                            {permissions &&
                            permissions.includes(
                              permission_client.client_config_delete
                            ) === true ? (
                              <Dropdown.Item
                                onClick={() => {
                                  onDeleteTax(tax.id);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <Pagination>
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Next />
              </Pagination> */}
            </Tab>

            <Tab eventKey="policy" title="Return Policy">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_config_create
                  ) === true ? (
                    <Link to={`/create-policy/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Policy
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchPolicies}
                      onChange={(e) => setSearchPolicies(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div> */}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
            <Form.Control as="select"  name="filter">
                    <option value={0}>All</option>
                    <option value="male">some</option>
                    <option value="female">others</option>
                 </Form.Control>
            </div> */}
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Configuration</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {/* {allPolicies < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : ( */}
                {policies.map((policy, i) => (
                  <Col md={4} key={i}>
                    <Card>
                      <Card.Body>
                        <Card.Title className="float-left">
                          {" "}
                          <strong>{policy.policy_name}</strong>{" "}
                        </Card.Title>

                        <Dropdown className="float-right">
                          <Dropdown.Toggle
                            variant="lite"
                            size="sm"
                            className="shadow"
                          >
                            <span>
                              <i
                                className="fa fa-ellipsis-v font-weight-bold"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {permissions &&
                            permissions.includes(
                              permission_client.client_config_edit
                            ) === true ? (
                              <Link
                                to={`/edit-policy/${policy.id}/${match.params.id}`}
                              >
                                <Dropdown.Item
                                  href={`/edit-policy/${policy.id}/${match.params.id}`}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Link>
                            ) : null}
                            <Dropdown.Item
                              onClick={() => onChangePolicyStatus(policy.id)}
                            >
                              {policy.status ? "Deactivate" : "Activate"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="clearfix"></div>

                        <h5>Policy</h5>
                        <div className="text-truncate">
                          <small>
                            {/* With our simple returns process, 
                            you can shop online worry-free. If something doesn’t work out,
                             you can send most items back within 30 days of delivery… */}

                            {policy.return_duration}
                          </small>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>

            <Tab eventKey="role" title="Roles">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_config_create
                  ) === true ? (
                    <Link to={`/business-role/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Role
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <input className="form-control py-2 border-right-0 border" type="search" placeholder="search"  />
                    <span className="input-group-append">
                        <div className="input-group-text bg-transparent"><i className="fa fa-search"></i></div>
                    </span>
                </div>   */}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
            <Form.Control as="select"  name="filter">
                    <option value={0}>All</option>
                    <option value="male">some</option>
                    <option value="female">others</option>
                 </Form.Control>
            </div> */}
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Configuration</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              {roles.map((role, i) => (
              <Row>
                <Col md={12}>
                <Card className="mb-3">
                  
                <Card.Body>
                  <Card.Title className="float-left"><h3 className="cap">{role.name}</h3></Card.Title>
                  <Card.Title className="float-right">
                  {permissions &&
                        permissions.includes(
                          permission_client.client_config_edit
                        ) === true ? (
                          <Link
                            to={`/edit-business-role/${role.id}/${match.params.id}`}
                          >
                            <Button
                              variant="light"
                              size="sm"
                              className="btn shadow"
                            >
                              <i className="fa fa-ellipsis-v text-danger"></i>
                            </Button>
                          </Link>
                        ) : null}
                  </Card.Title>
                  <div className="clearfix"></div>

                  <Row>
                    <Col>
                    <Accordion className="shadow">
                                <Card>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="0"
                                  >
                                    Permission
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                      <ul>
                                      {role.permissions &&
                          role.permissions.map((permission, i) => (
                            <span key={i}>
                              {removeUnderscores(permission.model) +
                                " " +
                                permission.name}
                              ,
                            </span>
                          ))}
                                      </ul>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                    </Col>
                    <Col>
                    <Accordion className="shadow">
                                <Card>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="0"
                                  >
                                    Assigned
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                      <ul>
                                      {role.users &&
                                      role.users.map((user, i) => (
                                        <span key={i}>{user.first_name},</span>
                                      ))}
                                      </ul>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
                </Col>
              </Row>
               ))}
              
            </Tab>

            <Tab eventKey="log" title="Logs">
              <Form inline className="mb-4">
                <Col md={2}></Col>
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchLog}
                      onChange={(e) => setSearchlog(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="input-group">
                    {/* <Form.Control as="select" name="filter">
                      <option onClick={exportFile}>Export</option>
                    </Form.Control> */}
                    <DropdownButton title="Download" id="dropdown-item-button">
                      <div className="dropdown-list">
                        <Dropdown.Item>
                          <p>All</p>
                        </Dropdown.Item>

                        <Dropdown.Item as="button">
                          <p onClick={exportFile}>Export</p>{" "}
                        </Dropdown.Item>
                      </div>
                    </DropdownButton>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Configuration</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Card>
                <Card.Body>
                  <Card.Text>Logs</Card.Text>
                </Card.Body>
              </Card>
              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>ACTION</th>
                    <th>TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {allLogs < 1 ? (
                    <div className="text-center m-auto">
                      <h1>No record found</h1>
                    </div>
                  ) : (
                    allLogs.map((log, i) => (
                      <>
                        <tr>
                          <td>
                            <img
                              className="activity-icon float-left mr-2"
                              src={User}
                              alt="users icon"
                            />
                            <small className="mb-5">
                              {" "}
                              <strong>
                                {log.user && log.user.first_name}{" "}
                              </strong>{" "}
                            </small>
                          </td>
                          <td>
                            <small className="text-muted mt-3">
                              {" "}
                              {log.activity}
                            </small>
                          </td>
                          <td>
                            <small className="">{date(log.created_at)}</small>
                          </td>
                        </tr>
                      </>
                    ))
                  )}
                </tbody>
              </Table>
              {/* <Pagination>
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Next />
              </Pagination> */}
            </Tab>

            <Tab eventKey="accounts" title="Business Account">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_config_create
                  ) === true ? (
                    <Link to={`/create-account/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Account
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchAccount}
                      onChange={(e) => setSearchAccount(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div> */}
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Configuration</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {/* {allAccounts < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : ( */}
                {accounts.map((account, i) => (
                  <Col md={4}>
                    <Card className="b-right">
                      <Card.Body>
                        <Card.Title className="float-left">
                          {" "}
                          <strong>{account.bank_name}</strong>{" "}
                        </Card.Title>

                        <Dropdown className="float-right">
                          <Dropdown.Toggle
                            variant="lite"
                            size="sm"
                            className="shadow"
                          >
                            <span>
                              <i
                                className="fa fa-ellipsis-v font-weight-bold"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {permissions &&
                            permissions.includes(
                              permission_client.client_config_edit
                            ) === true ? (
                              <>
                                <Link
                                  to={`/edit-account/${account.id}/${match.params.id}`}
                                ></Link>
                                <Dropdown.Item
                                  href={`/edit-account/${account.id}/${match.params.id}`}
                                >
                                  Edit
                                </Dropdown.Item>
                              </>
                            ) : null}
                            <Dropdown.Item
                              onClick={() => onChangeAccountStatus(account.id)}
                            >
                              {account.status ? "Deactivate" : "Activate"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="clearfix"></div>

                        <h5>{account.account_name}</h5>
                        <small>{account.account_number}</small>

                        <div className="clearfix mt-3">
                          {/* <Button className="btn bg-blue">
                            {account.status ? "Default" : "Not Default"}
                          </Button> */}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>

            <Tab eventKey="Location" title="Inventory Location">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_config_create
                  ) === true ? (
                    <Link to={`/create-location/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Location
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchlocation}
                      onChange={(e) => setSearchlocation(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div> */}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
            <Form.Control as="select"  name="filter">
                    <option value={0}>Export</option>
                    <option value="download">Download</option>
                    <option value="clear">Clear</option>
                 </Form.Control> 
            </div> */}
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Inventory</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {/* {allLocations < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : ( */}
                {locations.map((location, i) => (
                  <Col md={4} key={i}>
                    <Card className="b-right">
                      <Card.Body>
                        <Card.Title className="float-left">
                          {" "}
                          <strong>{location.address}</strong>{" "}
                        </Card.Title>

                        <Dropdown className="float-right">
                          <Dropdown.Toggle
                            variant="lite"
                            size="sm"
                            className="shadow"
                          >
                            <span>
                              <i
                                className="fa fa-ellipsis-v font-weight-bold"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {permissions &&
                            permissions.includes(
                              permission_client.client_config_edit
                            ) === true ? (
                              <Link
                                to={`/edit-location/${location.id}/${match.params.id}`}
                              >
                                <Dropdown.Item
                                  href={`/edit-location/${location.id}/${match.params.id}`}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Link>
                            ) : null}
                            <Dropdown.Item
                              onClick={() =>
                                onChangeLocationStatus(location.id)
                              }
                            >
                              {location.status ? "Deactivate" : "Activate"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="clearfix"></div>
                        <small className="text-muted mt-4">Contact</small>
                        <h6>{location.contact_number}</h6>
                        {/* <h6>Chris@needletechnology,.com.ng</h6> */}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Styles>
    </PageBody>
  );
};
