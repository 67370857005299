import { BusinessService } from "../../../Services/businessService";
import { businessType } from "../../actionTypes";

export class businessThunk {
  static loadBusineses(id, reduxBusiness) {
    return async (dispatch) => {
      try {
        if (id !== undefined) {
          const response = await BusinessService.getClientbusinesses(id);
          if (response.data.data) {
            dispatch({
              type: businessType.GET_ALL_BUSINESS,
              payload: response.data,
            });
            return;
          }
        } else {
          const response = await BusinessService.getbusinesses();
          if (response.data.data) {
            dispatch({
              type: businessType.GET_ALL_BUSINESS,
              payload: response.data,
            });
            return;
          }
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAll(id, reduxBusiness) {
    return async (dispatch) => {
      try {
        if (reduxBusiness && reduxBusiness.length === 0) {
          const response = await BusinessService.getClientbusinesses(id);
          if (response.data.data) {
            dispatch({
              type: businessType.GET_ALL_BUSINESS,
              payload: response.data,
            });
            return;
          }
        } else {
          dispatch({
            type: businessType.GET_ALL_BUSINESS,
            payload: reduxBusiness,
          });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getBusinesses(reduxBusiness) {
    return async (dispatch) => {
      try {
        if (reduxBusiness && reduxBusiness.length === 0) {
          console.log("22");
          const response = await BusinessService.getbusinesses();
          if (response.data.data) {
            dispatch({
              type: businessType.GET_ALL_BUSINESS,
              payload: response.data,
            });
            return;
          }
        } else {
          dispatch({
            type: businessType.GET_ALL_BUSINESS,
            payload: reduxBusiness,
          });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getBusiness(id, reduxBusiness, busines) {
    return async (dispatch) => {
      try {
        if (!busines || busines.data === undefined) {
          const response = await BusinessService.getbusiness(id);
          if (response.data.data) {
            dispatch({
              type: businessType.GET_ONE_BUSINESS,
              payload: response.data,
            });
            return;
          }
        } else {
          const business = {};
          business.data =
            reduxBusiness.data &&
            reduxBusiness.data.find((item) => item.id.toString() === id);
          if (business) {
            dispatch({
              type: businessType.GET_ONE_BUSINESS,
              payload: business,
            });

            return;
          }
          throw new Error("Client creation failed");
        }
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  // static getBusiness(id, reduxBusiness) {
  //   return async (dispatch) => {
  //     // console.log("RED", reduxBusiness);
  //     console.log("RED 2", id);
  //     try {
  //       const business = {};
  //       business.data =
  //         reduxBusiness.data &&
  //         reduxBusiness.data.find((item) => item.id.toString() === id);
  //       console.log("ONE", business);
  //       if (business) {
  //         dispatch({
  //           type: businessType.GET_ONE_BUSINESS,
  //           payload: business,
  //         });

  //         return;
  //       }

  //       throw new Error("Client creation failed");
  //     } catch (error) {
  //       // TODO: dispatch error to errorReducer
  //       // to be implemented
  //     }
  //   };
  // }

  static getAllActiveBusiness(reduxBusiness) {
    return async (dispatch) => {
      try {
        const activeUsersss = {};

        activeUsersss.data =
          reduxBusiness.data &&
          reduxBusiness.data.filter(
            (item) => item.status.toString().indexOf("1") !== -1
          );

        if (activeUsersss) {
          dispatch({
            type: businessType.GET_ACTIVE_BUSINESS,
            payload: activeUsersss,
          });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllInActiveBusiness(reduxBusiness) {
    return async (dispatch) => {
      try {
        const inActiveUsersss = {};

        inActiveUsersss.data =
          reduxBusiness.data &&
          reduxBusiness.data.filter(
            (item) => item.status.toString().indexOf("0") !== -1
          );

        if (inActiveUsersss) {
          dispatch({
            type: businessType.GET_IN_ACTIVE_BUSINESS,
            payload: inActiveUsersss,
          });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
}
