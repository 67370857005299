import { ApiClient } from "../Util/ApiClient";
import axios from "axios";

export class ClientInventoryService {
  static async getlocations(id) {
    try {
      const response = await ApiClient.get(`/client/inventory-locations/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getlocation(id) {
    try {
      const response = await ApiClient.get(
        `/client/inventory-locations/get/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getServicesIven(id) {
    try {
      const response = await ApiClient.get(`/filter/services/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createlocation(body) {
    try {
      const response = await ApiClient.post(
        "/client/inventory-locations/create-location",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updatelocation(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/inventory-locations/update-location/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async deletelocation(id) {
    try {
      const response = await ApiClient.delete(
        `/client/inventory-locations/delete-location/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statuslocation(id) {
    try {
      const response = await ApiClient.put(
        "/client/inventory-locations/change-status/" + id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getproducts(id) {
    try {
      const response = await ApiClient.get(`/client/products/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getproductsByCategory(id) {
    try {
      const response = await ApiClient.get(
        `/client/product-categories/get/products/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getproduct(id) {
    try {
      const response = await ApiClient.get(`/client/products/get/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createproduct(body) {
    try {
      const response = await ApiClient.post(
        "/client/products/create-product",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updateproduct(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/products/update-product/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statusproduct(id) {
    try {
      const response = await ApiClient.put(
        "/client/products/change-status/" + id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getservices(id) {
    try {
      const response = await ApiClient.get(`/client/services/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getservice(id) {
    try {
      const response = await ApiClient.get(`/client/services/get/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createservice(body) {
    try {
      const response = await ApiClient.post(
        "/client/services/create-service",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updateservice(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/services/update-service/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statusservice(id) {
    try {
      const response = await ApiClient.put(
        "/client/services/change-status/" + id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getsuppliers(id) {
    try {
      const response = await ApiClient.get(`/client/supplies/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }
  static async getsupply(id) {
    try {
      const response = await ApiClient.get(`/client/supplies/get/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createsupply(body) {
    try {
      const response = await ApiClient.post(
        "/client/supplies/create-supply",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updatesupply(id, body) {
    try {
      const response = await ApiClient.patch(
        `/client/supplies/update-supply/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async statussupply(id) {
    try {
      const response = await ApiClient.put(
        "/client/supplies/confirm-supply/" + id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getSalesReport(id) {
    try {
      const response = await ApiClient.get("/client/reports/" + id);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getSaleReport(id) {
    try {
      const response = await ApiClient.get("/client/orders/get/" + id);
      return response;
    } catch (err) {
      return err;
    }
  }
}
