import { ApiClient } from "../Util/ApiClient";

export class ClientReportService {
  static async getBusinessReport(business_id) {
    try {
      const response = await ApiClient.get(
        `/client/reporting/get/all/index/client/all/reports/0/${business_id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getClientReport(client_id) {
    try {
      const response = await ApiClient.get(
        `/client/reporting/get/all/index/client/all/reports/${client_id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getReport() {
    try {
      const response = await ApiClient.get(
        `/client/reporting/get/all/index/client/all/reports`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async compareBusinesses(business_id_1, business_id_2) {
    try {
      const response = await ApiClient.get(
        `/client/reporting/compare-business/${business_id_1}/${business_id_2}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }
}
