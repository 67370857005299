import * as React from "react";
import styled from "styled-components";
import { Check, Info, X } from "react-feather";
// import { Toast } from './Toast'

export const Styles = styled.div`
  width: 300px;
  padding: 20px;
  font-size: 80%;
  background: #ffffffdd;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fffff;
  box-shadow: 0px 18px 32px 0px rgba(76, 147, 255, 0.38);

  > svg {
    flex-shrink: 0;
  }

  * + * {
    margin-left: 10px;
  }

  &.success {
    background: #569d58;
    color: white;
  }

  &.error {
    background: #d9350f;
    color: white;
  }
`;

export const AlertTemplate = ({ options, message }) => {
  return (
    <Styles className={options.type} style={{ marginBottom: 20 }}>
      {options.type === "success" ? (
        <Check />
      ) : options.type === "error" ? (
        <X />
      ) : (
        <Info />
      )}
      <span>{message}</span>
    </Styles>
  );
};
