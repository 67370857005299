import {ApiClient} from '../Util/ApiClient'


export class ConfigCustomerService{

    static async exportFile(id){
        try{
            const response = await fetch(`https://devnrsapi.needletech.ng/download-logs/${id}`,{
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json',
              'Accept':'application/json'
            }})
           return response.json()
        }catch(err){
            return err
        }
       
    }

    static async getbusinessreport(id){
        try{
            const response = await ApiClient.get(`/client/reports/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async gettaxes(id){
        try{
            const response = await ApiClient.get(`/client/taxes/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getdefaulttax(id){
        try{
            const response = await ApiClient.get(`/client/taxes/get/default/tax/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getlogs(id){
        try{
            const response = await ApiClient.get(`/dashboard/activity-logs/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getreturnPolicies(id){
        try{
            const response = await ApiClient.get(`/client/return-policies/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getaccounts(id){
        try{
            const response = await ApiClient.get(`/client/accounts/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getproductcategories(id){
        try{
            const response = await ApiClient.get(`/client/product-categories/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getdiscounts(id){
        try{
            const response = await ApiClient.get(`/client/discounts/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }


    static async gettax(id){
        try{
            const response = await ApiClient.get(`/client/taxes/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getreturnPolicy(id){
        try{
            const response = await ApiClient.get(`/client/return-policies/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getaccount(id){
        try{
            const response = await ApiClient.get(`/client/accounts/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getproductcategory(id){
        try{
            const response = await ApiClient.get(`/client/product-categories/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }


    static async getdiscount(id){
        try{
            const response = await ApiClient.get(`/client/discounts/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async creattaxes(body) {
        try{
          const response = await ApiClient.post('/client/taxes/create-tax', body )
         return response
      }catch(err){
          return err
      }
      }

      static async updatetaxes(id,body) {
        try{
          const response = await ApiClient.patch(`/client/taxes/update-tax/${id}`, body )
         return response
      }catch(err){
          return err
      }
      }

      static async createreturnPolicy(body) {
        try{
          const response = await ApiClient.post('/client/return-policies/create-return-policy', body )
         return response
      }catch(err){
          return err
      }
      }

      static async updatereturnPolicy(id,body) {
        try{
          const response = await ApiClient.patch(`/client/return-policies/update-return-policy/${id}`, body )
         return response
      }catch(err){
          return err
      }
      }


      static async createaccounts(body) {
        try{
          const response = await ApiClient.post('/client/accounts/create-account', body )
         return response
      }catch(err){
          return err
      }
      }

      static async updateaccount(id,body) {
        try{
          const response = await ApiClient.patch(`/client/accounts/update-account/${id}`, body )
         return response
      }catch(err){
          return err
      }
      }


      static async createproductcategory(body) {
        try{
          const response = await ApiClient.post('/client/product-categories/create-category', body )
         return response
      }catch(err){
          return err
      }
      }

      static async updateproductcategory(id,body) {
        try{
          const response = await ApiClient.patch(`/client/product-categories/update-category/${id}`, body )
         return response
      }catch(err){
          return err
      }
      }

      static async creatediscount(body){
        try{
          const response = await ApiClient.post('/client/discounts/create-discount', body )
         return response
      }catch(err){
          return err
      }
      }

      static async updatediscount(id,body) {
        try{
          const response = await ApiClient.patch(`/client/discounts/update-discount/${id}`, body )
         return response
      }catch(err){
          return err
      }
      }


      static async changestatusaccount(id) {
        try{
          const response = await ApiClient.put(`/client/accounts/change-account-status/${id}`)
         return response
      }catch(err){
          return err
      }
      }

      static async changestatuspolicy(id) {
        try{
          const response = await ApiClient.put(`/client/return-policies/change-return-policy-status/${id}`)
         return response
      }catch(err){
          return err
      }
      }

      static async activatediscount(id) {
        try{
          const response = await ApiClient.put(`/client/discounts/activate/${id}` )
         return response
      }catch(err){
          return err
      }
      }

      static async deactivatediscount(id) {
        try{
          const response = await ApiClient.put(`/client/discounts/deactivate/${id}` )
         return response
      }catch(err){
          return err
      }
      }

      static async deletetax(id){
        try{
            const response = await ApiClient.delete(`/client/taxes/delete-tax/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async deleteaccount(id){
        try{
            const response = await ApiClient.delete(`/client/accounts/delete-account/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async deletepolicy(id){
        try{
            const response = await ApiClient.delete(`/client/return-policies/delete-return-policy/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async deleteproductcategories(id){
        try{
            const response = await ApiClient.delete(`/client/product-categories/delete-category/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }
}
