import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../../Component/Loader";
import { ClientInventoryService } from "../../../../../Services/clientInventoryService";

const Styles = styled.div`
  .ml-5 {
    margin-left: 6rem !important;
  }
`;

export const Create_service = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [locations, setLocation] = React.useState(null);

  const getLocations = async () => {
    updateLoader(true, "Loading");
    const getlocation = await ClientInventoryService.getlocations(
      match.params.id
    );
    setLocation(getlocation.data.data);
    updateLoader(false);
  };

  const digitsOnly = (value) => /^\d+$/.test(value);

  React.useEffect(() => {
    getLocations();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          <Formik
            initialValues={{
              service_name: "",
              cost_of_service: "",
              description: "",
              inventory_location_id: "",
              part_payment: false,
              default: false,
            }}
            validationSchema={Yup.object({
              service_name: Yup.string().required(
                "service rendered is required"
              ),

              // cost_of_service: Yup.number()
              //   .required("cost of service is required")
              //   .min(1, "please select from the options"),

              cost_of_service: Yup.string().test(
                "Digits only",
                "The field should have digits only",
                digitsOnly
              ),

              description: Yup.string().required(
                "description of service is required"
              ),

              inventory_location_id: Yup.number()
                .min(1, "please select from the options")
                .required("inventory location is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (values.cost_of_service <= 0) {
                setSubmitting(false);
                return alert.error(
                  "You can't create service with a price less than 1"
                );
              }
              values.business_id = match.params.id;
              const response = await ClientInventoryService.createservice(
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push(`/inventory/${match.params.id}`);
              } else {
                alert.error(response.response.data.message);
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="">
                  <h5 className="mb-5 ml-5">
                    {" "}
                    <strong>Create New Service</strong>{" "}
                  </h5>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Service rendered <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter service rendered"
                      name="service_name"
                      value={values.service_name}
                      onChange={handleChange}
                    />
                    {touched.service_name && errors.service_name ? (
                      <div className="text-danger">{errors.service_name}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Cost of service <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter cost of service"
                      name="cost_of_service"
                      value={values.cost_of_service}
                      onChange={handleChange}
                    />
                    {touched.cost_of_service && errors.cost_of_service ? (
                      <div className="text-danger">
                        {errors.cost_of_service}
                      </div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={10}>
                    <Form.Label>
                      Description of service{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter description of service"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                    {touched.description && errors.description ? (
                      <div className="text-danger">{errors.description}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center mt-5">
                  <Form.Group as={Col} md={5}>
                    <div className="mb-3">
                      <Form.Check
                        inline
                        label="Part payment"
                        type="checkbox"
                        id="1"
                        name="part_payment"
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      {" "}
                      Inventory location <span className="text-danger">
                        *
                      </span>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="inventory_location_id"
                      value={values.inventory_location_id}
                      onChange={handleChange}
                    >
                      <option value="0">Choose...</option>
                      {locations.map((location, i) => (
                        <option value={location.id} key={i}>
                          {location.inventory_location}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.inventory_location_id &&
                    errors.inventory_location_id ? (
                      <div className="text-danger">
                        {errors.inventory_location_id}
                      </div>
                    ) : locations.length < 1 ? (
                      <div className="text-danger">
                        you have to create inventory location first
                        {
                          <Link
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            to={`/create-location/${match.params.id}`}
                          >
                            {" "}
                            here
                          </Link>
                        }
                      </div>
                    ) : null}
                  </Form.Group>

                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/inventory/${match.params.id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
