import React, { useContext } from "react";
import { Col, Container, Button, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { ClientInventoryService } from "../../../../../Services/clientInventoryService";
import { phoneRegExp } from "../../../../../Util/Constants/constant";

const Styles = styled.div``;

export const Create_location = ({ match, location }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();

  // console.log("HISTORY", history);
  // console.log("LOCATION", location.pathname);

  // const handleBack = () => {
  //   history.goBack();
  // };

  // const url = history.goBack();

  // console.log("URL", url);

  return (
    <Styles>
      <Navbar />

      <Container className="mt-5">
        <Formik
          initialValues={{
            inventory_location: "",
            contact_number: "",
            address: "",
          }}
          validationSchema={Yup.object({
            inventory_location: Yup.string()
              .trim()
              .required("name of location is required"),

            contact_number: Yup.string()
              .required("phone number is a required field")
              .max(14, "phone number must be 14 digits maximum")
              .min(10, "phone number must be 11 digits minimum")
              .matches(phoneRegExp, "phone number is not valid"),

            address: Yup.string().trim().required("address is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.business_id = match.params.id;
            const response = await ClientInventoryService.createlocation(
              values
            );
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              // history.push(`/business-settings/${match.params.id}`);
              await history.goBack();
            } else {
              alert.error(response.response.data.message);
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row className="justify-content-md-center">
                <h5 className=" mb-3">
                  {" "}
                  <strong>Create New Inventory Location</strong>{" "}
                </h5>
              </Form.Row>

              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>   
                    Name of location <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name of location"
                    name="inventory_location"
                    value={values.inventory_location}
                    onChange={handleChange}
                  />
                  {touched.inventory_location && errors.inventory_location ? (
                    <div className="text-danger">
                      {errors.inventory_location}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Contact number <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <strong>+</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Enter contact number"
                      name="contact_number"
                      value={values.cost_of_service}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {touched.contact_number && errors.contact_number ? (
                    <div className="text-danger">{errors.contact_number}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={10}>
                  <Form.Label>
                    Address <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                  />
                  {touched.address && errors.address ? (
                    <div className="text-danger">{errors.address}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link onClick={history.goBack}>
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </Styles>
  );
};
