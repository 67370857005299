import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { ConfigService } from "../../../Services/systemConfigService";
import { AuthContext } from "../../../Context/AuthContext";
import NumberFormat from "react-number-format";

const Styles = styled.div``;

export const Subscription = () => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Styles>
      <Nav />

      <Container className="mt-5">
        <h5 className="form-h mb-3">
          {" "}
          <strong>Create Subscription Plan</strong>{" "}
        </h5>

        <form>
          <Formik
            initialValues={{
              name: "",
              price: "",
              product_min: "",
              product_max: "",
              plan_type: "",
              description: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().trim().required(),
              price: Yup.number().min(1, "minimum of 1").required(),
              product_min: Yup.number()
                .min(1, "minimum of 1")
                .required("minimum product is a required field"),
              product_max: Yup.number()
                .min(1, "minimum of 1")
                .required("maximum product is a required field")
                .test(
                  "match",
                  "maximum product must be greater than minimum product",
                  function (product_max) {
                    return product_max > this.parent.product_min;
                  }
                ),
              plan_type: Yup.string()
                .min(4, "please select an option")
                .required("subscription plan is a required field"),
              description: Yup.string().trim().required(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const response = await ConfigService.createsubscription(values);
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                // history.push('/settings')
                await history.goBack();
              } else {
                alert.error(response.response.data.message);
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name ? (
                      <div className="text-danger">{errors.name}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Price <span className="text-danger">*</span>
                    </Form.Label>
                    <NumberFormat
                      className="form-control"
                      label="Price"
                      isNumericString={true}
                      thousandSeparator={true}
                      placeholder="Enter Price"
                      value={values.price}
                      onValueChange={(val) =>
                        setFieldValue("price", val.floatValue)
                      }
                    />
                    {touched.price && errors.price ? (
                      <div className="text-danger">{errors.price}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>
                <h5 className="form-h mb-3 mt-4">
                  {" "}
                  <strong>Product Range</strong>{" "}
                </h5>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Minimium <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="product_min"
                      value={values.product_min}
                      onChange={handleChange}
                    />

                    {touched.product_min && errors.product_min ? (
                      <div className="text-danger">{errors.product_min}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Maximium <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="product_max"
                      value={values.product_max}
                      onChange={handleChange}
                    />

                    {touched.product_max && errors.product_max ? (
                      <div className="text-danger">{errors.product_max}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Plan Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="plan_type"
                      value={values.plan_type}
                      onChange={handleChange}
                    >
                      <option value={0}>Choose...</option>
                      <option value="monthly">MONTHLY</option>
                      <option value="annually">ANNUALLY</option>
                    </Form.Control>

                    {touched.plan_type && errors.plan_type ? (
                      <div className="text-danger">{errors.plan_type}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md="5">
                    <Form.Label>
                      Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />

                    {touched.description && errors.description ? (
                      <div className="text-danger">{errors.description}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to="/settings">
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </form>
      </Container>
    </Styles>
  );
};
