export const formattCurrency = (currency) => {
  return (
    currency &&
    Number(currency)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
};

// export const formattCurrency = (currency) => {
//     return currency && Number(currency.toFixed(1)).toLocaleString();
//   };
