import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Container, Button, Card } from "react-bootstrap";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../Util/Constants/constant";
import { Link, useHistory } from "react-router-dom";
import { PageBody } from "../../Component/Loader";
import { ClientService } from "../../Services/clientService";
import { AuthContext } from "../../Context/AuthContext";
import { ConfigService } from "../../Services/systemConfigService";
import { useLoadingMessage } from "../../Component/useLoadingMessage";

const Styles = styled.div`
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  #part1,
  #part2 {
    z-index: 2;
    display: block;
    height: auto;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  #part2 {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  #step2:checked ~ #part2 {
    opacity: 1;
    height: auto;
  }
  #step2:checked ~ #part1 {
    opacity: 0;
    height: 0;
    display: none;
  }
  .btn-pay {
    width: 100%;
  }
`;

export const Register = () => {
  const [navigate, setNavigate] = useState(1);
  const [toggle, settoggle] = useState(null);
  const [err, seterr] = useState(null);
  const [err2, seterr2] = useState(null);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const { alert } = useContext(AuthContext);
  const [subscripitions, setSubscription] = useState([]);
  const history = useHistory();

  const getsubscriptionPlan = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getsubscriptions();
    console.log("SUBS", response.data);
    setSubscription(response.data.data);
    updateLoader(false);
  };

  const handleNavigation = (val) => {
    setNavigate(val);
    if (val === 1) {
      settoggle(null);
    }
    if (val === 2) {
      settoggle(true);
    }
    if (val === 3) {
      settoggle(false);
    }
  };

  useEffect(() => {
    getsubscriptionPlan();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <div className="authContainer">
          <Container>
            <Row>
              <Col xs={12} md={3}></Col>
              <Col xs={12} md={9}>
                <div className="formContainer-2">
                  <span className="title-text ml-3 text-left">
                    Create account
                  </span>

                  <div className="text-right">
                    <Link to="/login">
                      <Button className="btn-light" size="sm">
                        {" "}
                        <i className=" fa fa-chevron-left"></i>Login
                      </Button>
                    </Link>

                    <Link to="/">
                      <Button className="btn-light" size="sm">
                        {" "}
                        <i className=" fa fa-home"></i> Home
                      </Button>
                    </Link>
                  </div>

                  <div className="stepwizard">
                    <div className="stepwizard-row setup-panel">
                      <div className="stepwizard-step">
                        <a
                          href="#1"
                          onClick={() => handleNavigation(1)}
                          type="button"
                          className="btn btn-circle"
                        >
                          {" "}
                          1{" "}
                        </a>
                        <p>Personal Details</p>
                      </div>

                      <div className="stepwizard-step">
                        <a
                          href="#2"
                          onClick={() => handleNavigation(2)}
                          type="button"
                          className="btn btn-circle"
                          disabled="disabled"
                        >
                          {" "}
                          2{" "}
                        </a>
                        <p>Business Details</p>
                      </div>

                      <div className="stepwizard-step">
                        <a
                          href="#3"
                          type="button"
                          onClick={() => handleNavigation(3)}
                          className="btn btn-circle"
                          disabled="disabled"
                        >
                          {" "}
                          3{" "}
                        </a>
                        <p>Payment Details</p>
                      </div>
                    </div>
                  </div>

                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      phonenumber: "",
                      email: "",
                      payment_plan: "",
                      business_name: "",
                      address: "",
                      registration_no: "",
                      no_of_branches: "",
                      no_of_products: "",
                      subscription_plan_id: "",
                      payment_mode: "",
                      amount: "",
                      payment_option: "",
                      confirm_password: "",
                    }}
                    validationSchema={Yup.object({
                      first_name: Yup.string()
                        .trim()
                        .required("first name is a required field"),

                      last_name: Yup.string()
                        .trim()
                        .required("last name is a required field"),
                      password: Yup.string()
                        .max(12, "Must be 12 characters or less")
                        .required("Required"),
                      confirm_password: Yup.string().oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      ),

                      phonenumber: Yup.string()
                        .required("phone number is a required field")
                        .max(11, "phone number must be 11 digits maximum")
                        .min(10, "phone number must be 10 digits minimum")
                        .matches(phoneRegExp, "Phone number is not valid"),

                      email: Yup.string()
                        .email("Invalid email address")
                        .required(),

                      address: Yup.string().trim().required(),

                      registration_no: Yup.string().trim().nullable(),

                      no_of_branches: Yup.string()
                        .trim()
                        .required("branch number is a required field"),

                      no_of_products: Yup.string()
                        .trim()
                        .required("number of product is a required field"),

                      payment_option: Yup.string()
                        .min(3, "please select an option")
                        .required("payment plan is a required field "),
                      payment_plan: Yup.string()
                        .min(3, "please select an option")
                        .nullable("payment mode is a required field"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      subscripitions.map((sub) => {
                        if (
                          values.no_of_products <= sub.product_max &&
                          sub.product_min <= values.no_of_products
                        ) {
                          values.subscription_plan_id = sub.id;
                        }
                      });

                      const data = [];
                      const client = {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        email: values.email,
                        payment_plan: values.payment_plan,
                        phonenumber: values.phonenumber,
                      };

                      const business = {
                        business_name: values.business_name,
                        registration_no: values.registration_no,
                        no_of_branches: values.no_of_branches,
                        no_of_products: values.no_of_products,
                      };

                      const payment = {
                        subscription_plan_id: values.subscription_plan_id,
                        payment_mode: values.payment_mode,
                      };

                      data.push(client, business, payment);

                      const response = await ClientService.registerclient(
                        values
                      );
                      if (response.status === 200) {
                        alert.success(response.data.message);
                        setSubmitting(false);
                        history.push("/login");
                      } else {
                        if (response.response.data.data.email)
                          alert.error(response.response.data.data.email);
                        if (response.response.data.data.phonenumber)
                          alert.error(response.response.data.data.phonenumber);
                      }
                    }}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        {navigate === 1 ? (
                          <div id="part1">
                            <Form.Row>
                              <div role="alert">
                                {/* {err !== null ? (  <p className='text-danger alert alert-danger form-h'>Please fill all required fields</p>):null}  */}
                              </div>
                            </Form.Row>

                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  First name{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  value={values.first_name}
                                  onChange={handleChange}
                                />
                                {/* {touched.first_name && errors.first_name ? ( */}
                                <div className="text-danger">
                                  {err && err.first_name}
                                </div>
                                {/* ): null}                            */}
                              </Form.Group>

                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Last name{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  value={values.last_name}
                                  onChange={handleChange}
                                />
                                {/* {touched.last_name && errors.last_name ? ( */}
                                <div className="text-danger">
                                  {err && err.last_name}
                                </div>
                                {/* ): null} */}
                              </Form.Group>
                            </Form.Row>

                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Phone number{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="phonenumber"
                                  value={values.phonenumber}
                                  onChange={handleChange}
                                />
                                {/* {touched.phonenumber && errors.phonenumber ? ( */}
                                <div className="text-danger">
                                  {err && err.phonenumber}
                                </div>
                                {/* ): null} */}
                              </Form.Group>

                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Email <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                />
                                {/* {touched.email && errors.email ? ( */}
                                <div className="text-danger">
                                  {err && err.email}
                                </div>
                                {/* ): null} */}
                              </Form.Group>
                            </Form.Row>

                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Password{" "}
                                  <span className="text-danger">*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                />
                                {/* {touched.password && errors.password ? ( */}
                                <div className="text-danger">
                                  {err && err.password}
                                </div>
                                {/* ): null} */}
                              </Form.Group>
                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Confirm Password{" "}
                                  <span className="text-danger">*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  name="confirm_password"
                                  value={values.confirm_password}
                                  onChange={handleChange}
                                />
                                {/* {touched.username && errors.username ? ( */}
                                <div className="text-danger">
                                  {err && err.confirm_password}
                                </div>
                                {/* ): null} */}
                              </Form.Group>
                            </Form.Row>

                            <Form.Group>
                              <div className="text-center">
                                <Link to="/">
                                  <Button className="btn-cancel m-3 shadow">
                                    Cancel
                                  </Button>
                                </Link>
                                <Button
                                  className="btn-save m-3 shadow"
                                  onClick={() => {
                                    validateForm().then((data) => {
                                      if (
                                        data.first_name ||
                                        data.last_name ||
                                        data.email ||
                                        data.confirm_password ||
                                        data.password ||
                                        data.phonenumber
                                      ) {
                                        seterr(data);
                                      } else {
                                        setNavigate(2);
                                      }
                                    });
                                  }}
                                >
                                  Continue
                                </Button>
                              </div>
                            </Form.Group>
                          </div>
                        ) : null}

                        {navigate === 2 ? (
                          <div className="part2">
                            <Form.Row>
                              <div role="alert">
                                {/* {err2 !== null ? (  <p className='text-danger alert alert-danger form-h'>Please fill all required fields</p>):null}  */}
                              </div>
                            </Form.Row>

                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Company name{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="business_name"
                                  value={values.business_name}
                                  onChange={handleChange}
                                />
                                {/* {touched.business_name && errors.business_name ? ( */}
                                <div className="text-danger">
                                  {err2 && err2.business_name}
                                </div>
                                {/* ): null}   */}
                              </Form.Group>

                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Company registration number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="registration_no"
                                  value={values.registration_no}
                                  onChange={handleChange}
                                />
                                {/* {touched.registration_no && errors.registration_no ? ( */}
                                <div className="text-danger">
                                  {err2 && err2.registration_no}
                                </div>
                                {/* ): null}   */}
                              </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Number of branches{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="no_of_branches"
                                  value={values.no_of_branches}
                                  onChange={handleChange}
                                />
                                {/* {touched.no_of_branches && errors.no_of_branches  ? ( */}
                                <div className="text-danger">
                                  {err2 && err2.no_of_branches}
                                </div>
                                {/* ): null}   */}
                              </Form.Group>

                              <Form.Group as={Col} md={5}>
                                <Form.Label>
                                  Number products{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  name="no_of_products"
                                  value={values.no_of_products}
                                  onChange={handleChange}
                                >
                                  <option value="0">Choose...</option>
                                  {subscripitions.map((sub, i) => (
                                    <option value={sub.id} key={i}>
                                      {"From " +
                                        sub.product_min +
                                        "-" +
                                        sub.product_max}
                                    </option>
                                  ))}
                                </Form.Control>
                                {/* {touched.no_of_products && errors.no_of_products ? ( */}
                                <div className="text-danger">
                                  {err2 && err2.no_of_products}
                                </div>
                                {/* ): null}   */}
                              </Form.Group>
                            </Form.Row>

                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={10}>
                                <Form.Label>
                                  Address <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows="2"
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                />
                                {/* {touched.address && errors.address ? ( */}
                                <div className="text-danger">
                                  {err && err.address}
                                </div>
                                {/* ): null} */}
                              </Form.Group>
                            </Form.Row>

                            <Form.Row className="justify-content-md-center">
                              <Form.Group as={Col} md={10}>
                                <div className="shadow p-2">
                                  <h6>
                                    Subscription Plan:{" "}
                                    {subscripitions.map((sub) =>
                                      values.no_of_products == sub.id
                                        ? sub.name
                                        : null
                                    )}
                                  </h6>
                                  <h6>
                                    Total:{" "}
                                    {subscripitions.map((sub) =>
                                      values.no_of_products == sub.id
                                        ? values.payment_plan === "yearly"
                                          ? sub.price * 12
                                          : sub.price
                                        : null
                                    )}
                                  </h6>
                                </div>
                              </Form.Group>
                            </Form.Row>
                            <Form.Group>
                              <div className="mt-3 text-center">
                                <Button
                                  className="btn-cancel m-3 shadow"
                                  onClick={() => {
                                    setNavigate(1);
                                  }}
                                >
                                  Previous
                                </Button>

                                <Button
                                  className="btn-save m-3 shadow"
                                  onClick={() => {
                                    validateForm().then((data) => {
                                      if (
                                        data.business_name ||
                                        data.registration_no ||
                                        data.no_of_branches ||
                                        data.no_of_products
                                      ) {
                                        seterr2(data);
                                      } else {
                                        setNavigate(3);
                                      }
                                    });
                                  }}
                                >
                                  Payment
                                </Button>
                              </div>
                            </Form.Group>
                          </div>
                        ) : null}

                        {navigate === 3 ? (
                          <div className="part3">
                            <Form.Row className="justify-content-md-center">
                              <Col md={10}>
                                <Card className="p-5">
                                  <Form.Row className="justify-content-md-center">
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Payment option{" "}
                                        <span className="text-danger">*</span>{" "}
                                        <small className="text-muted">
                                          ( Trial or Pay Now ){" "}
                                        </small>
                                      </Form.Label>
                                      <Form.Control
                                        as="select"
                                        name="payment_option"
                                        value={values.payment_option}
                                        onChange={handleChange}
                                        defaultValue="Choose..."
                                      >
                                        <option value={0}>Choose...</option>
                                        <option value="trial">Trial</option>
                                        {/* <option value="paid">Pay now</option> */}
                                      </Form.Control>
                                      {touched.payment_option &&
                                      errors.payment_option ? (
                                        <div className="text-danger">
                                          {errors.payment_option}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Form.Row>

                                  {values.payment_option === "paid" ? (
                                    <Form.Row className="justify-content-md-center">
                                      <Form.Group as={Col}>
                                        <Form.Label>
                                          Payment plan{" "}
                                          <span className="text-danger">*</span>{" "}
                                          <small className="text-muted">
                                            ( Monthly or Annually )
                                          </small>
                                        </Form.Label>
                                        <Form.Control
                                          as="select"
                                          name="payment_plan"
                                          value={values.payment_plan}
                                          onChange={handleChange}
                                        >
                                          <option value={0}>Choose...</option>
                                          <option value="monthly">
                                            Monthly
                                          </option>
                                          <option value="yearly">Yearly</option>
                                          ))}
                                        </Form.Control>
                                        {touched.payment_plan &&
                                        errors.payment_plan ? (
                                          <div className="text-danger">
                                            {errors.payment_plan}
                                          </div>
                                        ) : null}
                                      </Form.Group>
                                    </Form.Row>
                                  ) : null}
                                  {values.payment_option === "paid" ? (
                                    <>
                                      <Form.Row className="justify-content-md-center">
                                        <Form.Group as={Col}>
                                          <div className="shadow p-2">
                                            <h6>
                                              Subscription Plan:{" "}
                                              {subscripitions.map((sub) =>
                                                values.no_of_products == sub.id
                                                  ? sub.name
                                                  : null
                                              )}
                                            </h6>
                                            <h6>
                                              Total:{" "}
                                              {subscripitions.map((sub) =>
                                                values.no_of_products == sub.id
                                                  ? values.payment_plan ===
                                                    "yearly"
                                                    ? sub.price * 12
                                                    : sub.price
                                                  : null
                                              )}
                                            </h6>
                                          </div>
                                        </Form.Group>
                                      </Form.Row>
                                    </>
                                  ) : null}

                                  <Form.Group>
                                    <div className="mt-4">
                                      {values.payment_option !== "trial" &&
                                      values.payment_plan ? (
                                        <a
                                          className="btn-pay"
                                          disabled={isSubmitting}
                                          href="https://testnrsapi.remsys.com.ng/payment-page/payment-page"
                                        >
                                          <Button
                                            variant="secondary btn-action"
                                            size="lg"
                                            block
                                          >
                                            PAY NOW
                                          </Button>

                                          {isSubmitting ? (
                                            <span
                                              id="login_spinner"
                                              className="fa fa-spin fa-spinner"
                                            ></span>
                                          ) : null}
                                        </a>
                                      ) : (
                                        <Button
                                          type="submit"
                                          variant="secondary btn-action"
                                          size="lg"
                                          block
                                          disabled={isSubmitting}
                                        >
                                          CONTINUE
                                          {isSubmitting ? (
                                            <span
                                              id="login_spinner"
                                              className="fa fa-spin fa-spinner"
                                            ></span>
                                          ) : null}
                                        </Button>
                                      )}
                                      <center>
                                        <Button
                                          className="btn-cancel m-3 shadow"
                                          onClick={() => {
                                            setNavigate(2);
                                          }}
                                        >
                                          Previous
                                        </Button>
                                      </center>
                                    </div>
                                  </Form.Group>
                                </Card>
                              </Col>
                            </Form.Row>
                          </div>
                        ) : null}
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Styles>
    </PageBody>
  );
};
