import { clientType } from "../../actionTypes";

const initialState = {
  clients: [],
  allclients: [],
};

export const Clientreducer = (state = initialState, action) => {
  switch (action.type) {
    case clientType.GET_ALL: {
      return { ...state, clients: action.payload, allclients: action.payload };
    }

    case clientType.GET_ONE: {
      return { ...state, ...action.payload };
    }

    case clientType.GET_ACTIVE: {
      return { ...state, allclients: action.payload };
    }

    case clientType.GET_INACTIVE: {
      return { ...state, allclients: action.payload };
    }

    case clientType.GET_PAID: {
      return { ...state, allclients: action.payload };
    }

    default: {
      return state;
    }
  }
};
