import React from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
// import styled from 'styled-components';
import { Link, useHistory } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
// import { PageBody } from '../../../Component/Loader';
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { ConfigService } from "../../../Services/systemConfigService";
import { BusinessService } from "../../../Services/businessService";
import { AuthContext } from "../../../Context/AuthContext";

export const Create_business = ({ match }) => {
  const history = useHistory();
  const { alert } = React.useContext(AuthContext);
  const [subscripitions, setSubscription] = React.useState([]);
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getsubscriptionPlan = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getsubscriptions();
    console.log("SUBS", response.data);
    setSubscription(response.data.data);
    updateLoader(false);
  };

  React.useEffect(() => {
    getsubscriptionPlan();
  }, []);

  return (
    <React.Fragment>
      <Nav />

      <Container className="mt-5">
        <Formik
          initialValues={{
            business_name: "",
            registration_no: "",
            no_of_branches: "",
            no_of_products: "",
          }}
          validationSchema={Yup.object({
            business_name: Yup.string()
              .trim()
              .required("business name is a required field"),

            registration_no: Yup.string().trim().nullable(),

            no_of_branches: Yup.string()
              .trim()
              .required("branch number is a required field"),

            no_of_products: Yup.string()
              .trim()
              .required("number of product is a required field"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.client_id = match.params.id;
            const response = await BusinessService.createbusiness(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              if (match.params.id !== undefined) {
                history.push(`/clients-business/${match.params.id}`);
              } else {
                history.push("/business");
              }
            } else {
              alert.error("error creating business");
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <h5 className="form-h mb-3">
                {" "}
                <strong>Create New Business</strong>{" "}
              </h5>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Company name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="business_name"
                    value={values.business_name}
                    onChange={handleChange}
                  />
                  {touched.business_name && errors.business_name ? (
                    <div className="text-danger">{errors.business_name}</div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col} md={5}>
                  <Form.Label>Company registration number</Form.Label>
                  <Form.Control
                    type="text"
                    name="registration_no"
                    value={values.registration_no}
                    onChange={handleChange}
                  />
                  {touched.registration_no && errors.registration_no ? (
                    <div className="text-danger">{errors.registration_no}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Number of branches <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="no_of_branches"
                    value={values.no_of_branches}
                    onChange={handleChange}
                  />
                  {touched.no_of_branches && errors.no_of_branches ? (
                    <div className="text-danger">{errors.no_of_branches}</div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col} md={5}>
                  {/* <Form.Label>
                    Number products <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="no_of_products"
                    value={values.no_of_products}
                    onChange={handleChange}
                  />
                  {touched.no_of_products && errors.no_of_products ? (
                    <div className="text-danger">{errors.no_of_products}</div>
                  ) : null} */}
                  <Form.Label>
                    Number products <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="no_of_products"
                    value={values.no_of_products}
                    onChange={handleChange}
                  >
                    <option value="0">Choose...</option>
                    {subscripitions.map((sub, i) => (
                      <option value={sub.id} key={i}>
                        {"From " + sub.product_min + "-" + sub.product_max}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link
                    to={
                      match.params.id !== undefined
                        ? `/clients-business/${match.params.id}`
                        : "/business"
                    }
                  >
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};
