import { businessUserType } from "../../actionTypes";

const initialState = {
  businessUsers: [],
  AllBusinessUsers: [],
  businesUsers: {},
};

export const businessUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case businessUserType.GET_ALL_B_USERS: {
      return {
        ...state,
        businessUsers: action.payload,
        AllBusinessUsers: action.payload,
      };
    }

    case businessUserType.GET_ONE_B_USER: {
      return { ...state, businesUsers: action.payload };
    }

    case businessUserType.GET_ACTIVE_B_USER: {
      return { ...state, AllBusinessUsers: action.payload };
    }

    case businessUserType.GET_IN_ACTIVE_B_USER: {
      return { ...state, AllBusinessUsers: action.payload };
    }
    default: {
      return state;
    }
  }
};
