import React, { useContext } from "react";
import { Col, Container, Button, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../Services/systemConfigService';
// import {UserService} from '../../../../Services/userService'
import { AuthContext } from "../../../../Context/AuthContext";
import { useHistory } from "react-router-dom";
// import { useLoadingMessage } from '../../../../Component/useLoadingMessage';
// import { PageBody } from '../../../../Component/Loader';
import { phoneRegExp } from "../../../../Util/Constants/constant";
import { ClientCustomerService } from "../../../../Services/clientCustomerService";

const Styles = styled.div``;

export const Create_customer = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Styles>
      <Nav />

      <Container className="mt-5">
        <h5 className="form-h mb-3">
          {" "}
          <strong>Create New Customer</strong>{" "}
        </h5>

        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            phonenumber: "",
            email: "",
          }}
          validationSchema={Yup.object({
            first_name: Yup.string()
              .trim()
              .required("first name is a required field"),

            last_name: Yup.string()
              .trim()
              .required("last name is a required field"),

            phonenumber: Yup.string()
              .required("phone number is a required field")
              .max(14, "phone number must be 14 digits maximum")
              .min(10, "phone number must be 11 digits minimum")
              .matches(phoneRegExp, "Phone number is not valid"),

            email: Yup.string().email("Invalid email address").required(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.business_id = match.params.id;
            const response = await ClientCustomerService.createuser(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              history.push(`/customers/${match.params.id}`);
            }

            alert.error(response.response.data.data.email);
            if (response.response.data.data.phonenumber)
              alert.error(response.response.data.data.phonenumber);
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    First name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  {touched.first_name && errors.first_name ? (
                    <div className="text-danger">{errors.first_name}</div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Last name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  {touched.last_name && errors.last_name ? (
                    <div className="text-danger">{errors.last_name}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    phone number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phonenumber"
                    value={values.phonenumber}
                    onChange={handleChange}
                  />
                  {touched.phonenumber && errors.phonenumber ? (
                    <div className="text-danger">{errors.phonenumber}</div>
                  ) : null}
                </Form.Group>
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {touched.email && errors.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <div className="mt-3 text-center">
                  <Link to={`/customers/${match.params.id}`}>
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </Styles>
  );
};
