import React from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Tab,
  Tabs,
  Row,
  Table,
  Dropdown,
  Accordion
} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import { ConfigService } from "../../../Services/systemConfigService";
import { ConfirmTemplate } from "../../../Component/confirm";
import { AuthContext } from "../../../Context/AuthContext";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { PageBody } from "../../../Component/Loader";
import NumberFormat from "react-number-format";
import { permission } from "../../../Util/Constants/constant";
import { removeUnderscores } from "../../../Util/removeUnderscore";

const Styles = styled.div`
  .dropdown-toggle::after {
    display: none !important;
  },
  .cap{ 
    text-transform: capitalize;
   }
`;

export const Settings = () => {
  const [subscriptions, setSub] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("plan");
  const { alert, permissions } = React.useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  
  {(window.localStorage.getItem('sub')===null) && window.localStorage.setItem('sub', JSON.stringify("plan"))}

  const getSubscriptions = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getsubscriptions();
    setSub(response.data.data);
    updateLoader(false);
  };

  const getroles = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigService.getroles();
    setRoles(role.data.data);
    updateLoader(false);
  };

  const onDelete = (id) => {
    const request = async () => {
      updateLoader(true, "Deleting Subscriptions");
      const response = await ConfigService.deletesubscription(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getSubscriptions();
      getroles();
    };

    ConfirmTemplate(
      {
        title: "Deleting Subscription Plan",
        message: "Are You Sure You Want To Delete This Plan",
      },
      request
    );
  };

  const onRoleChangeStatus = (id, status) => {
    const request = async () => {
      updateLoader(true, "Change Role Status");
      let response;
      if (status) {
        response = await ConfigService.deactivaterole(id);
      } else {
        response = await ConfigService.activaterole(id);
      }

      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getSubscriptions();
      getroles();
    };

    ConfirmTemplate(
      {
        title: "Role Status",
        message: "Are You Sure You Want To change The Status",
      },
      request
    );
  };

  React.useEffect(() => {
    getSubscriptions();
    getroles();
    const actTab = window.localStorage.getItem('sub');
    setActiveTab(JSON.parse(actTab));
  }, []);


  React.useEffect(() => {
    window.localStorage.setItem('sub', JSON.stringify(activeTab));
  });

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        <Container className="mt-4">
          <Form inline>
            <Col md={4}></Col>
            <Col md={4}>
              {/* <div className="input-group">
                <input className="form-control py-2 border-right-0 border" type="search" placeholder="search"  />
                <span className="input-group-append">
                    <div className="input-group-text bg-transparent"><i className="fa fa-search"></i></div>
                </span>
            </div>   */}
            </Col>
            <Col md={4}>
              <div className="breadcrum">
                <ul>
                  <Link to="/dashboard">
                    <li>Home</li>
                  </Link>
                  <li>Configuration</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>

        <Container fluid className="mt-5">
          <Tabs defaultActiveKey={activeTab}
            activeTab={activeTab}
            onSelect={(k) => setActiveTab(k)}>

            <Tab eventKey="plan" title="Subscription Plan" >

              {permissions &&
                permissions.includes(permission.config_create) === true ? (
                <Link to="/sub-plan">
                  <Button className="btn-create shadow p-2 m-3">
                    Create Subscription
                  </Button>
                </Link>
              ) : null}
              <Row>
                {subscriptions.map((sub, i) => (
                  <Col md={4} key={i} className="mb-3">
                    <Card>
                      <Card.Body>
                        <Card.Title className="float-left">
                          {" "}
                          <strong>{sub.name.toUpperCase()}</strong>{" "}
                        </Card.Title>
                        {permissions &&
                          permissions.includes(permission.config_edit) ===
                          true ? (
                          <Button
                            variant="lite"
                            size="sm"
                            className="btn text-danger shadow float-right"
                            onClick={() => {
                              onDelete(sub.id);
                            }}
                          >
                            <i className="fa fa-trash text-danger"></i>
                          </Button>
                        ) : null}
                        {permissions &&
                          permissions.includes(permission.config_edit) ===
                          true ? (
                          <Link to={`/edit-sub/${sub.id}`}>
                            <Button
                              variant="lite"
                              size="sm"
                              className="btn  shadow float-right"
                            >
                              <i className="fa fa-pencil text-danger"></i>
                            </Button>
                          </Link>
                        ) : null}
                        <div className="clearfix"></div>

                        <h5 className="text-danger">
                          <NumberFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            value={sub.price}
                            prefix={"₦"}
                          />{" "}
                        </h5>
                        <small>Product range</small>

                        <div className="clearfix mt-3">
                          <small className="float-left">
                            Minimium <br></br>
                            <p>{sub.product_min}</p>
                          </small>

                          <small className="float-right">
                            Maximium <br></br>
                            <p>{sub.product_max}</p>
                          </small>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>


            <Tab eventKey="role" title="Roles" >

              {permissions &&
                permissions.includes(permission.config_create) === true ? (
                <Link to="/role">
                  <Button className="btn-create shadow p-2 m-3">
                    Create New Role
                  </Button>
                </Link>
              ) : null}


              {roles.map((role, i) => (
                <Row>
                  <Col md={12}>
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Title className="float-left" > <h3 className="cap">{role.name}</h3></Card.Title>

                        <Dropdown className="float-right">
                          <Dropdown.Toggle
                            variant="lite"
                            size="sm"
                            className="shadow"
                          >
                            <span>
                              <i
                                className="fa fa-ellipsis-v font-weight-bold"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {permissions &&
                              permissions.includes(permission.config_edit) ===
                              true ? (
                              <>
                                <Link to={`/edit-role/${role.id}`}>
                                  <Dropdown.Item href={`/edit-role/${role.id}`}>
                                    Edit
                                  </Dropdown.Item>
                                </Link>
                                <Dropdown.Item
                                  onClick={() => {
                                    onRoleChangeStatus(role.id, role.status);
                                  }}
                                >
                                  {role.status ? "Deactivate" : "Activate"}
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="clearfix"></div>
                        <Row>
                          <Col>
                            <Accordion className="shadow">
                              <Card>
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey="0"
                                >
                                  Permission
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>
                                    <ul>
                                      {role.permissions &&
                                        role.permissions.map((permission, i) => (
                                          <span key={i}>
                                            {removeUnderscores(permission.model) +
                                              " " +
                                              permission.name}
                                            ,
                                          </span>
                                        ))}
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </Col>
                          <Col>
                            <Accordion className="shadow">
                              <Card>
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey="0"
                                >
                                  Assigned
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <Card.Body>
                                    <ul>
                                      {role.users &&
                                        role.users.map((user, i) => (
                                          <span key={i}>{user.first_name},</span>
                                        ))}
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </Col>
                        </Row>

                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

              ))}

            </Tab>

          </Tabs>
        </Container>
      </Styles>
    </PageBody>
  );
};
