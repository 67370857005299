import { ApiClient } from "../Util/ApiClient";

export class ConfigService {
  static async getpermission() {
    try {
      const response = await ApiClient.get("/config/permissions");
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getroles() {
    try {
      const response = await ApiClient.get("/config/roles");
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getrole(id) {
    try {
      const response = await ApiClient.get(`/config/roles/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createrole(body) {
    try {
      const response = await ApiClient.post("/config/roles/create-role", body);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updaterole(id, body) {
    try {
      const response = await ApiClient.patch(
        `/config/roles/update-role/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getclientroles(id) {
    try {
      const response = await ApiClient.get(`/client/roles/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getclientrole(id) {
    try {
      const response = await ApiClient.get(`/config/roles/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createclientrole(id, body) {
    try {
      const response = await ApiClient.post(
        `/config/roles/create-role/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updateclientrole(id, business_id, body) {
    try {
      const response = await ApiClient.patch(
        `/config/roles/update-role/${id}/${business_id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async activaterole(id) {
    try {
      const response = await ApiClient.put("/config/roles/activate-role/" + id);
      return response;
    } catch (err) {
      return err;
    }
  }

  static async deactivaterole(id) {
    try {
      const response = await ApiClient.put(
        "/config/roles/deactivate-role/" + id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getsubscriptions() {
    return ApiClient.get("/config/subscription-plans");
  }

  static async getsubscription(id) {
    return ApiClient.get(`/config/subscription-plans/${id}`);
  }

  static async activatesubscription(id) {
    try {
      const response = await ApiClient.post(
        "/config/subscription-plans/activate",
        id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async deactivatesubscription(id) {
    try {
      const response = await ApiClient.post(
        "/config/subscription-plans/deactivate",
        id
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async createsubscription(body) {
    try {
      const response = await ApiClient.post(
        "/config/subscription-plans/create-subscription-plan",
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async updatesubscription(id, body) {
    try {
      const response = await ApiClient.patch(
        `/config/subscription-plans/update-subscription-plan/${id}`,
        body
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async deletesubscription(id) {
    try {
      const response = await ApiClient.delete(
        `/config/subscription-plans/delete-subscription-plan/${id}`
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  static async getactivitylogs() {
    return ApiClient.get("/dashboard/activity-logs");
  }

  static async getnotificationss() {
    return ApiClient.get("/dashboard/notifications");
  }

  static async getSeennotificationss() {
    return ApiClient.get("/dashboard/notifications/get/seen");
  }
  static async getLatestnotificationss() {
    return ApiClient.get("/dashboard/notifications/latest-five");
  }

  static async getRecentnotificationss() {
    return ApiClient.get("/dashboard/notifications");
  }

  static async updatenotificationss(id) {
    return ApiClient.put(`/dashboard/notifications/change-status/${id}`);
  }
  static async deletenotificationss(id) {
    return ApiClient.delete(
      `/dashboard/notifications/delete-notification/${id}`
    );
  }
}
