import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { PageBody } from "../../../../../Component/Loader";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { ClientInventoryService } from "../../../../../Services/clientInventoryService";
import Select from "react-select";
const Styles = styled.div`
  .ml-5 {
    margin-left: 6rem !important;
  }
`;

export const Create_discount = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [products, setProduct] = React.useState([]);
  const [categories, setCategory] = React.useState(null);
  const [cat, setCat] = React.useState("");
  // const [search,setSearch] = React.useState('')

  const getProductCategory = async () => {
    updateLoader(true, "Loading");
    const getcategory = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategory(getcategory.data.data);
    updateLoader(false);
  };
  const getProduct = async (category_id) => {
    // updateLoader(true, "Loading");
    const getproducts = await ClientInventoryService.getproductsByCategory(
      category_id
    );
    const options = getproducts.data.data.map((d) => ({
      value: d.id,
      label: d.product_name,
    }));
    setProduct(options);
    setCat(category_id);
    // updateLoader(false);
  };

  React.useEffect(() => {
    getProductCategory();
  }, []);
  //   React.useEffect(() => {}, [products]);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          <Formik
            initialValues={{
              name: "",
              amount: "",
              products: [],
              products_category: cat,
              default: "false",
              percentage: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().trim().required("discount name is required"),

              amount: Yup.number().nullable(),
              percentage: Yup.number().nullable(),
              // .required("percentage is required"),

              products: Yup.string().trim().required("products is required"),

              products_category: Yup.string()
                .trim()
                .required("products category is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              values.business_id = match.params.id;

              if (values.default === "true") {
                values.percentage = "";
              } else {
                values.amount = "";
              }
              const response = await ConfigCustomerService.creatediscount(
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push(`/inventory/${match.params.id}`);
              } else {
                alert.error(response.response.data.message);
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="">
                  <h5 className="mb-5 ml-5">
                    {" "}
                    <strong>Create Discount</strong>{" "}
                  </h5>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Check
                      type="radio"
                      label="Amount"
                      name="default"
                      id="Radios1"
                      onChange={handleChange}
                      // checked={values.default ? true:false}
                      value={true}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Check
                      type="radio"
                      label="Percentage"
                      name="default"
                      id="Radios2"
                      onChange={handleChange}
                      value={false}
                      defaultChecked
                      // checked={!values.default ? true:false}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Discount name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter discount name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name ? (
                      <div className="text-danger">{errors.name}</div>
                    ) : null}
                  </Form.Group>

                  {values.default === "true" ? (
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Discount amount <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter discount amount"
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                      />
                      {touched.amount && errors.amount ? (
                        <div className="text-danger">{errors.amount}</div>
                      ) : null}
                    </Form.Group>
                  ) : (
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Discount Percentage{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter discount Percentage"
                        name="percentage"
                        value={values.percentage}
                        onChange={handleChange}
                      />
                      {touched.percentage && errors.percentage ? (
                        <div className="text-danger">{errors.percentage}</div>
                      ) : null}
                    </Form.Group>
                  )}
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Product Category<span className="text-danger">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="products_category"
                      value={values.products_category}
                      onChange={handleChange}
                      onClick={() => {
                        if (values.products_category >= 1)
                          getProduct(values.products_category);
                        setProduct([]);
                      }}
                    >
                      <option value="0">Choose...</option>
                      {categories.map((category, i) => (
                        <option value={category.id} key={i}>
                          {category.category_name}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.products_category && errors.products_category ? (
                      <div className="text-danger">
                        {errors.products_category}
                      </div>
                    ) : categories.length < 1 ? (
                      <div className="text-danger">
                        you have to create product category first
                        {
                          <Link
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            to={`/create-category/${match.params.id}`}
                          >
                            {" "}
                            here
                          </Link>
                        }
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5} xs="6">
                    <Form.Label>
                      Products<span className="text-danger">*</span>{" "}
                    </Form.Label>
                    {/* <Form.Control> */}

                    <Select
                      isMulti
                      className="form-control"
                      value={values.products}
                      options={products}
                      onChange={(date) => setFieldValue("products", date)}
                    />

                    {/* </Form.Control> */}
                    {touched.products && errors.products ? (
                      <div className="text-danger">{errors.products}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center"></Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/inventory/${match.params.id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
