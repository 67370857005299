import React, { useState, useEffect } from "react";
import { Col, Row, Container, Card, Jumbotron as Jumbo } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Nav from "../../../Component/Layouts/Nav";
import Inventory from "../../../Assets/Img/icons/inventory.png";
import Sales from "../../../Assets/Img/icons/sales.png";
import Report from "../../../Assets/Img/icons/reports.png";
import Users from "../../../Assets/Img/icons/users.png";
import Customer from "../../../Assets/Img/icons/clients.png";
import Task from "../../../Assets/Img/icons/task.png";
import Config from "../../../Assets/Img/icons/config.png";
// import { AuthContext } from '../../../Context/AuthContext';
import { BusinessService } from "../../../Services/businessService";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { businessThunk } from "../../../redux/action/business/business.thunk";
import { useDispatch, useSelector } from "react-redux";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 150px;
  }
`;

export const Manage_business = ({ match }) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [business, setBusiness] = useState([]);
  const { businesses, busines, AllBusiness } = useSelector(
    (state) => state.businesses
  );
  const dispatch = useDispatch();

  const getbusinessess = async () => {
    updateLoader(true, "Fetching Business");
    const response = await BusinessService.getbusiness(match.params.id);
    setBusiness(response.data.data);
    updateLoader(false);
  };

  useEffect(() => {
    getbusinessess();
  }, []);


  // const getbusines = React.useCallback(async () => {
  //   updateLoader(true, "Fetching Business");
  //   await dispatch(
  //     businessThunk.getBusiness(match.params.id, businesses, busines)
  //   );
  //   updateLoader(false);
  // }, [dispatch, updateLoader, businesses, match.params.id, busines]);

  // useEffect(() => {
  //   getbusines();
  // }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        <Jumbo fluid>
          <Container className="text-white">
            <h1>{business && business.business_name}</h1>
            <span className="mr-5 mb-3">
              {busines.data && busines.data.registration_no
                ? `RC ${busines.data.registration_no}`
                : null}
            </span>
            {/* <span className="ml-5 pl-5">Next payment date : <em>12/03/2023</em> </span> */}
          </Container>
        </Jumbo>

        <div className="breadcrum float-right">
          <ul>
            <Link to={`/dashboard`}>
              <li>Home</li>
            </Link>
            <Link to={`/clients-business/${business && business.client_id}`}>
              <li>Businesses</li>
            </Link>
          </ul>
        </div>
        <div className="clearfix"></div>

        <Container>
          <Row className="mb-4">
            <Col xs={6} md={3}>
              <Link to={`/inventory/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img
                        className="dash-icon"
                        src={Inventory}
                        alt="users icon"
                      />
                    </span>
                    <span>
                      {" "}
                      <strong>Inventory</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col xs={6} md={3}>
              <Link to={`/sales/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img className="dash-icon" src={Sales} alt="users icon" />
                    </span>
                    <span>
                      {" "}
                      <strong>Sales</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col xs={6} md={3}>
              {/* <Link to="/business-report"> */}
              <Link to={`/business-report/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img
                        className="dash-icon"
                        src={Report}
                        alt="users icon"
                      />
                    </span>
                    <span>
                      {" "}
                      <strong>Report</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col xs={6} md={3}>
              <Link to={`/business-users/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img className="dash-icon" src={Users} alt="users icon" />
                    </span>
                    <span>
                      {" "}
                      <strong>Users</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={3}>
              <Link to={`/customers/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img
                        className="dash-icon"
                        src={Customer}
                        alt="users icon"
                      />
                    </span>
                    <span>
                      {" "}
                      <strong>Customers</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col xs={6} md={3}>
              <Link to={`/business-tasks/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img className="dash-icon" src={Task} alt="users icon" />
                    </span>
                    <span>
                      {" "}
                      <strong>Task</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col xs={6} md={3}>
              <Link to={`/business-settings/${match.params.id}`}>
                <Card border="light">
                  <Card.Body>
                    <span>
                      <img
                        className="dash-icon"
                        src={Config}
                        alt="users icon"
                      />
                    </span>
                    <span>
                      {" "}
                      <strong>Configuration</strong>{" "}
                    </span>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </Container>
      </Styles>
    </PageBody>
  );
};
