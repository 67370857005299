import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfisgService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../../Component/Loader";
import { ClientInventoryService } from "../../../../../Services/clientInventoryService";

const Styles = styled.div`
.ml-5{
  margin-left: 6rem!important;
}
`;

export const Edit_service = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [services, setServices] = React.useState(null);
  const [locations, setLocation] = React.useState([]);

  const getLocations = async () => {
    updateLoader(true, "Loading");
    const getlocation = await ClientInventoryService.getlocations(
      match.params.business_id
    );
    setLocation(getlocation.data.data);
    updateLoader(false);
  };

  const getService = async () => {
    updateLoader(true, "Fetching Service");
    const response = await ClientInventoryService.getservice(match.params.id);
    setServices(response.data.data);
    updateLoader(false);
  };
  React.useEffect(() => {
    getLocations();
    getService();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          {services !== null ? (
            <Formik
              initialValues={{
                service_name: services.service_name,
                cost_of_service: services.cost_of_service,
                description: services.description,
                inventory_location_id: services.inventory_location_id,
                part_payment: services.part_payment ? true : false,
              }}
              validationSchema={Yup.object({
                service_name: Yup.string().required(
                  "service rendered is required"
                ),

                cost_of_service: Yup.string().required(
                  "cost of service is required"
                ),

                description: Yup.string().required(
                  "description of service is required"
                ),

                inventory_location_id: Yup.number()
                  .min(1, "please select from the options")
                  .required("inventory location is required"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                values.business_id = match.params.business_id;
                const response = await ClientInventoryService.updateservice(
                  match.params.id,
                  values
                );
                if (response.status === 200) {
                  alert.success(response.data.message);
                  setSubmitting(false);
                  history.push(`/inventory/${match.params.business_id}`);
                } else {
                  alert.error(response.response.data.message);
                }
              }}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row className="">
                    <h5 className="mb-5 ml-5">
                      {" "}
                      <strong>Edit Service</strong>{" "}
                    </h5>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Service rendered <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter service rendered"
                        name="service_name"
                        value={values.service_name}
                        onChange={handleChange}
                      />
                      {touched.service_name && errors.service_name ? (
                        <div className="text-danger">{errors.service_name}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group as={Col} md={5}>
                      <Form.Label>
                        Cost of service <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter cost of service"
                        name="cost_of_service"
                        value={values.cost_of_service}
                        onChange={handleChange}
                      />
                      {touched.cost_of_service && errors.cost_of_service ? (
                        <div className="text-danger">
                          {errors.cost_of_service}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={10}>
                      <Form.Label>
                        Description of service{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description of service"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      {touched.description && errors.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center mt-5">
                    <Form.Group as={Col} md={5}>
                      <div className="mb-3">
                        <Form.Check
                          inline
                          label="Part Payment"
                          type="checkbox"
                          id="1"
                          name="part_payment"
                          value={values.part_payment}
                          checked={values.part_payment}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={10}>
                      <Form.Label>
                        {" "}
                        Inventory location{" "}
                        <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="inventory_location_id"
                        value={values.inventory_location_id}
                        onChange={handleChange}
                      >
                        <option value="0">Choose...</option>
                        {locations.map((location, i) => (
                          <option value={location.id} key={i}>
                            {location.inventory_location}
                          </option>
                        ))}
                      </Form.Control>
                      {touched.inventory_location_id &&
                      errors.inventory_location_id ? (
                        <div className="text-danger">
                          {errors.inventory_location_id}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>

                  <Form.Group>
                    <div className="mt-3 text-center">
                      <Link to={`/inventory/${match.params.business_id}`}>
                        <Button className="btn-cancel m-3 shadow">
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        type="submit"
                        className="btn-save m-3 shadow"
                        disabled={!dirty}
                      >
                        Update{" "}
                        {isSubmitting ? (
                          <span
                            id="login_spinner"
                            className="fa fa-spin fa-spinner"
                          ></span>
                        ) : null}
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          ) : null}
        </Container>
      </Styles>
    </PageBody>
  );
};
