import React from "react";
import { Col, Container, Button, Card, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../../Component/Layouts/Nav";
import User from "../../../../Assets/Img/user-icon.png";
// import { UserService } from '../../../../Services/userService';
import { PageBody } from "../../../../Component/Loader";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
// import {Formik} from 'formik';
import { ClientCustomerService } from "../../../../Services/clientCustomerService";
import { AuthContext } from "../../../../Context/AuthContext";
import {permission_client} from "../../../../Util/Constants/constant"

const Styles = styled.div`
  .card-body p {
    font-size: 10px;
  }
`;

export const Customers = ({ match }) => {
  const [users, setUsers] = React.useState([]);
  // const [active, setActive] = React.useState();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [search, setSearch] = React.useState("");
  const {permissions} = React.useContext(AuthContext)

  const getusers = async () => {
    updateLoader(true, "Fetching Customers");
    const response = await ClientCustomerService.getusers(match.params.id);
    setUsers(response.data.data);
    // setActive(1);
    updateLoader(false);
  };

  // const getactiveusers = async () => {
  //   updateLoader(true, "Fetching Active Customers");
  //   const response = await ClientCustomerService.getactiveusers(
  //     match.params.id
  //   );
  //   setUsers(response.data.data);
  //   setActive(2);
  //   updateLoader(false);
  // };
  // const getinactiveusers = async () => {
  //   updateLoader(true, "Fetching Inactive Customers");
  //   const response = await ClientCustomerService.getinactiveusers(
  //     match.params.id
  //   );
  //   setUsers(response.data.data);
  //   setActive(3);
  //   updateLoader(false);
  // };

  const allUsers = users.filter((user) => {
    return (
      user.first_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      user.last_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  React.useEffect(() => {
    getusers();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        <Container className="mt-4" fluid>
          <Form inline>
            <Col md={2}>
            {permissions &&
                permissions.includes(permission_client.client_customer_create) === true ? (
              <Link to={`/create-customer/${match.params.id}`}>
                <Button className="btn-create shadow p-2 m-3">
                  Create Customer
                </Button>
              </Link>
                ):null}
            </Col>

            <Col md={3}>
              <div className="input-group">
                <input
                  className="form-control py-2 border-right-0 border"
                  type="search"
                  placeholder="search"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="input-group-append">
                  <div className="input-group-text bg-transparent">
                    <i className="fa fa-search"></i>
                  </div>
                </span>
              </div>
            </Col>
            <Col className="" md={4}>
              {/* <Button
                className={
                  active === 1 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  getusers();
                }}
              >
                All
              </Button>
              <Button
                className={
                  active === 2 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  getactiveusers();
                }}
              >
                Active
              </Button>
              <Button
                className={
                  active === 3 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  getinactiveusers();
                }}
              >
                Inactive
              </Button> */}
            </Col>
            <Col md={3}>
              <div className="breadcrum">
                <ul>
                  <Link to="/dashboard">
                    <li>Home</li>
                  </Link>
                  <Link to={`/manage-business/${match.params.id}`}>
                    <li>Business</li>
                  </Link>
                  <li>Customers</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>
        <Container fluid>
          <Row className="mt-5">
            {allUsers < 1 ? (
              <div className="text-center m-auto">
                <h3>No record found</h3>
              </div>
            ) : (
              allUsers.map((user, i) => (
                <Col md={3} key={i} className="mb-5">
                  <Card className="text-center card-user">
                    <center>
                      <img className="user-img" src={User} alt="user icon" />
                    </center>
                    <Card.Body className="mt-4">
                      <Card.Title>
                        <strong>
                          {user !== null
                            ? user.first_name + " " + user.last_name
                            : null}
                        </strong>
                      </Card.Title>

                      <small>{user.email}</small>
                      <p>{user.phonenumber}</p>
                      <em className="text-danger">
                      </em>
                      <p></p>
                      {permissions &&
                permissions.includes(permission_client.client_customer_edit) === true ? (
                      <Link to={`/edit-customer/${user.id}/${match.params.id}`}>
                        <Button className="btn-create-two shadow m-3">
                          <small>Edit</small>
                        </Button>
                      </Link>):null}
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </Styles>
    </PageBody>
  );
};
