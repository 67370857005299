import {ApiClient} from '../Util/ApiClient'


export class PaymentService{

    static async makePayment(body){
        try{
            const response = await ApiClient.post('/payments/payment/initiate-payment',body)
           return response
        }catch(err){
            return err
        }
       
    }

    
    static async getclientpayment(){
        try{
            const response = await ApiClient.get('/payments/get-client-payment')
           return response
        }catch(err){
            return err
        }
       
    }

    static async getclientpaymentId(id){
        try{
            const response = await ApiClient.get(`/payments/get-client-payment/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getincome(){
        try{
            const response = await ApiClient.get(`/payments/get/income/generated`)
           return response
        }catch(err){
            return err
        }
       
    }
   
   
}
