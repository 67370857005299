import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Tab,
  Tabs,
  Row,
  Table,
  Dropdown,
  DropdownButton,
  Accordion,
  Pagination,
} from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import Product from "../../../../Assets/Img/products/shoes.png";
import { PageBody } from "../../../../Component/Loader";
import { ConfirmTemplate } from "../../../../Component/confirm";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import { AuthContext } from "../../../../Context/AuthContext";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { ConfigService } from "../../../../Services/systemConfigService";
import { formattCurrency } from "../../../../Util/formattCurrency";
import * as moment from "moment";
import { permission_client } from "../../../../Util/Constants/constant";

const Styles = styled.div`
  // .dropdown-toggle::after {
  //   display: none !important;
  // }

  .product-item {
    max-height: 209px;
    overflow: hidden;
  }

  .dropdown-list {
    height: 150px;
    overflow-y: scroll;
  }

  .dropdown-toggle {
    background-color: #ffffff;
    color: #000080;
  }
`;

export const Inventory = ({ match }) => {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);

  const [filter, setFilter] = useState([]);
  const [active, setActive] = useState(null);
  const [supplies, setSupplies] = useState([]);
  const [searchproduct, setSearchproduct] = useState("");
  const [searchservices, setSearchservices] = useState("");
  const [searchDiscount, setSearchDiscount] = React.useState("");
  const [searchsupplies, setSearchsupplies] = useState("");

  const { alert, permissions, user_type } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const [taxes, setTaxes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [roles, setRoles] = useState([]);






  const [activeTab, setActiveTab] = useState("Products");


  {(window.localStorage.getItem('inv')===null) && window.localStorage.setItem('inv', JSON.stringify("Products"))}

















  const date = (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const getDiscounts = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getdiscounts(match.params.id);
    setDiscounts(response.data.data);
    updateLoader(false);
  };
  const getProducts = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getproducts(match.params.id);
    setProducts(response.data.data);
    updateLoader(false);
  };
  const getServices = async () => {
    updateLoader(true, "Loading");
    const role = await ClientInventoryService.getservices(match.params.id);
    setServices(role.data.data);
    updateLoader(false);
  };

  const getLocations = async () => {
    updateLoader(true, "Loading");
    const role = await ClientInventoryService.getlocations(match.params.id);
    setLocations(role.data.data);
    if (active === null || false) {
      setFilter(role.data.data);
      setActive(true);
    }
    updateLoader(false);
  };

  const getCategories = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategories(role.data.data);
    setActive(false);
    updateLoader(false);
  };

  const getSupply = async () => {
    updateLoader(true, "Loading");
    const role = await ClientInventoryService.getsuppliers(match.params.id);
    setSupplies(role.data.data);
    updateLoader(false);
  };

  const getProductInVen = async (id) => {
    updateLoader(true, "Loading");
    const location = await ClientInventoryService.getlocation(id);
    setProducts(location.data.data.products);
    updateLoader(false);
  };

  const getServiceInVen = async (id) => {
    updateLoader(true, "Loading");
    const location = await ClientInventoryService.getServicesIven(id);
    setServices(location.data.data);
    updateLoader(false);
  };

  // const getServiceInVen = async (id) => {
  //   console.log(id);
  //   //     updateLoader(true, "Loading");
  //   //     const location = await ClientInventoryService.getlocation(id)
  //   //     setServices(location.data.data.services)

  //   // updateLoader(false);
  // };

  const getProductCat = async (id) => {
    updateLoader(true, "Loading");
    const category = await ClientInventoryService.getproductsByCategory(id);
    setProducts(category.data.data);
    updateLoader(false);
  };

  const onChangeProductStatus = (id) => {
    const request = async () => {
      updateLoader(true, "Changing Product Status");
      const response = await ClientInventoryService.statusproduct(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getProducts();
      getServices();
      getLocations();
      getSupply();
      getDiscounts();
    };

    ConfirmTemplate(
      {
        title: "Change Product Status",
        message: "Are You Sure You Want To change This Product status",
      },
      request
    );
  };

  const onDeleteProductCategory = (id) => {
    const request = async () => {
      updateLoader(true, "Deleting Product Category");
      const response = await ConfigCustomerService.deleteproductcategories(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getTaxes();
      getAccounts();
      getPolicies();
      getProductCategories();
      getLocations();

      // getDiscounts()
      getroles();
      getLogs();
    };

    ConfirmTemplate(
      {
        title: "Deleting Product Category",
        message: "Are You Sure You Want To Delete This Product Category",
      },
      request
    );
  };

  const getTaxes = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.gettaxes(match.params.id);
    setTaxes(response.data.data);
    updateLoader(false);
  };
  const getLogs = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getlogs(match.params.id);
    setLogs(response.data.data);
    updateLoader(false);
  };
  const getAccounts = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigCustomerService.getaccounts(match.params.id);
    setAccounts(role.data.data);
    updateLoader(false);
  };

  const getPolicies = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigCustomerService.getreturnPolicies(match.params.id);
    setPolicies(role.data.data);
    updateLoader(false);
  };

  const getProductCategories = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategories(response.data.data);
    updateLoader(false);
  };

  const getroles = async () => {
    updateLoader(true, "Loading");
    const role = await ConfigService.getclientroles(match.params.id);
    setRoles(role.data.data);
    updateLoader(false);
  };

  const onChangeDiscountStatus = (id, status) => {
    const request = async () => {
      updateLoader(true, "Changing Discount Status");
      let response;
      if (status) {
        response = await ConfigCustomerService.deactivatediscount(id);
      } else {
        response = await ConfigCustomerService.activatediscount(id);
      }

      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getProducts();
      getServices();
      getLocations();
      getSupply();
      getDiscounts();
    };

    ConfirmTemplate(
      {
        title: "Change Discount status",
        message: "Are You Sure You Want To change This Discount status",
      },
      request
    );
  };

  const onChangeServiceStatus = (id) => {
    const request = async () => {
      updateLoader(true, "Changing Service Status");
      const response = await ClientInventoryService.statusservice(id);

      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getProducts();
      getServices();
      getLocations();
      getSupply();
    };

    ConfirmTemplate(
      {
        title: "Change Service status",
        message: "Are You Sure You Want To change This Service status",
      },
      request
    );
  };
  // const onChangeLocationStatus = (id) =>{
  //     const request = async ()=>{
  //         updateLoader(true, "Changing Location Status");
  //        const response =  await  ClientInventoryService.statuslocation(id)
  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             getProducts()
  //             getServices()
  //             getLocations()
  //             getSupply()

  //          }

  //     ConfirmTemplate({title:"Change Location Status",message:"Are You Sure You Want To change This Location status"},request)
  // }

  const onChangeSupplyStatus = (id) => {
    console.log("ID", id);
    const request = async () => {
      updateLoader(true, "Changing Supply Status");
      const response = await ClientInventoryService.statussupply(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error("error changing status");
      }
      getProducts();
      getServices();
      getLocations();
      getSupply();
    };

    ConfirmTemplate(
      {
        title: "Change Supply Status",
        message: "Are You Sure You Want To change This Supply status",
      },
      request
    );
  };

  const filterServices = (savice_name) => {
    console.log(savice_name);
  };

  const allProducts = products.filter((product) => {
    return (
      product.product_name
        .toLowerCase()
        .indexOf(searchproduct.toLowerCase()) !== -1
    );
  });
  const allServices = services.filter((service) => {
    return (
      service.service_name
        .toLowerCase()
        .indexOf(searchservices.toLowerCase()) !== -1
    );
  });

  const allDiscounts = discounts.filter((discount) => {
    return (
      discount.name.toLowerCase().indexOf(searchDiscount.toLowerCase()) !== -1
    );
  });
  const allSupplys = supplies.filter((supply) => {
    return (
      supply.supplier_name
        .toLowerCase()
        .indexOf(searchsupplies.toLowerCase()) !== -1
    );
  });

  useEffect(() => {
    getProducts();
    getServices();
    getLocations();
    getCategories();
    getSupply();
    getDiscounts();
    









    const actTab = window.localStorage.getItem('inv');
    setActiveTab(JSON.parse(actTab));
   
  }, []);

  React.useEffect(() => {
    window.localStorage.setItem('inv', JSON.stringify(activeTab));
  });















  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />
        <Container fluid className="mt-5">
          <Tabs 
          
          
          
          
          
          
          
          
          
          
          defaultActiveKey={activeTab} 
          onSelect={(k) => setActiveTab(k)}
          
          
          
          
          
          
          
          >
            <Tab eventKey="Products" title="Products">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_inventory_create
                  ) === true ? (
                    <Link to={`/create-product/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Product
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchproduct}
                      onChange={(e) => setSearchproduct(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      <DropdownButton
                        title="Filter by category"
                        id="dropdown-item-button"
                      >
                        <div className="dropdown-list">
                          <Dropdown.Item>
                            <p onClick={() => getProducts()}>All</p>
                          </Dropdown.Item>
                          {categories.map((cat, i) => (
                            <Dropdown.Item as="button">
                              <p key={i} onClick={() => getProductCat(cat.id)}>
                                {cat.category_name}
                              </p>{" "}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                    </Col>
                    <Col md={6}>
                      <DropdownButton title="Filter by location">
                        <div className="dropdown-list">
                          {locations.map((location, i) => (
                            <Dropdown.Item as="button">
                              <p
                                key={i}
                                onClick={() => getProductInVen(location.id)}
                              >
                                {location.inventory_location}
                              </p>{" "}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </DropdownButton>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Products</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {allProducts < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allProducts.map((product, i) => (
                    <Col md={4} key={i}>
                      <Card className="product-item mb-3">
                        <Row className="no-gutters">
                          <Col md={6}>
                            <img
                              className="card-img-top h-100 w-100"
                              src={
                                product.image
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    product.image
                                  : Product
                              }
                              alt="product"
                            />
                          </Col>
                          <Col md={6}>
                            <Card.Body>
                              {permissions &&
                              permissions.includes(
                                permission_client.client_inventory_edit
                              ) === true ? (
                                <>
                                  <Dropdown className="float-right">
                                    <Dropdown.Toggle variant="lite" size="sm">
                                      <span>
                                        <i
                                          className="fa fa-ellipsis-v font-weight-bold"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Link
                                        to={`/edit-product/${product.id}/${match.params.id}`}
                                      ></Link>

                                      <Dropdown.Item
                                        href={`/edit-product/${product.id}/${match.params.id}`}
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() =>
                                          onChangeProductStatus(product.id)
                                        }
                                      >
                                        {product.status
                                          ? "Deactivate"
                                          : "Activate"}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </>
                              ) : null}
                              <Card.Title>{product.product_name} </Card.Title>
                              <Card.Text className="text-danger">
                                ₦ {formattCurrency(product.selling_price)}
                              </Card.Text>
                              <span>
                                <strong>ID</strong> :{product.product_id}
                              </span>
                              <div className="product-desc pb-2">
                                <small>{product.product_description}</small>
                                <br></br>
                                <small>
                                  {product.quantity !== 0 ? (
                                    <strong>
                                      Available in store :{product.quantity}
                                    </strong>
                                  ) : (
                                    <strong className="text-danger">
                                      Out of stock
                                    </strong>
                                  )}
                                </small>
                                <br></br>
                                <small>
                                  <strong>
                                    {Array.isArray(product.discounts)
                                      ? product.discounts.map((disc) =>
                                          disc.amount === null
                                            ? `Discount:
                                              ${disc.percentage}
                                              %`
                                            : `Discount: ₦${formattCurrency(
                                                disc.amount
                                              )}`
                                        )
                                      : null}
                                  </strong>{" "}
                                </small>
                              </div>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Tab>

            <Tab eventKey="category" title="Product Categories">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_inventory_create
                  ) === true ? (
                    <Link to={`/create-category/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Category
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  {/* <div className="input-group">
                    <input className="form-control py-2 border-right-0 border" type="search" placeholder="search"  />
                    <span className="input-group-append">
                        <div className="input-group-text bg-transparent"><i className="fa fa-search"></i></div>
                    </span>
                </div>   */}
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Product Category</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>CATEGORY NAME</th>

                    {user_type === "admin" ? null : <th>ACTION</th>}
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, i) => (
                    <tr key={i}>
                      <td>
                        <h4>{category.category_name}</h4>
                      </td>

                      <td className="text-center">
                        {permissions &&
                        permissions.includes(
                          permission_client.client_inventory_edit
                        ) === true ? (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="lite"
                              size="sm"
                              className="shadow"
                            >
                              <span>
                                <i
                                  className="fa fa-ellipsis-v font-weight-bold"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <>
                                <Link
                                  to={`/edit-category/${category.id}/${match.params.id}`}
                                ></Link>

                                <Dropdown.Item
                                  href={`/edit-category/${category.id}/${match.params.id}`}
                                >
                                  Edit
                                </Dropdown.Item>
                              </>
                              {permissions &&
                              permissions.includes(
                                permission_client.client_inventory_delete
                              ) === true ? (
                                <Dropdown.Item
                                  onClick={() =>
                                    onDeleteProductCategory(category.id)
                                  }
                                >
                                  Delete
                                </Dropdown.Item>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination>
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Next />
              </Pagination>
            </Tab>

            <Tab eventKey="Services" title="Services">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_inventory_create
                  ) === true ? (
                    <Link to={`/create-service/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Service
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchservices}
                      onChange={(e) => setSearchservices(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  <Row>
                    <Col md={6}>
                      <DropdownButton title="Filter by location">
                        <Dropdown.Item as="button">
                          <p onClick={getServices}>All</p>{" "}
                        </Dropdown.Item>
                        {locations.map((location, i) => (
                          <Dropdown.Item as="button">
                            <p
                              key={i}
                              onClick={() => getServiceInVen(location.id)}
                            >
                              {location.inventory_location}
                            </p>{" "}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Services</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {allServices < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allServices.map((service, i) => (
                    <Col md={4} key={i}>
                      <Card>
                        <Card.Body>
                          <Card.Title className="float-left">
                            {" "}
                            <strong>{service.service_name}</strong>{" "}
                          </Card.Title>
                          {permissions &&
                          permissions.includes(
                            permission_client.client_inventory_edit
                          ) === true ? (
                            <Dropdown className="float-right">
                              <Dropdown.Toggle
                                variant="lite"
                                size="sm"
                                className="shadow"
                              >
                                <span>
                                  <i
                                    className="fa fa-ellipsis-v font-weight-bold"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <>
                                  <Link
                                    to={`/edit-service/${service.id}/${match.params.id}`}
                                  ></Link>
                                  <Dropdown.Item
                                    href={`/edit-service/${service.id}/${match.params.id}`}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                </>
                                <Dropdown.Item
                                  onClick={() =>
                                    onChangeServiceStatus(service.id)
                                  }
                                >
                                  {service.status ? "Deactivate" : "Activate"}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : null}

                          <div className="clearfix"></div>

                          <h5 className="text-danger">
                            ₦ {formattCurrency(service.cost_of_service)}{" "}
                          </h5>
                          <div className="text-truncate">
                            <small>{service.description} </small>
                          </div>
                          <div>
                            <div className="clearfix mt-3">
                              {service.part_payment > 0 ? (
                                <p className="text-success">Part payment</p>
                              ) : (
                                <p className="text-muted">No Part payment</p>
                              )}
                            </div>
                            {/* <div className="clearfix mt-3">
                              <Link
                                to={`/service/${match.params.id}/${service.id}`}
                              >
                                <Button className="btn bg-blue">pay</Button>
                              </Link>{" "}
                            </div> */}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Tab>

            <Tab eventKey="Supplies" title="Supplies">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_inventory_create
                  ) === true ? (
                    <Link to={`/create-supply/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Supply
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchsupplies}
                      onChange={(e) => setSearchsupplies(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  <DropdownButton
                    title="Filter by category"
                    id="dropdown-item-button"
                  >
                    <div className="dropdown-list">
                      <Dropdown.Item>
                        <p onClick={() => getSupply()}>All</p>
                      </Dropdown.Item>
                      {categories.map((cat, i) => (
                        <Dropdown.Item as="button">
                          <p key={i} onClick={() => getProductCat(cat.id)}>
                            {cat.category_name}
                          </p>{" "}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </DropdownButton>
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Supplies</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Table striped bordered variant="light" re>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>DATE</th>
                    <th>STATUS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allSupplys < 1 ? (
                    <div className="text-center m-auto">
                      <h1>No record found</h1>
                    </div>
                  ) : (
                    allSupplys.map((supply, i) => (
                      <tr>
                        <td>{supply.supplier_name}</td>
                        <td>{date(supply.date_of_supply)}</td>
                        <td>{supply.status ? "Confirmed" : "Pending"}</td>
                        <td className="text-center">
                          {permissions &&
                          permissions.includes(
                            permission_client.client_inventory_edit
                          ) === true ? (
                            <>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="lite"
                                  size="sm"
                                  className="shadow"
                                >
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v font-weight-bold"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Link
                                    to={`/edit-supply/${supply.id}/${match.params.id}`}
                                  >
                                    {" "}
                                  </Link>
                                  <Dropdown.Item>Edit</Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      onChangeSupplyStatus(supply.id)
                                    }
                                  >
                                    {supply.status ? "Pending" : "Confirm"}
                                  </Dropdown.Item>

                                  {/* <Dropdown.Item>Comfirmss</Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Tab>

            <Tab eventKey="discounts" title="Discounts">
              <Form inline className="mb-4">
                <Col md={2}>
                  {permissions &&
                  permissions.includes(
                    permission_client.client_inventory_create
                  ) === true ? (
                    <Link to={`/create-discount/${match.params.id}`}>
                      <Button className="btn-create shadow">
                        Add New Discount
                      </Button>
                    </Link>
                  ) : null}
                </Col>
                {/* <Col md={3}>
            
            <div className="input-group">
                    <input className="form-control py-2 border-right-0 border" type="search" placeholder="search"  />
                    <span className="input-group-append">
                        <div className="input-group-text bg-transparent"><i className="fa fa-search"></i></div>
                    </span>
                </div>  
            </Col> */}
                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={searchDiscount}
                      onChange={(e) => setSearchDiscount(e.target.value)}
                    />{" "}
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                {/* <Col md={3}></Col> */}
                <Col md={3}></Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to={`/manage-business/${match.params.id}`}>
                        <li>Business</li>
                      </Link>
                      <li>Discount</li>
                    </ul>
                  </div>
                </Col>
              </Form>

              <Row>
                {allDiscounts < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allDiscounts.map((discount, i) => (
                    <Col md={6}>
                      <Card>
                        <Card.Body>
                          <Card.Title className="float-left">
                            {" "}
                            <small>{discount.name}</small>
                          </Card.Title>
                          {permissions &&
                          permissions.includes(
                            permission_client.client_inventory_edit
                          ) === true ? (
                            <>
                              <Dropdown className="float-right">
                                <Dropdown.Toggle
                                  variant="lite"
                                  size="sm"
                                  className="shadow"
                                >
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v font-weight-bold"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Link
                                    to={`/edit-discount/${discount.id}/${match.params.id}`}
                                  ></Link>
                                  <Dropdown.Item
                                    href={`/edit-discount/${discount.id}/${match.params.id}`}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      onChangeDiscountStatus(
                                        discount.id,
                                        discount.status
                                      )
                                    }
                                  >
                                    {discount.status
                                      ? "Deactivate"
                                      : "Activate"}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          ) : null}
                          <div className="clearfix"></div>

                          <h5 className="text-danger">
                            {discount.amount !== null
                              ? "₦" + formattCurrency(discount.amount)
                              : discount.percentage + "%"}{" "}
                          </h5>
                          <Row>
                            <Col>
                              <Accordion className="shadow">
                                <Card>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey="0"
                                  >
                                    Products
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                      <ul>
                                        {discount.products.map((product, i) => (
                                          <li>{product.product_name}</li>
                                        ))}
                                      </ul>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Styles>
    </PageBody>
  );
};
