import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Row,
  Container,
  Navbar,
  Form,
  Badge,
  Dropdown,
  Jumbotron,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../Assets/Img/logo.png";
import User from "../../Assets/Img/user-icon.png";
import Users from "../../Assets/Img/icons/users.png";
import Clients from "../../Assets/Img/icons/clients.png";
import Business from "../../Assets/Img/icons/business.png";
import Task from "../../Assets/Img/icons/task.png";
import Reports from "../../Assets/Img/icons/reports.png";
import Config from "../../Assets/Img/icons/config.png";
import { AuthContext } from "../../Context/AuthContext";
import { ConfigService } from "../../Services/systemConfigService";
import { Bar, Pie, Line } from "react-chartjs-2";
import * as moment from "moment";
import { roles, permission } from "../../Util/Constants/constant";
import { PageBody } from "../../Component/Loader";
import { useLoadingMessage } from "../../Component/useLoadingMessage";
import { UserService } from "../../Services/userService";
import { ClientService } from "../../Services/clientService";
// import { ClientCustomerService } from '../../Services/clientCustomerService';
import { PaymentService } from "../../Services/paymentService";
const Styles = styled.div`
  @media (min-width: 1025px) {
    .dropdown-menu {
      max-width: 350px;
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .card-body {
    overflow: auto;
  }
`;

export const Dashboard = ({ match }) => {
  const { logout, user, user_type, permissions } = useContext(AuthContext);
  const [activitylogs, setActivitylogs] = useState([]);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [activeclients, setactiveClients] = useState([]);
  const [inactiveclients, setinactiveClients] = useState([]);
  const [activeusers, setactiveUsers] = useState([]);
  const [inactiveusers, setinactiveUsers] = useState([]);
  const [income, setIncome] = useState([]);
  const [notifications, setNotifications] = useState([]);

  console.log("user", user);
  console.log("type", user_type);

  const IncomeGen = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Income Generated Per Month",
        data: income,
        fill: true,
        backgroundColor: "#EF705D",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const clientChart = {
    labels: ["Active Clients", "Inactive Clients"],
    datasets: [
      {
        label: "Clients",
        data: [activeclients.length, inactiveclients.length],
        fill: true,
        backgroundColor: ["#F5C00E", "#EF705D", "#4FBCE5", "#1C3FAA"],
      },
    ],
  };

  const userChart = {
    labels: ["Active", "Inactive"],
    datasets: [
      {
        label: "Users",
        data: [activeusers.length, inactiveusers.length],
        fill: false,
        backgroundColor: ["#F5C00E", "#EF705D", "#4FBCE5", "#1C3FAA"],
      },
    ],
  };
  const date = (date) => {
    if (date) {
      return moment(date).fromNow();
    }
  };

  const greet = () => {
    const date = new Date();
    let greet;
    let hours = date.getHours();
    if (hours < 12) greet = "Good Morning";
    else if (hours >= 12 && hours <= 17) greet = "Good Afternoon";
    else if (hours >= 17 && hours <= 24) greet = "Good Evening";

    return greet;
  };

  const getActivityLogs = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getactivitylogs();
    setActivitylogs(response.data.data);
    updateLoader(false);
  };

  const getActiveClient = async () => {
    updateLoader(true, `Loading`);
    const response = await ClientService.getactiveclients();
    setactiveClients(response.data.data);
    const inactiveuser = await UserService.getinactiveusers();
    setinactiveUsers(inactiveuser.data.data);
    const inactiveclient = await ClientService.getinactiveclients();
    setinactiveClients(inactiveclient.data.data);
    const activeuser = await UserService.getactiveusers();
    setactiveUsers(activeuser.data.data);
    updateLoader(false);
  };

  const getincome = async () => {
    updateLoader(true, "Loading");
    const response = await PaymentService.getincome();
    setIncome(response.data.data);
    updateLoader(false);
  };
  const getnotification = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getLatestnotificationss();
    setNotifications(response.data.data);
    updateLoader(false);
  };
  useEffect(() => {
    getActivityLogs();
    if (user_type === roles.Admin) {
      getActiveClient();
    }
    getincome();
    getnotification();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <div className="cover">
          <Navbar bg="lite" variant="light">
            <Navbar.Brand href="#home" className="mr-auto">
              <img className="logo" src={Logo} alt="Logo" />
            </Navbar.Brand>
            <Form inline className="mr-3">
              <Dropdown variant="lite shadow" alignright>
                <Dropdown.Toggle id="dropdown-custom-components" variant="lite">
                  <i className="fa fa-bell" aria-hidden="true"></i>{" "}
                  <Badge variant="danger rounded-circle"></Badge>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <small>Recent</small>
                  </Dropdown.Item>
                  {notifications.map((notification, i) => (
                    <Dropdown.Item eventKey="1" key={i}>
                      <Link to="/task">
                        <div className=" clearfix mb-2">
                          <img
                            className="activity-icon float-left mr-2"
                            src={User}
                            alt="users icon"
                          />
                          <small className="mb-5">
                            {" "}
                            <strong>
                              {" "}
                              {notification.sent_by &&
                                notification.sent_by.first_name +
                                  " " +
                                  notification.sent_by.last_name}{" "}
                            </strong>{" "}
                          </small>
                          <small className="float-right text-muted">
                            {date(notification.created_at)}{" "}
                          </small>{" "}
                          <br></br>
                          <div className="activity-info">
                            <small className="text-muted mt-3">
                              {notification.action}
                            </small>
                          </div>
                        </div>
                      </Link>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item className="text-center">
                    <Link to="/notification">
                      <small className="text-danger">View all</small>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle variant="lite" id="dropdown-basic" alignright>
                  <img className="notify mr-3" src={User} alt="users icon" />
                  {user !== null ? user.first_name : null}{" "}
                  <i className="fa fa-chevron-down"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to="/profile">
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                  </Link>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form>
          </Navbar>

          <Jumbotron fluid>
            <Container>
              <span className="text-white title-text">
                {" "}
                {greet()} {user && user.first_name}{" "}
              </span>
            </Container>
          </Jumbotron>

          <Container className="dash-content">
            <Row>
              <Col xs={12} md={8}>
                <Row className="mb-3">
                  {permissions &&
                  permissions.includes(permission.user_view) === true ? (
                    <Col xs={6} md={4}>
                      <Link to="/users">
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Users}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>Users</strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : null}
                  {permissions &&
                  permissions.includes(permission.client_view) === true ? (
                    <Col xs={6} md={4}>
                      <Link to="/clients">
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Clients}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>
                                {" "}
                                {user_type === roles.Client
                                  ? "Customers"
                                  : "Clients"}
                              </strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : null}
                  {user_type === roles.Client ? (
                    <Col xs={6} md={4}>
                      <Link to="/business">
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Business}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>Business</strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : null}
                </Row>

                <Row>
                  {user_type === roles.Admin ? (
                    <Col xs={6} md={4}>
                      <Link to="/task">
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Task}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>Tasks</strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : null}

                  {user_type === roles.Admin ? (
                    <Col xs={6} md={4}>
                      <Link to="/report">
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Reports}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>Reports</strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : (
                    <Col xs={6} md={4}>
                      <Link to="/clients-reports">
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Reports}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>Reports</strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  )}

                  {permissions &&
                  permissions.includes(permission.config_view) === true ? (
                    user_type === roles.Admin ? (
                      <Col xs={6} md={4}>
                        <Link to="/settings">
                          <Card border="light">
                            <Card.Body className="text-center">
                              <img
                                className="dash-icon"
                                src={Config}
                                alt="users icon"
                              />
                              <h6>
                                {" "}
                                <strong>Configuration</strong>{" "}
                              </h6>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ) : null
                  ) : null}

                  {user_type === roles.Client ? (
                    <Col xs={6} md={4}>
                      <Link to={`/client-payment`}>
                        <Card border="light">
                          <Card.Body className="text-center">
                            <img
                              className="dash-icon"
                              src={Business}
                              alt="users icon"
                            />
                            <h6>
                              {" "}
                              <strong>Payment</strong>{" "}
                            </h6>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ) : null}
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <Card border="light" className="activity-card">
                  <Card.Header>
                    <div className="clearfix">
                      <span className="float-left">
                        {" "}
                        <strong>Recent Activities</strong>{" "}
                      </span>

                      <Link to="#">
                        {/* <small className="float-right text-muted"> Clear </small> */}
                      </Link>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {activitylogs.map((activity, i) => (
                      <div className=" clearfix mb-2" key={i}>
                        <img
                          className="activity-icon float-left mr-2"
                          src={User}
                          alt="users icon"
                        />
                        <small className="mb-5">
                          {" "}
                          <strong> {activity.user.first_name} </strong>{" "}
                        </small>
                        <small className="float-right text-muted">
                          {" "}
                          {date(activity.created_at)}{" "}
                        </small>{" "}
                        <br></br>
                        <div className="activity-info">
                          <small className="text-muted mt-3">
                            {" "}
                            {activity.activity}
                          </small>
                        </div>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {user_type === roles.Admin && (
              <Row className="mt-4 mb-4">
                <Col md={6}>
                  <Card>
                    <Card.Body>
                      <Bar
                        data={IncomeGen}
                        height={300}
                        options={{
                          maintainAspectRatio: false,
                        }}
                      />
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <Card>
                        <Card.Body>
                          <Pie
                            data={clientChart}
                            height={300}
                            options={{
                              maintainAspectRatio: false,
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card>
                        <Card.Body>
                          <Line
                            data={userChart}
                            height={300}
                            options={{
                              maintainAspectRatio: false,
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </Styles>
    </PageBody>
  );
};
