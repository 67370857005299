export const clientType = {
  GET_ALL: "GET_ALL",
  GET_ONE: "GET_ONE",
  GET_ACTIVE: "GET_ACTIVE",
  GET_INACTIVE: "GET_INACTIVE",
  GET_PAID: "GET_PAID",
};

export const businessType = {
  GET_ALL_BUSINESS: "GET_ALL_BUSINESS",
  GET_ONE_BUSINESS: "GET_ONE_BUSINESS",
  GET_ACTIVE_BUSINESS: "GET_ACTIVE_BUSINESS",
  GET_IN_ACTIVE_BUSINESS: "GET_IN_ACTIVE_BUSINESS",
};

export const businessUserType = {
  GET_ALL_B_USERS: "GET_ALL_B_USERS",
  GET_ONE_B_USER: "GET_ONE_B_USER",
  GET_ACTIVE_B_USER: "GET_ACTIVE_B_USER",
  GET_IN_ACTIVE_B_USER: "GET_IN_ACTIVE_B_USER",
};

export const userType = {
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ONE_USER: "GET_ONE_USER",
  GET_CLIENT_USER: "GET_CLIENT_USER",
  GET_ACTIVE_USERS: "GET_ACTIVE_USERS",
  GET_IN_ACTIVE_USERS: "GET_IN_ACTIVE_USERS",
};
