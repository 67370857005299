import * as React from 'react'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css';


export const ConfirmTemplate = (data,request) => (
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h1>{data.title}</h1>
                <p>{data.message}</p>
                <button onClick={onClose}>No</button>
                <button
                  onClick={() => {
                    request();
                    onClose();
                  }}
                >
                  Yes
                </button>
              </div>
            );
          }
      })
  )

