// require("dotenv").config();
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Chart from 'chart.js';
import "./Assets/css/styles.scss";
import "./Assets/js/main";
import "react-datepicker/dist/react-datepicker.css";
import { Home } from "./Pages/Auth/Home";
import { Login } from "./Pages/Auth/Login";
import { Register } from "./Pages/Auth/Register";
import { Nofile } from "./Pages/Auth/Nofile";
import { First_login } from "./Pages/Auth/First_login";
import { Reset_password } from "./Pages/Auth/Reset_password";
import { Forget_password } from "./Pages/Auth/Forget_password";
import { Welcome } from "./Pages/Auth/Welcome_page";
import { Dashboard } from "./Pages/Dashboard/Dashboard";
import { Provider as AlertProvider } from "react-alert";
import { AuthProvider } from "./Context/AuthContext";
import { AlertTemplate } from "./Component/AlertTemplate";
import {
  ExternalRoute,
  InternalRoute,
  SpecialRoute,
} from "./Component/Auth.route";
import { Profile } from "./Pages/Dashboard/Profile/Profile";
import { EditProfile } from "./Pages/Dashboard/Profile/Edit-profile";
import Notifications from "./Pages/Dashboard/Notification/Notification";
import { Settings } from "./Pages/Dashboard/Config/Settings";
import { Subscription } from "./Pages/Dashboard/Config/Sub_plan";
import { EditSubscription } from "./Pages/Dashboard/Config/Edit_Sub_plan";
import { Roles } from "./Pages/Dashboard/Config/Roles";
import { Task } from "./Pages/Dashboard/Task/Task";
import { Create_task } from "./Pages/Dashboard/Task/create_task";
import { Edit_task } from "./Pages/Dashboard/Task/Edit_task";
import { Users } from "./Pages/Dashboard/Users/Users";
import { Create_user } from "./Pages/Dashboard/Users/Create_user";
import { Edit_user } from "./Pages/Dashboard/Users/Edit_user";
import { Clients } from "./Pages/Dashboard/Client/Clients";
import { EditRoles } from "./Pages/Dashboard/Config/Edit_role";
import { Create_client } from "./Pages/Dashboard/Client/Create_client";
import { Edit_client } from "./Pages/Dashboard/Client/Edit_client";
import { Manage_client } from "./Pages/Dashboard/Client/Manage_client";
import { Business } from "./Pages/Dashboard/Business/Business";
import { Create_business } from "./Pages/Dashboard/Business/Create_business";
import { Edit_business } from "./Pages/Dashboard/Business/Edit_business";
import { Manage_business } from "./Pages/Dashboard/Business/Manage_business";
// import { Create_client_business } from "./Pages/Dashboard/Client/component/business_details";
// import { Create_client_payment } from "./Pages/Dashboard/Client/component/payment_details";
// import { Edit_client_payment } from "./Pages/Dashboard/Client/component/edit_payments";
import { Business_settings } from "./Pages/Dashboard/Client-Management/Config/Business_settings";
import { Create_tax } from "./Pages/Dashboard/Client-Management/Config/Tax/Create_tax";
import { Edit_tax } from "./Pages/Dashboard/Client-Management/Config/Tax/Edit_tax";
import { Create_policy } from "./Pages/Dashboard/Client-Management/Config/Policy/Create-policy";
import { Edit_policy } from "./Pages/Dashboard/Client-Management/Config/Policy/Edit-policy";
import { Create_client_role } from "./Pages/Dashboard/Client-Management/Config/Roles/Client_role";
import { Edit_client_role } from "./Pages/Dashboard/Client-Management/Config/Roles/Edit_client_role";
import { Create_discount } from "./Pages/Dashboard/Client-Management/Config/Discounts/Create-discount";
import { Edit_discount } from "./Pages/Dashboard/Client-Management/Config/Discounts/Edit-discount";
import { Create_account } from "./Pages/Dashboard/Client-Management/Config/Accounts/Create-account";
import { Edit_account } from "./Pages/Dashboard/Client-Management/Config/Accounts/Edit-account";
import { Create_category } from "./Pages/Dashboard/Client-Management/Config/Category/Create-category";
import { Edit_category } from "./Pages/Dashboard/Client-Management/Config/Category/Edit-category";
import { Inventory } from "./Pages/Dashboard/Client-Management/Inventory/Inventory";
import { Create_product } from "./Pages/Dashboard/Client-Management/Inventory/Product/Create-product";
import { Edit_product } from "./Pages/Dashboard/Client-Management/Inventory/Product/Edit-product";
import { Create_service } from "./Pages/Dashboard/Client-Management/Inventory/Service/Create-service";
import { Edit_service } from "./Pages/Dashboard/Client-Management/Inventory/Service/Edit-service";
import { Create_supply } from "./Pages/Dashboard/Client-Management/Inventory/Supply/Create-supply";
import { Edit_supply } from "./Pages/Dashboard/Client-Management/Inventory/Supply/Edit-supply";
import { Create_location } from "./Pages/Dashboard/Client-Management/Inventory/Location/Create-location";
import { Edit_location } from "./Pages/Dashboard/Client-Management/Inventory/Location/Edit-location";
import { Sales } from "./Pages/Dashboard/Client-Management/Sales/Sales";
import { Cart } from "./Pages/Dashboard/Client-Management/Sales/Cart";
import { Service_pay } from "./Pages/Dashboard/Client-Management/Sales/Service_Cart";
import { Business_users } from "./Pages/Dashboard/Client-Management/User/Business-users";
import { Create_business_user } from "./Pages/Dashboard/Client-Management/User/Create-business-user";
import { ManageBusinessUser } from "./Pages/Dashboard/Client-Management/User/Edit-business-user";
import { Business_task } from "./Pages/Dashboard/Client-Management/Task/Business-task";
import { Create_business_task } from "./Pages/Dashboard/Client-Management/Task/Create-business-task";
import { Edit_business_task } from "./Pages/Dashboard/Client-Management/Task/Edit-business-task";
import { Customers } from "./Pages/Dashboard/Client-Management/Customer/Customers";
import { Create_customer } from "./Pages/Dashboard/Client-Management/Customer/Create-customer";
import { Edit_customer } from "./Pages/Dashboard/Client-Management/Customer/Edit-customer";
import {
  permission,
  permission_client,
  roles,
} from "./Util/Constants/constant";
import { Reports } from "./Pages/Dashboard/Report/Report";
import { Business_reports } from "./Pages/Dashboard/Client-Management/Report/Business-report";
import { Admin_client_report } from "./Pages/Dashboard/Client/Admin_clients_report";
import { Admin_client_payment } from "./Pages/Dashboard/Client/Admin_clients_payment";
import { Edit_payment } from "./Pages/Dashboard/Client-Management/Sales/Edit-payment";
import { Manage_payment } from "./Pages/Dashboard/Client-Management/Sales/Manage_payment";

import { Provider } from "react-redux";
import store from "./redux/store";
import { Make_payment } from "./Pages/Dashboard/Client/Make_payment";
import { Receipt } from "./Pages/Dashboard/Client-Management/Sales/Receipt";

function App() {
  // render() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <AlertProvider
          template={AlertTemplate}
          timeout={5000}
          position={"bottom right"}
        >
          <Router>
            <AuthProvider>
              <Switch>
                <ExternalRoute exact path="/" component={Home} />
                <ExternalRoute exact path="/login" component={Login} />
                <ExternalRoute exact path="/register" component={Register} />
                <ExternalRoute
                  exact
                  path="/forget-password/:token"
                  component={Forget_password}
                />
                <ExternalRoute
                  exact
                  path="/reset-password"
                  component={Reset_password}
                />

                <ExternalRoute exact path="/welcome" component={Welcome} />

                <InternalRoute>
                  <Switch>
                    <SpecialRoute
                      exact
                      path="/first-login"
                      isPrivate
                      component={First_login}
                    />
                    <SpecialRoute
                      exact
                      path="/dashboard"
                      isPrivate
                      component={Dashboard}
                    />
                    <SpecialRoute
                      exact
                      path="/settings"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.config_view}
                      component={Settings}
                    />
                    <SpecialRoute
                      exact
                      path="/profile"
                      isPrivate
                      component={Profile}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-profile"
                      isPrivate
                      component={EditProfile}
                    />
                    <SpecialRoute
                      exact
                      path="/notification"
                      isPrivate
                      component={Notifications}
                    />
                    <SpecialRoute
                      exact
                      path="/sub-plan"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.config_create}
                      component={Subscription}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-sub/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.config_edit}
                      component={EditSubscription}
                    />
                    <SpecialRoute
                      exact
                      path="/role"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.config_create}
                      component={Roles}
                    />
                    <SpecialRoute
                      exact
                      path="/task"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      component={Task}
                    />
                    <SpecialRoute
                      exact
                      path="/create-task"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      component={Create_task}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-task/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      component={Edit_task}
                    />
                    <SpecialRoute
                      exact
                      path="/users"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission.user_view}
                      component={Users}
                    />
                    <SpecialRoute
                      exact
                      path="/create-user"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission.user_create}
                      component={Create_user}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-user/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission.user_edit}
                      component={Edit_user}
                    />
                    <SpecialRoute
                      exact
                      path="/create-client"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission.client_create}
                      component={Create_client}
                    />
                    {/* <SpecialRoute exact path="/create-client-business/:id" isPrivate component={Create_client_business} /> */}
                    {/* <SpecialRoute exact path="/create-client-payment/:id" isPrivate component={Create_client_payment} /> */}
                    <SpecialRoute
                      exact
                      path="/edit-client/:id"
                      role={[roles.Admin, roles.Client]}
                      permission={permission.client_create}
                      isPrivate
                      component={Edit_client}
                    />
                    {/* <SpecialRoute exact path="/edit-client-payment/:id"  isPrivate component={Edit_client_payment}  /> */}
                    <SpecialRoute
                      exact
                      path="/manage-client/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.client_view}
                      component={Manage_client}
                    />
                    <SpecialRoute
                      exact
                      path="/clients"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission.client_view}
                      component={Clients}
                    />

                    <SpecialRoute
                      exact
                      path="/clients-customers/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.client_view}
                      component={Clients}
                    />
                    <SpecialRoute
                      exact
                      path="/clients-users/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.user_view}
                      component={Users}
                    />
                    <SpecialRoute
                      exact
                      path="/clients-business/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.user_view}
                      component={Business}
                    />
                    <SpecialRoute
                      exact
                      path="/clients-tasks/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.user_view}
                      component={Task}
                    />
                    <SpecialRoute
                      exact
                      path="/clients-reports/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.user_view}
                      component={Admin_client_report}
                    />

                    <SpecialRoute
                      exact
                      path="/clients-reports"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission.user_view}
                      component={Admin_client_report}
                    />

                    <SpecialRoute
                      exact
                      path="/clients-payment/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.user_view}
                      component={Admin_client_payment}
                    />

                    <SpecialRoute
                      exact
                      path="/client-payment"
                      isPrivate
                      role={[roles.Client]}
                      // permission={permission.user_view}
                      component={Admin_client_payment}
                    />

                    <SpecialRoute
                      exact
                      path="/make-payment"
                      isPrivate
                      role={[roles.Client]}
                      // permission={permission.user_view}
                      component={Make_payment}
                    />

                    <SpecialRoute
                      exact
                      path="/clients-settings/:id"
                      isPrivate
                      role={[roles.Admin]}
                      permission={permission.user_view}
                      component={Settings}
                    />
                    <SpecialRoute
                      exact
                      path="/create-business/:id"
                      isPrivate
                      component={Create_business}
                    />

                    <SpecialRoute
                      exact
                      path="/edit-role/:id"
                      role={[roles.Admin]}
                      permission={permission.config_edit}
                      isPrivate
                      component={EditRoles}
                    />
                    <SpecialRoute
                      exact
                      path="/business"
                      isPrivate
                      role={[roles.Client]}
                      permission={permission.business_view}
                      component={Business}
                    />
                    <SpecialRoute
                      exact
                      path="/create-business"
                      isPrivate
                      component={Create_business}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-business/:id"
                      isPrivate
                      component={Edit_business}
                    />
                    <SpecialRoute
                      exact
                      path="/manage-business/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      component={Manage_business}
                    />
                    <SpecialRoute
                      exact
                      path="/business-settings/:id/"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Business_settings}
                    />
                    <SpecialRoute
                      exact
                      path="/create-tax/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Create_tax}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-tax/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Edit_tax}
                    />
                    <SpecialRoute
                      exact
                      path="/create-policy/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Create_policy}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-policy/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Edit_policy}
                    />
                    <SpecialRoute
                      exact
                      path="/business-role/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Create_client_role}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-business-role/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Edit_client_role}
                    />
                    <SpecialRoute
                      exact
                      path="/create-discount/:id"
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      isPrivate
                      component={Create_discount}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-discount/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Edit_discount}
                    />
                    <SpecialRoute
                      exact
                      path="/create-account/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client]}
                      permission={permission_client.config_view}
                      component={Create_account}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-account/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Edit_account}
                    />
                    <SpecialRoute
                      exact
                      path="/create-category/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Create_category}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-category/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Edit_category}
                    />
                    <SpecialRoute
                      exact
                      path="/inventory/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Inventory}
                    />

                    <SpecialRoute
                      exact
                      path="/create-product/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Create_product}
                    />

                    <SpecialRoute
                      exact
                      path="/manage-payment/:order_id/manage"
                      isPrivate
                      role={[roles.Client]}
                      component={Edit_payment}
                    />

                    <SpecialRoute
                      exact
                      path="/manage-payment/:id/:order_id"
                      isPrivate
                      role={[roles.Client]}
                      component={Manage_payment}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-product/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Edit_product}
                    />
                    <SpecialRoute
                      exact
                      path="/create-service/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Create_service}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-service/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Edit_service}
                    />
                    <SpecialRoute
                      exact
                      path="/create-supply/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Create_supply}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-supply/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_inventory_view}
                      component={Edit_supply}
                    />
                    <SpecialRoute
                      exact
                      path="/create-location/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Create_location}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-location/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.config_view}
                      component={Edit_location}
                    />
                    <SpecialRoute
                      exact
                      path="/sales/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_sale_view}
                      component={Sales}
                    />
                    <SpecialRoute
                      exact
                      path="/cart/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_sale_view}
                      component={Cart}
                    />
                    <SpecialRoute
                      exact
                      path="/sales/receipt/:id/:order_id"
                      isPrivate
                      role={[roles.Client, roles.Client_user]}
                      permission={permission_client.client_sale_view}
                      component={Receipt}
                    />
                    <SpecialRoute
                      exact
                      path="/service/:id/:service_id"
                      isPrivate
                      role={[roles.Client, roles.Client_user]}
                      component={Service_pay}
                    />
                    <SpecialRoute
                      exact
                      path="/business-users/:id/"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_user_view}
                      component={Business_users}
                    />
                    <SpecialRoute
                      exact
                      path="/create-business-user/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_user_view}
                      component={Create_business_user}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-business-user/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_user_view}
                      component={ManageBusinessUser}
                    />
                    <SpecialRoute
                      exact
                      path="/business-tasks/:id/"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_task_view}
                      component={Business_task}
                    />
                    <SpecialRoute
                      exact
                      path="/create-business-task/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_task_view}
                      component={Create_business_task}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-business-task/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_task_view}
                      component={Edit_business_task}
                    />
                    <SpecialRoute
                      exact
                      path="/customers/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_customer_view}
                      component={Customers}
                    />
                    <SpecialRoute
                      exact
                      path="/create-customer/:id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_customer_view}
                      component={Create_customer}
                    />
                    <SpecialRoute
                      exact
                      path="/edit-customer/:id/:business_id"
                      isPrivate
                      role={[roles.Admin, roles.Client, roles.Client_user]}
                      permission={permission_client.client_customer_view}
                      component={Edit_customer}
                    />
                    <SpecialRoute
                      exact
                      path="/report"
                      isPrivate
                      component={Reports}
                    />
                    <SpecialRoute
                      exact
                      path="/business-report/:id"
                      isPrivate
                      component={Business_reports}
                    />
                  </Switch>
                </InternalRoute>

                <Route path="" component={Nofile} />
              </Switch>
            </AuthProvider>
          </Router>
        </AlertProvider>
      </Provider>
    </React.Fragment>
  );
  // }
}

export default App;
