import React, { useState } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Jumbotron as Jumbo,
  Table,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { PageBody } from "../../../../Component/Loader";
import { formattCurrency } from "../../../../Util/formattCurrency";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const Manage_payment = ({ match }) => {
  //   const { alert } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [payments, setPayments] = React.useState("");
  const [report, setReport] = React.useState([]);
  const history = useHistory();

  const getSaleReport = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getSaleReport(
      match.params.order_id
    );
    setReport(response.data.data);
    updateLoader(false);
  };

  console.log(report);

  const dateFormat = (data) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(data);
    return date.toLocaleDateString("en-US", options);
  };

  console.log(report);

  React.useEffect(() => {
    getSaleReport();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />
        <Jumbo fluid></Jumbo>
        <Container fluid>
          <Form inline>
            <Col md={3}>
              {report.total_amount - report.total_paid > 0 && (
                <>
                  <Link to={`/manage-payment/${match.params.order_id}/manage`}>
                    <Button className="btn-create shadow p-2 m-3">
                      Make payment
                    </Button>
                  </Link>{" "}
                </>
              )}
            </Col>
            <Col md={3}></Col>
            <Col md={3}></Col>
            <Col md={3}>
              <div className="breadcrum">
                <ul>
                  <Link to={`/manage-business/${match.params.id}`}>
                    <li>Business</li>
                  </Link>
                  <Link to={`/sales/${match.params.id}`}>
                    <li>Sales</li>
                  </Link>
                  <li>Payment</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>

        <Container className="mt-3" fluid>
          <Row className="mt-4 mb-4">
            <Col md={12}>
              {/* <h5 className="text-center">Payment</h5> */}
              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>CUSTOMER NAME</th>
                    <th>AMOUNT PAID</th>
                    <th>PAYMENT TYPE</th>
                    <th>PAYMENT DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {report.sale_payments &&
                    report.sale_payments.map((sale_payment) => (
                      <tr>
                        <td>
                          <small>
                            <strong>
                              {" "}
                              {report.customer &&
                                `${report.customer.first_name}
                                ${report.customer.last_name}`}{" "}
                            </strong>
                          </small>
                        </td>
                        <td>₦ {formattCurrency(sale_payment.paid_amount)}</td>
                        <td>{sale_payment.payment_type}</td>
                        <td>{dateFormat(sale_payment.payment_date)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Styles>
    </PageBody>
  );
};
