import React, { useState, useContext } from "react";
import { Form, Col, Row, Container, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../Context/AuthContext";

const Styles = styled.div``;

export const Login = () => {
  const { login } = useContext(AuthContext);

  const [passwordShow, setPasswordShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const togglePasswordVisiblity = () => {
    setShowIcon(showIcon ? false : true);
    setPasswordShow(passwordShow ? false : true);
  };

  return (
    <Styles>
      <div className="authContainer">
        <Container>
          <Row>
            <Col xs={12} md={5}></Col>
            <Col xs={6} md={6}>
              <div className="formContainer-1">
                <h3 className="title-text mb-5">Welcome to NRS</h3>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Email is Required"),
                    password: Yup.string()
                      .max(12, "Must be 12 characters or less")
                      .required("Password is Required"),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    await login(values);
                    setSubmitting(false);
                  }}
                >
                  {({
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {touched.email && errors.email ? (
                          <div className="text-danger">{errors.email}</div>
                        ) : null}{" "}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            type={passwordShow ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          <InputGroup.Prepend onClick={togglePasswordVisiblity}>
                            <InputGroup.Text>
                              {" "}
                              <i
                                className={
                                  showIcon ? "fa button fa-eye-slash" : "fa button fa-eye"
                                }
                              ></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                        {touched.password && errors.password ? (
                          <div className="text-danger">{errors.password}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <div className="mt-4 clearfix">
                          <Link to="/">
                            <small className="float-left">
                              {" "}
                              <strong className="ml-2">
                                {" "}
                                <i className=" fa fa-chevron-left"></i> Home
                              </strong>{" "}
                            </small>
                          </Link>

                          <Link to="/reset-password">
                            <small className="float-right">
                              {" "}
                              <strong>Forgot Password ?</strong>{" "}
                            </small>
                          </Link>
                        </div>
                      </Form.Group>

                      <Form.Group>
                        <div className="mt-5">
                          <Button
                            type="submit"
                            variant="secondary btn-action"
                            size="lg"
                            disabled={isSubmitting}
                            block
                          >
                            LOGIN{" "}
                            {isSubmitting ? (
                              <span
                                id="login_spinner"
                                className="fa fa-spin fa-spinner text-white"
                              ></span>
                            ) : null}
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Styles>
  );
};
