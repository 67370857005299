import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Button, Card, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import User from "../../../Assets/Img/user-icon.png";
import { TaskService } from "../../../Services/taskService";
import { AuthContext } from "../../../Context/AuthContext";
import { ConfirmTemplate } from "../../../Component/confirm";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { PageBody } from "../../../Component/Loader";
import * as moment from "moment";
import { permission } from "../../../Util/Constants/constant";

const Styles = styled.div`
  .dropdown-toggle::after {
    display: none !important;
  }
`;

export const Task = ({ match }) => {
  const [tasks, setTasks] = useState([]);
  const { permissions } = useContext(AuthContext);
  const { alert } = useContext(AuthContext);
  const [active, setActive] = useState();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [searchtask, setSearchtask] = useState("");

  const date = (date) => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
  };

  const gettasks = async () => {
    updateLoader(true, "Fetching Tasks");

    if (match.params.id === undefined) {
      const response = await TaskService.gettasks();
      setTasks(response.data.data);
      setActive(1);
      updateLoader(false);
    } else {
      const response = await TaskService.getClienttasks(match.params.id);
      setTasks(response.data.data);
      setActive(1);
      updateLoader(false);
    }
  };

  const getPending = async () => {
    updateLoader(true, "Fetching Tasks");
    if (match.params.id === undefined) {
      const response = await TaskService.getpending();
      setTasks(response.data.data);
      setActive(2);
      updateLoader(false);
    } else {
      const response = await TaskService.getClientpending(match.params.id);
      setTasks(response.data.data);
      setActive(2);
      updateLoader(false);
    }
  };

  const getOngoing = async () => {
    updateLoader(true, "Fetching Tasks");
    if (match.params.id === undefined) {
      const response = await TaskService.getongoing();
      setTasks(response.data.data);
      setActive(3);
      updateLoader(false);
    } else {
      const response = await TaskService.getClientongoing(match.params.id);
      setTasks(response.data.data);
      setActive(3);
      updateLoader(false);
    }
  };

  const getCompleted = async () => {
    updateLoader(true, "Fetching Tasks");
    if (match.params.id === undefined) {
      const response = await TaskService.getcompleted();
      setTasks(response.data.data);
      setActive(4);
      updateLoader(false);
    } else {
      const response = await TaskService.getClientcompleted(match.params.id);
      setTasks(response.data.data);
      setActive(4);
      updateLoader(false);
    }
  };

  const onDelete = (id) => {
    const request = async () => {
      updateLoader(true, "Deleting Task");
      const response = await TaskService.deletetask(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      gettasks();
    };

    ConfirmTemplate(
      {
        title: "Deleting Task",
        message: "Are You Sure You Want To Delete This Task",
      },
      request
    );
  };

  // const onPending = (id) =>{
  //     const body={
  //         status:"pending"
  //     }
  //     const request = async ()=>{
  //         updateLoader(true, "Changing Task Status");
  //        const response =  await TaskService.updatetask(id,body)
  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             gettasks()
  //          }

  //     ConfirmTemplate({title:"Changing Task Status",message:"Are You Sure You Want To change This Task to pending"},request)
  // }

  // const onGoing = (id) =>{
  //     const body={
  //         status:"ongoing"
  //     }
  //     const request = async ()=>{
  //         updateLoader(true, "Changing Task Status");
  //        const response =  await  TaskService.updatetask(id,body)
  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             gettasks()
  //          }

  //          ConfirmTemplate({title:"Changing Task Status",message:"Are You Sure You Want To change This Task to ongoing"},request)
  //         }

  // const onCompleted = (id) =>{
  //     const body={
  //         status:"completed"
  //     }
  //     const request = async ()=>{
  //         updateLoader(true, "Changing Task Status");
  //        const response =  await  TaskService.updatetask(id,body)
  //        if(response.status === 200){
  //         alert.success(response.data.message)
  //             }else{
  //                 alert.error(response.data.message)
  //             }
  //             gettasks()
  //          }

  //          ConfirmTemplate({title:"Changing Task Status",message:"Are You Sure You Want To change This Task to completed"},request)
  //         }

  const allTasks = tasks.filter((task) => {
    return task.title.toLowerCase().indexOf(searchtask.toLowerCase()) !== -1;
  });
  useEffect(() => {
    gettasks();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        <Container className="mt-4" fluid>
          <Form inline>
            <Col md={2}>
              {match.params.id === undefined ? (
                permissions &&
                permissions.includes(permission.task_create) === true ? (
                  <Link to="/create-task">
                    <Button className="btn-create shadow p-2 m-3">
                      Create Task
                    </Button>
                  </Link>
                ) : null
              ) : null}
            </Col>

            <Col md={3}>
              <div className="input-group">
                <input
                  className="form-control py-2 border-right-0 border"
                  type="search"
                  placeholder="search"
                  name="search"
                  value={searchtask}
                  onChange={(e) => setSearchtask(e.target.value)}
                />{" "}
                <span className="input-group-append">
                  <div className="input-group-text bg-transparent">
                    <i className="fa fa-search"></i>
                  </div>
                </span>
              </div>
            </Col>
            <Col className="" md={4}>
              <Button
                className={
                  active === 1 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  gettasks();
                }}
              >
                All
              </Button>
              <Button
                className={
                  active === 4 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  getCompleted();
                }}
              >
                Done
              </Button>
              <Button
                className={
                  active === 2 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  getPending();
                }}
              >
                Pending
              </Button>
              <Button
                className={
                  active === 3 ? "btn-filter-all shadow m-1" : "btn-filter"
                }
                onClick={() => {
                  getOngoing();
                }}
              >
                Ongoing
              </Button>
            </Col>
            <Col md={3}>
              <div className="breadcrum">
                <ul>
                  <Link to="/dashboard">
                    <li>Home</li>
                  </Link>
                  <li>Task</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>
        {tasks !== undefined ? (
          <Container fluid>
            <Row className="mt-4">
              {allTasks < 1 ? (
                <div className="text-center m-auto">
                  <h1>No record found</h1>
                </div>
              ) : (
                allTasks.map((task, i) => (
                  <Col md={6} key={i} className="mb-3">
                    <Card>
                      <Card.Body>
                        <div className="text-truncate">
                          <Card.Title className="float-left">
                            {" "}
                            <small className="text-muted">
                              Task title
                            </small>{" "}
                            <br></br>
                            <p>{task.title}</p>
                          </Card.Title>
                        </div>
                        <Card.Title className="float-right">
                          {" "}
                          <small className="text-muted">
                            Projected due date
                          </small>{" "}
                          <br></br>
                          <small className="text-danger">
                            {" "}
                            {date(task.due_date)}
                          </small>
                        </Card.Title>
                        <Card.Title className="float-right  mr-5">
                          {" "}
                          <small className="text-muted">Priority</small>{" "}
                          <br></br>
                          <small className="text-danger">{task.priority}</small>
                        </Card.Title>
                        <div className="clearfix"></div>
                        <small className="text-muted">
                          Task Description
                        </small>{" "}
                        <br></br>
                        <div className="text-truncate">
                          <p>{task.description}</p>
                        </div>
                        <div className="float-left">
                          <small className="text-muted">Assignee</small>{" "}
                          <br></br>
                          <img className="" src={User} alt="user icon" />
                          <span>
                            {" "}
                            <small className="mb-5">
                              {" "}
                              <strong>
                                {" "}
                                {task.user
                                  ? task.user.first_name +
                                    " " +
                                    task.user.last_name
                                  : null}
                              </strong>{" "}
                            </small>{" "}
                          </span>
                        </div>
                        <div className="float-right">
                          <small className="text-muted"> Task status </small>{" "}
                          <br></br>
                          <Button className="btn-save-two">
                            {task.status === "pending"
                              ? "Pending"
                              : task.status === "ongoing"
                              ? "Ongoing"
                              : task.status === "completed"
                              ? "Done"
                              : null}
                          </Button>
                        </div>
                        <div className="clearfix"></div>
                        <div className="mt-3">
                          {match.params.id === undefined ? (
                            permissions &&
                            permissions.includes(permission.task_edit) ===
                              true ? (
                              <Link to={`/edit-task/${task.id}`}>
                                <Button className="btn-create-two shadow mr-2">
                                  <small>Edit</small>
                                </Button>
                              </Link>
                            ) : null
                          ) : null}
                          {match.params.id === undefined ? (
                            permissions &&
                            permissions.includes(permission.task_delete) ===
                              true ? (
                              <Button
                                className="btn-create-two shadow"
                                onClick={() => {
                                  onDelete(task.id);
                                }}
                              >
                                <small className="text-danger">Delete</small>
                              </Button>
                            ) : null
                          ) : null}
                          {/* <Dropdown>
                        <Dropdown.Toggle variant="lite" size="sm" className="shadow">
                        <span><i className="fa fa-ellipsis-v font-weight-bold" aria-hidden="true"></i></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item  onClick={()=>{onPending(task.id)}} >Pending</Dropdown.Item>
                            <Dropdown.Item  onClick={()=>{onGoing(task.id)}} >Ongoing</Dropdown.Item>
                            <Dropdown.Item  onClick={()=>{onCompleted(task.id)}}>Done</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown> */}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>
          </Container>
        ) : null}
      </Styles>
    </PageBody>
  );
};
