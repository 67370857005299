import React, { useContext } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Jumbotron as Jumbo,
  Card,
  Table,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../Component/Layouts/Navbar";
// import { Formik } from "formik";
// import * as Yup from "yup";
import { AuthContext } from "../../../Context/AuthContext";
// import { ConfigCustomerService } from "../../../Services/systemConfigClient";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { PaymentService } from "../../../Services/paymentService";
import { PageBody } from "../../../Component/Loader";
import { roles } from "../../../Util/Constants/constant";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const Admin_client_payment = ({ match }) => {
  const { user_type } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [payments, setPayments] = React.useState("");
  const history = useHistory();

  const getpayment = async () => {
    updateLoader(true, "Fetching Payments");
    if (match.params.id !== undefined) {
      const response = await PaymentService.getclientpaymentId(match.params.id);
      setPayments(response.data.data);
      updateLoader(false);
    } else {
      const response = await PaymentService.getclientpayment();
      setPayments(response.data.data);
      updateLoader(false);
    }
  };

  console.log(payments);

  const dateFormat = (data) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(data);
    return date.toLocaleDateString("en-US", options);
  };

  // React.useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     getpayment();
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  React.useEffect(() => {
    getpayment();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />
        <Jumbo fluid></Jumbo>
        <Container fluid>
          <Form inline>
            {user_type === roles.Client ? (
              <Col md={3}>
                <Link to={`/make-payment`}>
                  <Button className="btn-create shadow p-2 m-3">
                    Make payment
                  </Button>
                </Link>
              </Col>
            ) : null}
            <Col md={3}></Col>
            <Col md={6}>
              <div className="breadcrum">
                <ul>
                  <Link to="/dashboard">
                    <li>Home</li>
                  </Link>
                  <Link to={`/manage-client/${match.params.id}`}>
                    <li>Manage client</li>
                  </Link>
                  <li>Payment</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>

        <Container className="mt-3" fluid>
          <Row className="mt-4 mb-4">
            <Col md={12}>
              {/* <h5 className="text-center">Payment</h5> */}
              <Table striped bordered variant="light">
                <thead>
                  <tr>
                    <th>AMOUNT</th>
                    <th>PLAN</th>
                    <th>PAYMENT DATE</th>
                    <th>EXPIRATION DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.length !== 0 ? (
                    payments.map((payment, i) => (
                      <tr key={i}>
                        <td>
                          <small>
                            <strong> {payment.amount} </strong>
                          </small>
                        </td>
                        <td>
                          {payment.subscription
                            ? payment.subscription.name
                            : "Trial"}
                        </td>
                        <td>
                          {payment.payment_date &&
                            dateFormat(payment.payment_date)}
                        </td>
                        <td>
                          {payment.expiration_date &&
                            dateFormat(payment.expiration_date)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <small>
                          <strong> {} </strong>
                        </small>
                        
                      </td>
                      <td>Trial</td>
                      <td>{}</td>
                      <td>{}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Styles>
    </PageBody>
  );
};
