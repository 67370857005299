import {ApiClient} from '../Util/ApiClient'


export class BusinessService{


    static async updatebusiness(id,body){
        try{
            const response = await ApiClient.patch(`/businesses/update-business/${id}`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getbusinesses(){
        try{
            const response = await ApiClient.get(`/businesses`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getClientbusinesses(id){
        try{
            const response = await ApiClient.get(`/businesses/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    

    static async getactivebusiness(){
        try{
            const response = await ApiClient.get(`businesses/get/active`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getinactivebusiness(){
        try{
            const response = await ApiClient.get(`/businesses/get/inactive`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getClientactivebusiness(id){
        try{
            const response = await ApiClient.get(`/businesses/get/active/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getClientinactivebusiness(id){
        try{
            const response = await ApiClient.get(`/businesses/get/inactive/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getpaidbusiness(){
        try{
            const response = await ApiClient.get('/businesses/paid-business')
           return response
        }catch(err){
            return err
        }
       
    }

    static async createbusiness(body){
        try{
            const response = await ApiClient.post('/businesses/create-business',body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getbusiness(id){
        try{
            const response = await ApiClient.get(`/businesses/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async deletebusiness(id){
        try{
            const response = await ApiClient.delete(`/businesses/delete-business/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

   
    
}
