import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Button, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../../Component/Layouts/Nav";
import { Formik } from "formik";
import * as Yup from "yup";
import { ConfigService } from "../../../../Services/systemConfigService";
// import { UserService } from "../../../../Services/userService";
import { AuthContext } from "../../../../Context/AuthContext";
import { useHistory } from "react-router-dom";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../Component/Loader";
import { phoneRegExp } from "../../../../Util/Constants/constant";
import { ClientUserService } from "../../../../Services/clientUserService";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";

const Styles = styled.div``;

export const Create_business_user = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [locations, setLocations] = useState([]);

  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getroles = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getclientroles(match.params.id);
    setRoles(response.data.data);
    updateLoader(false);
  };

  const getLocations = async () => {
    updateLoader(true, "Loading");
    const role = await ClientInventoryService.getlocations(match.params.id);
    setLocations(role.data.data);
    updateLoader(false);
  };

  useEffect(() => {
    getroles();
    getLocations();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />

        <Container className="mt-5">
          <h5 className="form-h mb-3">
            {" "}
            <strong>Create New User</strong>{" "}
          </h5>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              role_id: "",
              email: "",
              inventory_location_id: "",
            }}
            validationSchema={Yup.object({
              first_name: Yup.string()
                .trim()
                .required("first name is a required field"),

              last_name: Yup.string()
                .trim()
                .required("last name is a required field"),

              role_id: Yup.number()
                .min(1, "please select from the options")
                .required("role is a required field"),
              inventory_location_id: Yup.number()
                .min(1, "please select from the options")
                .required("role is a required field"),

              email: Yup.string().email("Invalid email address").required(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const response = await ClientUserService.createuser(
                match.params.id,
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push(`/business-users/${match.params.id}`);
              }
              if (response.response.data.data.email)
                return alert.error(response.response.data.data.email);

              if (response.response.data.message)
                return alert.error(response.response.data.message);
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      First name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                    />
                    {touched.first_name && errors.first_name ? (
                      <div className="text-danger">{errors.first_name}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Last name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                    />
                    {touched.last_name && errors.last_name ? (
                      <div className="text-danger">{errors.last_name}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Role <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="role_id"
                      value={values.role_id}
                      onChange={handleChange}
                    >
                      <option value="0">Choose...</option>
                      {roles.map((role, i) => (
                        <option value={role.id} key={i}>
                          {role.name}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.role_id && errors.role_id ? (
                      <div className="text-danger">{errors.role_id}</div>
                    ) : roles.length < 1 ? (
                      <div className="text-danger">
                        you have to create user roles first
                        {
                          <Link
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            to={`/business-role/${match.params.id}`}
                          >
                            {" "}
                            here
                          </Link>
                        }
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                    >
                      <option value={0}>Choose...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Inventory Location <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="inventory_location_id"
                      value={values.inventory_location_id}
                      onChange={handleChange}
                    >
                      <option value="0">Choose...</option>
                      {locations.map((location, i) => (
                        <option value={location.id} key={i}>
                          {location.inventory_location}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.inventory_location_id &&
                    errors.inventory_location_id ? (
                      <div className="text-danger">
                        {errors.inventory_location_id}
                      </div>
                    ) : locations.length < 1 ? (
                      <div className="text-danger">
                        you have to create inventory location first
                        {
                          <Link
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            to={`/create-location/${match.params.id}`}
                          >
                            {" "}
                            here
                          </Link>
                        }
                      </div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/business-users/${match.params.id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
