import React, { useContext, useEffect, useCallback } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../../Context/AuthContext";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../../Component/Loader";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";

export const Edit_policy = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const [policies, setPolicies] = React.useState(null);
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getPolicy = useCallback(async () => {
    updateLoader(true, "Fetching Policies");
    const response = await ConfigCustomerService.getreturnPolicy(
      match.params.id
    );
    setPolicies(response.data.data);
    updateLoader(false);
  }, [updateLoader, match]);

  useEffect(() => {
    getPolicy();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Navbar />

      {policies !== null ? (
        <Container className="mt-5">
          <Formik
            initialValues={{
              policy_name: policies.policy_name,
              return_duration: policies.return_duration,
            }}
            validationSchema={Yup.object({
              policy_name: Yup.string()
                .trim()
                .required("policy name is required"),

              return_duration: Yup.string()
                .trim()
                .required("return duration is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              values.business_id = match.params.business_id;
              const response = await ConfigCustomerService.updatereturnPolicy(
                match.params.id,
                values
              );
              if (response.status === 200) {
                alert.success(response.data.message);
                setSubmitting(false);
                history.push(`/business-settings/${match.params.business_id}`);
              } else {
                alert.error(response.response.data.message);
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="justify-content-md-center">
                  <h5 className=" mb-3">
                    {" "}
                    <strong>Edit Return Policy</strong>{" "}
                  </h5>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={8}>
                    <Form.Label>
                      Policy name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter policy name"
                      name="policy_name"
                      value={values.policy_name}
                      onChange={handleChange}
                    />
                    {touched.policy_name && errors.policy_name ? (
                      <div className="text-danger">{errors.policy_name}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={8}>
                    <Form.Label>
                      Return duration <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="4"
                      name="return_duration"
                      value={values.return_duration}
                      onChange={handleChange}
                    />
                    {touched.return_duration && errors.return_duration ? (
                      <div className="text-danger">
                        {errors.return_duration}
                      </div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/business-settings/${match.params.business_id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={!dirty}
                    >
                      Update{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      ) : null}
    </PageBody>
  );
};
