import { ClientUserService } from "../../../Services/clientUserService";
import { businessUserType } from "../../actionTypes";

export class businessUsersThunk {
  static loadBusers(id) {
    return async (dispatch) => {
      try {
        if (id !== undefined) {
          console.log("3");
          const response = await ClientUserService.getusers(id);
          console.log("res", response);
          if (response.data.data) {
            dispatch({
              type: businessUserType.GET_ALL_B_USERS,
              payload: response.data,
            });
            return;
          }
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllBUsers(id, reduxBusinessUsers) {
    return async (dispatch) => {
      console.log("2");
      try {
        if (reduxBusinessUsers.length === 0) {
          console.log("3");
          const response = await ClientUserService.getusers(id);
          console.log("res", response);
          if (response.data.data) {
            dispatch({
              type: businessUserType.GET_ALL_B_USERS,
              payload: response.data,
            });
            return;
          }
        } else {
          console.log("4");
          dispatch({
            type: businessUserType.GET_ALL_B_USERS,
            payload: reduxBusinessUsers,
          });
          return;
        }
        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getBusinessUser(id, reduxBusinessUsers, businesUsers) {
    return async (dispatch) => {
      try {
        if (!businesUsers || businesUsers.data === undefined) {
          const response = await ClientUserService.getuser(id);
          if (response.data.data) {
            dispatch({
              type: businessUserType.GET_ONE_B_USER,
              payload: response.data,
            });
            return;
          }
        } else {
          const businesUsers = {};
          businesUsers.data =
            reduxBusinessUsers.data &&
            reduxBusinessUsers.data.find((item) => item.id.toString() === id);
          if (businesUsers) {
            dispatch({
              type: businessUserType.GET_ONE_B_USER,
              payload: businesUsers,
            });

            return;
          }
          throw new Error("Client creation failed");
        }
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllActiveBusinessUsers(reduxBusinessUsers) {
    return async (dispatch) => {
      try {
        const activeUsersss = {};

        activeUsersss.data =
          reduxBusinessUsers.data &&
          reduxBusinessUsers.data.filter(
            (item) => item.status.toString().indexOf("1") !== -1
          );

        if (activeUsersss) {
          dispatch({
            type: businessUserType.GET_ACTIVE_B_USER,
            payload: activeUsersss,
          });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllInActiveBusinessUsers(reduxBusinessUsers) {
    return async (dispatch) => {
      try {
        const inActiveUsersss = {};

        inActiveUsersss.data =
          reduxBusinessUsers.data &&
          reduxBusinessUsers.data.filter(
            (item) => item.status.toString().indexOf("0") !== -1
          );

        if (inActiveUsersss) {
          dispatch({
            type: businessUserType.GET_IN_ACTIVE_B_USER,
            payload: inActiveUsersss,
          });

          return;
        }

        throw new Error("Client creation failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
}
