import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Row,
  Table,
  FormCheck,
} from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import product from "../../../../Assets/Img/products/shoes.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../Context/AuthContext";
import { PageBody } from "../../../../Component/Loader";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { ClientCustomerService } from "../../../../Services/clientCustomerService";
import { ClientSalesService } from "../../../../Services/clientSalesService";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import { formattCurrency } from "../../../../Util/formattCurrency";

const Styles = styled.div`
.quantity input {
    display: inline-block;
    width: 50px;
    height: 30px;
    padding 0;
    margin: 10px;
    text-align:center;
    background: #fff;
    border: 1px solid red;
      border-right: none;
  }

  .list-table{
    height: 300px;
    overflow: auto;
  }

`;

export const Cart = ({ match }) => {
  const [Items, setItems] = useState([]);
  const [total, setTotal] = useState();
  const { alert, user_type } = useContext(AuthContext);
  const [discounts, setDiscounts] = useState([]);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [users, setUsers] = useState([]);
  const [Tax, setTax] = useState(0);
  const [toggleForm, setToggleForm] = useState({ show: false });
  const [showDisc, setShowDisc] = useState({ showDis: false });
  const [showCust, setShowCust] = useState({ showCustomer: false });
  const history = useHistory();

  const reduceQuantity = (
    id,
    number,
    cost,
    total_cost,
    discount,
    total_discount
  ) => {
    if (number === 1) return;
    const quantity = number - 1 < 1 ? 1 : number - 1;
    total_discount -= discount;
    console.log(total_discount);
    const price = cost - total_cost;
    const item = Items.map((el) =>
      el.id === id ? { ...el, quantity, price, total_discount } : el
    );
    localStorage.setItem("products", JSON.stringify(item));
    setItems(item);
    let total = 0;
    item.map((item) => {
      return (total += item.price);
    });
    setTotal(total);
  };

  const addQuantity = (
    id,
    number,
    cost,
    total_cost,
    discount,
    quantity_in_stock
  ) => {
    let normal_price = total_cost;
    const quantity = number + 1;

    if (quantity > quantity_in_stock)
      return alert.error("Product quantity exceeds the quantity in stock");

    const price = normal_price * quantity;
    let total_discount = discount * quantity || discount;
    const item = Items.map((el) =>
      el.id === id ? { ...el, quantity, price, total_discount } : el
    );
    localStorage.setItem("products", JSON.stringify(item));
    setItems(item);
    let total = 0;
    item.map((item) => {
      return (total += item.price);
    });
    setTotal(total);
  };

  const deleteQuantity = (id) => {
    Items.map((v, i) => {
      if (v.id === id) {
        const item = Items.slice(0, i).concat(Items.slice(i + 1));
        localStorage.setItem("products", JSON.stringify(item));
        setItems(item);
        alert.success("Product deleted Successfully");
      }
    });
    const items = JSON.parse(localStorage.getItem("products"));
    let total = 0;
    items.map((item) => {
      return (total += item.price);
    });
    setTotal(total);
  };

  const getusers = async () => {
    updateLoader(true, "Fetching Customers");
    const response = await ClientCustomerService.getusers(match.params.id);
    const res = await ConfigCustomerService.getdefaulttax(match.params.id);
    setUsers(response.data.data);
    if (res.data !== undefined) {
      setTax(res.data.data.rate);
    }
    updateLoader(false);
  };

  const getDiscounts = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getdiscounts(match.params.id);
    setDiscounts(response.data.data);
    updateLoader(false);
  };

  const toggleFormCustomer = () => {
    setToggleForm({ show: !toggleForm.show });
  };

  const toggleDiscountForm = () => {
    setShowDisc({ showDis: !showDisc.showDis });
  };

  const toggleCustomer = () => {
    setShowCust({ showCustomer: !showCust.showCustomer });
  };

  useEffect(() => {
    updateLoader(true, "Fetching Customers");
    getusers();
    const items = JSON.parse(localStorage.getItem("products"));
    let total = 0;
    items &&
      items.map((item) => {
        return (total += item.price);
      });
    setTotal(total);
    setItems(items);
    getDiscounts();
    updateLoader(false);
  }, []);

  const calcDiscount = (discount, price) => {
    if (!discount) return "";
    let taxamount = price * (discount / 100);
    return price - taxamount;
  };

  console.log(Items);

  const calcIndivTax = (total_price, status) => {
    let price = total_price;
    let taxAmount = (price /= Tax / 100 + 1);
    let percentageAmount = (Tax / 100) * taxAmount;
    if (status === "isVat") return percentageAmount;
    if (status === "isTotal") return taxAmount;
  };

  // const calcDiscount = (discount, price, status) => {
  //   // console.log(price);
  //   // console.log(discount);
  //   // console.log(status);
  //   let disamount = price * (discount / 100);
  //   if (status === "isDiscount") return disamount;

  //   if (status === "isPrice") return price - disamount;
  // };

  let price = total;
  let taxAmount = (price /= Tax / 100 + 1);
  let percentageAmount = (Tax / 100) * taxAmount;
  return (
    <Styles>
      <PageBody isLoading={isLoading} message={message}>
        <Navbar />
        <Container fluid className="mt-5">
          <h2>
            <strong>Shopping Cart</strong>{" "}
          </h2>
          <Row>
            <Col md={8}>
              <div className="list-table">
                <Table bordered variant="light" responsive>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity/Interval</th>
                      <th>Price</th>
                      <th>Tax({Tax} %)</th>
                      <th>Discount</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Items && Items.length > 0 ? (
                      Items.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <img
                              className="card-img-top product-img-circle"
                              src={product}
                              alt="product"
                            />
                            <small>
                              {" "}
                              <strong> {item.name}</strong>
                            </small>
                          </td>
                          <td>
                            <div className="quantity">
                              <input
                                type="button"
                                value="-"
                                onClick={() =>
                                  reduceQuantity(
                                    item.id,
                                    item.quantity,
                                    item.price,
                                    item.cost,
                                    item.discount,
                                    item.total_discount
                                  )
                                }
                              />
                              <input
                                type="text"
                                name="quantity"
                                value={item.quantity}
                              />
                              <input
                                type="button"
                                value="+"
                                onClick={() =>
                                  addQuantity(
                                    item.id,
                                    item.quantity,
                                    item.price,
                                    item.cost,
                                    item.discount,
                                    item.quantity_in_stock
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td>₦ {item.amount}</td>
                          {/* <td>
                            ₦ {formattCurrency((item.tax / 100) * item.price)}
                          </td> */}
                          <td>
                            {formattCurrency(calcIndivTax(item.price, "isVat"))}
                          </td>
                          <td>
                            {item.discount !== 0 &&
                              `₦ ${formattCurrency(item.total_discount)}`}
                          </td>
                          {/* <td>
                            ₦
                            {formattCurrency(
                              (item.price /= item.tax / 100 + 1)
                            )}
                          </td> */}
                          <td>
                            {formattCurrency(
                              calcIndivTax(item.price, "isTotal")
                            )}
                          </td>
                          <td className="text-danger text-center">
                            <Button
                              variant="light"
                              className="text-danger"
                              onClick={() => deleteQuantity(item.id)}
                            >
                              {" "}
                              <strong>x</strong>{" "}
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <h3 className="text-center">Empty Cart</h3>
                    )}
                  </tbody>
                </Table>
                <div>
                  <Link to={`/sales/${match.params.id}`}>
                    <Button className="btn-create float-right">
                      Continue shopping
                    </Button>
                  </Link>
                  <div className="clearfix">
                    {toggleForm.show ? (
                      <Button
                        onClick={toggleFormCustomer}
                        className="btn-create"
                      >
                        Hide form
                      </Button>
                    ) : (
                      <Button
                        onClick={toggleFormCustomer}
                        className="btn-create"
                      >
                        Show form
                      </Button>
                    )}
                  </div>
                </div>

                <Row>
                  <Col md="12">
                    <Formik
                      initialValues={{
                        first_name: "",
                        phone_number: "",
                        gender: "",
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        first_name: Yup.string().required("name is required"),

                        phone_number: Yup.string().required(
                          "phone number is required"
                        ),

                        email: Yup.string().required("email is required"),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        values.business_id = match.params.id;
                        const response = await ClientCustomerService.createuser(
                          values
                        );
                        if (response.status === 200) {
                          alert.success(response.data.message);
                          setSubmitting(false);
                          getusers();
                        }

                        alert.error(response.response.data.data.email);
                        if (response.response.data.data.phonenumber)
                          alert.error(response.response.data.data.phonenumber);
                      }}
                    >
                      {({
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) =>
                        toggleForm.show && (
                          <>
                            <Card className="mt-4">
                              <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                  <Form.Row>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Name{" "}
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                      />
                                      {touched.first_name &&
                                      errors.first_name ? (
                                        <div className="text-danger">
                                          {errors.first_name}
                                        </div>
                                      ) : null}
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Phone number
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter phone number"
                                        name="phone_number"
                                        value={values.phone_number}
                                        onChange={handleChange}
                                      />
                                      {touched.phone_number &&
                                      errors.phone_number ? (
                                        <div className="text-danger">
                                          {errors.phone_number}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Form.Row>

                                  <Form.Row>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                      />
                                      {touched.email && errors.email ? (
                                        <div className="text-danger">
                                          {errors.email}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Form.Row>

                                  <Form.Group>
                                    <div className="text-center">
                                      <Link to={`/sales/${match.params.id}`}>
                                        {/* <Button className="btn-cancel m-3 shadow">
                                          Cancel
                                        </Button> */}
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn-save m-3 shadow"
                                        disabled={isSubmitting}
                                      >
                                        Save{" "}
                                        {isSubmitting ? (
                                          <span
                                            id="login_spinner"
                                            className="fa fa-spin fa-spinner"
                                          ></span>
                                        ) : null}
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </Form>
                              </Card.Body>
                            </Card>
                          </>
                        )
                      }
                    </Formik>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>Summary</Card.Title>

                  <div className="clearfix">
                    <Card.Text className="float-left">Sub total</Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency(taxAmount)}
                    </Card.Text>
                  </div>
                  <div className="clearfix">
                    <Card.Text className="float-left">
                      Tax <strong>( {Tax}% )</strong>
                    </Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency(percentageAmount)}
                    </Card.Text>
                  </div>
                  <div className="clearfix">
                    <Card.Text className="float-left">Total</Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency(taxAmount + percentageAmount)}
                    </Card.Text>
                  </div>

                  <Formik
                    initialValues={{
                      customer_id: "",
                      discount: "",
                      payment_type: "Cash",
                      reference_no: "",
                    }}
                    validationSchema={Yup.object({
                      //   customer_id: Yup.number()
                      //     .min(1, "please select from the options")
                      //     .required("Customer is a required field"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      if (user_type === "admin")
                        return alert.error(
                          "you don't permission to perform this action"
                        );
                      if (values.payment_type === "POS") {
                        if (values.reference_no === "") {
                          setSubmitting(false);
                          return alert.error(
                            "reference number is needed for pos payment"
                          );
                        }
                      }
                      values.total_amount = taxAmount + percentageAmount;
                      values.total_paid = values.total_amount;
                      values.paid_amount = values.total_amount;
                      // values.part_payment
                      //   ? (values.paid_amount = values.part_payment)
                      //   : (values.paid_amount = totalAmount);
                      values.products = Items;
                      values.total_tax = Tax;
                      values.type = "product";

                      values.business_id = match.params.id;
                      // console.log(values);
                      const response = await ClientSalesService.checkout(
                        values
                      );
                      if (response.status === 200) {
                        alert.success(response.data.message);
                        localStorage.setItem("products", []);
                        setSubmitting(false);
                        history.push(
                          `/sales/receipt/${match.params.id}/${response.data.data[0].order_id}`
                        );
                      }
                      if (response.response.data.data)
                        return alert.error(response.response.data.data[0]);
                    }}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} md={12}>
                            {showCust.showCustomer ? (
                              <p
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-primary"
                                onClick={toggleCustomer}
                              >
                                - Customer
                              </p>
                            ) : (
                              <p
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-primary"
                                onClick={toggleCustomer}
                              >
                                + Customer
                              </p>
                            )}
                            {showCust.showCustomer && (
                              <Form.Control
                                as="select"
                                name="customer_id"
                                value={values.customer_id}
                                onChange={handleChange}
                              >
                                <option value="0">Choose...</option>
                                {users.map((user, i) => (
                                  <option value={user.id} key={i}>
                                    {user.first_name + " " + user.last_name}
                                  </option>
                                ))}
                              </Form.Control>
                            )}
                          </Form.Group>
                        </Form.Row>

                        <Form.Row className="mb-3">
                          {showDisc.showDis ? (
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                              className="text-primary"
                              onClick={toggleDiscountForm}
                            >
                              - Hide discount
                            </p>
                          ) : (
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                              className="text-primary"
                              onClick={toggleDiscountForm}
                            >
                              + Add discount
                            </p>
                          )}
                          {showDisc.showDis && (
                            <>
                              <Form.Control
                                type="text"
                                name="discount"
                                value={values.discount}
                                onChange={handleChange}
                              />
                              {touched.discount && errors.discount ? (
                                <div className="text-danger">
                                  {errors.discount}
                                </div>
                              ) : null}
                            </>
                          )}
                        </Form.Row>
                        {/* <Form.Row className="mb-3">
                          <Form.Group as={Col} md={12}>
                            <Form.Label>Discounts</Form.Label>
                            <Form.Control
                              as="select"
                              name="discount"
                              value={values.discount}
                              onChange={handleChange}
                            >
                              <option value="0">Choose...</option>
                              {discounts.map((discount, i) => (
                                <option value={discount.id} key={i}>
                                  {`${discount.name} %${discount.percentage}`}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row> */}
                        {/* 
                        <div className="clearfix mt-4">
                          <Card.Text className="float-left">Total</Card.Text>
                          <Card.Text className="float-right">
                            ₦ {formattCurrency(taxAmount + percentageAmount)}
                          </Card.Text>
                        </div> */}

                        <Form.Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Check
                              type="radio"
                              label="Cash"
                              value="Cash"
                              name="payment_type"
                              defaultChecked={
                                values.payment_type === "Cash" ? true : false
                              }
                              id="Radios1"
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group as={Col} md={6}>
                            <Form.Check
                              type="radio"
                              label="POS"
                              value="POS"
                              onChange={handleChange}
                              name="payment_type"
                              id="Radios2"
                            />
                          </Form.Group>
                        </Form.Row>
                        {values.payment_type === "POS" ? (
                          <Form.Row className="mb-3">
                            <Form.Label>Reference Number</Form.Label>
                            <Form.Control
                              type="text"
                              name="reference_no"
                              value={values.reference_no}
                              onChange={handleChange}
                            />
                            {touched.reference_no && errors.reference_no ? (
                              <div className="text-danger">
                                {errors.reference_no}
                              </div>
                            ) : null}
                          </Form.Row>
                        ) : null}
                        <Button
                          className="bg-blue"
                          size="md"
                          type="submit"
                          block
                        >
                          CHECKOUT{" "}
                          {isSubmitting ? (
                            <span
                              id="login_spinner"
                              className="fa fa-spin fa-spinner"
                            ></span>
                          ) : null}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </PageBody>
    </Styles>
  );
};
