import {ApiClient} from '../Util/ApiClient'


export class ClientCustomerService{

    static async updateuser(id,body){
        try{
            const response = await ApiClient.patch(`/client/customers/update-customer/${id}`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getusers(id){
        try{
            const response = await ApiClient.get(`/client/customers/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getallcustomers(id){
        try{
            const response = await ApiClient.get(`/client/customers/client-customers/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getallauthcustomers(){
        try{
            const response = await ApiClient.get(`/client/customers/client-customers/get`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getactiveusers(id){
        try{
            const response = await ApiClient.get(`/users/active-users/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }
    static async getinactiveusers(id){
        try{
            const response = await ApiClient.get(`/users/inactive-users/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async createuser(body){
        try{
            const response = await ApiClient.post(`/client/customers/create-customer`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getuser(id){
        try{
            const response = await ApiClient.get(`/client/customers/get/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

   
    
}
