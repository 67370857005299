import React from "react";
import { Navbar, Dropdown, Nav, Badge } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Img/logo.png";
import User from "../../Assets/Img/user-icon.png";
import { AuthContext } from "../../Context/AuthContext";
import { ConfigService } from "../../Services/systemConfigService";
import * as moment from "moment";

const Styles = styled.div`
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      max-width: 350px;
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }
`;

export const Client_Navigation = () => {
  const { logout, user } = React.useContext(AuthContext);
  const [notifications, setNotifications] = React.useState([]);

  const getnotification = async () => {
    const response = await ConfigService.getLatestnotificationss();
    setNotifications(response.data.data);
  };
  React.useEffect(() => {
    getnotification();
  }, []);

  const date = (date) => {
    if (date) {
      return moment(date).fromNow();
    }
  };

  return (
    <Styles>
      <Navbar collapseOnSelect expand="lg" bg="lite" sticky="top">
        <Navbar.Brand href="/">
          <img className="logo" src={Logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="txt">
          <Nav className="m-auto text-center">
            {/* <Nav.Link href="/dashboard">Home</Nav.Link>
                <Nav.Link href="/users">Users</Nav.Link>
                <Nav.Link href="/clients">Clients</Nav.Link>
                <Nav.Link href="/business">Business</Nav.Link>
                <Nav.Link href="/task">Task</Nav.Link>
                <Nav.Link href="#">Reports</Nav.Link>
                <Nav.Link href="/settings">Configuration</Nav.Link> */}
          </Nav>
          <Nav>
            <Nav.Link>
              <Dropdown variant="lite shadow" alignRight>
                <Dropdown.Toggle id="dropdown-custom-components" variant="lite">
                  <i className="fa fa-bell" aria-hidden="true"></i>{" "}
                  {/* <Badge variant="danger rounded-circle">9</Badge> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <small>Recent</small>
                  </Dropdown.Item>
                  {notifications.map((notification, i) => (
                    <Dropdown.Item eventKey="1" key={i}>
                      <Link to="/task">
                      <div className=" clearfix mb-2">
                        <img
                          className="activity-icon float-left mr-2"
                          src={User}
                          alt="users icon"
                        />
                        <small className="mb-5">
                          {" "}
                          <strong>
                            {notification.sent_by &&
                              notification.sent_by.first_name +
                                " " +
                                notification.sent_by.last_name}{" "}
                          </strong>{" "}
                        </small>
                        <small className="float-right text-muted">
                          {" "}
                          {date(notification.created_at)}{" "}
                        </small>{" "}
                        <br></br>
                        <div className="activity-info">
                          <small className="text-muted mt-3">
                            {" "}
                            {notification.action}{" "}
                          </small>
                        </div>
                      </div>
                      </Link>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item className="text-center">
                    <Link to="/notification">
                      <small className="text-danger">View all</small>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>

            <Nav.Link>
              <Dropdown>
                <Dropdown.Toggle variant="lite" id="dropdown-basic" alignRight>
                  <img className="notify mr-1" src={User} alt="users icon" />
                  {user !== null ? user.first_name : null}{" "}
                  <i className="fa fa-chevron-down"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to="/profile">
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                  </Link>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link> 
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  );
};
export default Client_Navigation;
