import React, { useEffect } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Jumbotron as Jumbo,
  Form,
} from "react-bootstrap";
import styled from "styled-components";
import Nav from "../../../Component/Layouts/Nav";
import { Link, withRouter } from "react-router-dom";
import User from "../../../Assets/Img/user-icon.png";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { ConfigService } from "../../../Services/systemConfigService";
import { ConfirmTemplate } from "../../../Component/confirm";
import * as moment from "moment";
import { AuthContext } from "../../../Context/AuthContext";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

const Notifications = ({ history }) => {
  const { alert } = React.useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [notifications, setNotifications] = React.useState([]);
  const [active, setActive] = React.useState();

  const date = (date) => {
    if (date) {
      return moment(date).fromNow();
    }
  };

  const getNotifications = async () => {
    updateLoader(true, "Fetching Notifications");
    const response = await ConfigService.getnotificationss();
    setNotifications(response.data.data);
    setActive(1);
    updateLoader(false);
  };

  //   const getSeenNotifications = async () => {
  //     updateLoader(true, "Fetching Notifications");
  //     const response = await ConfigService.getSeennotificationss();
  //     setNotifications(response.data.data);
  //     setActive(2);
  //     updateLoader(false);
  //   };

  //   const getRecentNotifications = async () => {
  //     updateLoader(true, "Fetching Notifications");
  //     const response = await ConfigService.getRecentnotificationss();
  //     setNotifications(response.data.data);
  //     setActive(3);
  //     updateLoader(false);
  //   };

  const onDelete = (id) => {
    const request = async () => {
      updateLoader(true, "Deleting Notification");
      const response = await ConfigService.deletenotificationss(id);
      if (response.status === 200) {
        alert.success(response.data.message);
      } else {
        alert.error(response.data.message);
      }
      getNotifications();
    };

    ConfirmTemplate(
      {
        title: "Deleting Notification ",
        message: "Are You Sure You Want To Delete This Notification",
      },
      request
    );
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        <Jumbo fluid></Jumbo>
        <Container>
          <Form inline onSubmit={(e) => e.preventDefault()}>
            <Col md={4}></Col>
            <Col md={4}>
              {/* <Button className={active===1 ? 'btn-filter-all shadow m-1':'btn-filter'} onClick={()=>{getNotifications()}}>All</Button>      
            <Button className={active===2 ? 'btn-filter-all shadow m-1':'btn-filter'} onClick={()=>{getSeenNotifications()}}>Seen</Button>
            <Button className={active===3 ? 'btn-filter-all shadow m-1':'btn-filter'} onClick={()=>{getRecentNotifications()}}>Recent</Button> */}
            </Col>
            <Col md={4}>
              <div className="breadcrum">
                <ul>
                  <Link onClick={history.goBack}>
                    <li>Back</li>
                  </Link>
                  <li>Notification</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>

        <Container className="mt-4">
          {notifications.map((notification, i) => (
            <Card className="p-2" key={i}>
              <Col md={12}>
                <div className=" clearfix mb-2">
                  <img
                    className="activity-icon float-left mr-2"
                    src={User}
                    alt="users icon"
                  />
                  <small className="mb-5">
                    {" "}
                    <strong>
                      {" "}
                      {notification.sent_by &&
                        notification.sent_by.first_name +
                          " " +
                          notification.sent_by.last_name}{" "}
                    </strong>{" "}
                  </small>
                  <small className="float-right text-muted">
                    {" "}
                    {date(notification.created_at)}
                  </small>{" "}
                  <br></br>
                  <Button
                    variant="lite"
                    size="sm"
                    className="btn text-danger shadow float-right"
                    onClick={() => onDelete(notification.id)}
                  >
                    Delete{" "}
                  </Button>
                  <div className="activity-info">
                    <small className="text-muted mt-3">
                      {" "}
                      {notification.action}
                    </small>
                  </div>
                </div>
              </Col>
            </Card>
          ))}
        </Container>
      </Styles>
    </PageBody>
  );
};

export default withRouter(Notifications);
