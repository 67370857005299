import React, { useContext }  from 'react';
import { Col, Container, Button, Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom'
import { Link } from 'react-router-dom';
import Navbar from "../../../../../Component/Layouts/Navbar";
import {Formik} from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../../../../Context/AuthContext';
import { useLoadingMessage } from '../../../../../Component/useLoadingMessage';
import { PageBody } from '../../../../../Component/Loader';
import { ConfigCustomerService } from '../../../../../Services/systemConfigClient';





export const Edit_tax = ({match}) => {
const {alert} = useContext(AuthContext)
const history = useHistory()
const { isLoading, message, updateLoader } = useLoadingMessage()
const [taxs,setTax] = React.useState(null)


const getTax= async()=>{
    updateLoader(true, "Fetching Tax");
    const response = await ConfigCustomerService.gettax(match.params.id)
    console.log(response)
    setTax(response.data.data)
    updateLoader(false);
}
React.useEffect(()=>{
getTax()
},[])

return(
    <PageBody isLoading={isLoading} message={message} >
                <Navbar/>
        
{taxs !== null  ? 
        <Container className="mt-5">
            

            
                <Formik
                        initialValues={{rate:taxs.rate, isDefault:taxs.isDefault ? true :false}}
                        validationSchema={Yup.object({
                        rate:  Yup.number()
                        .typeError('Rate must be a number')
                        .required("tax rate is required"),
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            values.business_id = match.params.business_id
                            const response = await ConfigCustomerService.updatetaxes(match.params.id,values)
                          if(response.status === 200){
                            alert.success(response.data.message)
                            setSubmitting(false);
                            history.push(`/business-settings/${match.params.business_id}`)
                        }else{
                            alert.error(response.response.data.message)
                        }
                        }}
                        >
                        {({isSubmitting,handleSubmit,handleChange,values,touched,errors,dirty}) => (
                        <Form onSubmit={handleSubmit}>
                            
                            <Form.Row className="justify-content-md-center">
                            <h5 className=" mb-3"> <strong>Edit Tax</strong> </h5>
                            </Form.Row>
                       
                       <Form.Row className="justify-content-md-center">
                            <Form.Group as={Col} md="5">
                            <Form.Label>Tax rate <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter tax rate" name='rate' value={values.rate} onChange={handleChange}/>
                            {touched.rate && errors.rate ? (
                                <div className="text-danger">{errors.rate}</div>
                            ): null} 
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col} md="5" >
                            <Form.Label>   <small>(Set Default)</small></Form.Label>
                            <div className="mb-3">
                                        <Form.Check inline label="Default" type='checkbox' id='1' name='isDefault' value={values.isDefault} checked={values.isDefault} onChange={handleChange}/>                                                              
                                        </div>
                            {touched.isDefault && errors.isDefault ? (
                                <div className="text-danger">{errors.isDefault}</div>
                            ): null} 
                        </Form.Group>
                        </Form.Row>

                        <Form.Group>
                            <div className="mt-3 text-center"> 
                                <Link to={`/business-settings/${match.params.business_id}`}>
                                <Button className="btn-cancel m-3 shadow" >
                                    Cancel
                                </Button>
                                </Link>
                                <Button type='submit' className="btn-save m-3 shadow" disabled={!dirty} >
                                Update {isSubmitting ? (<span id="login_spinner" className="fa fa-spin fa-spinner"></span>) :null}
                                </Button>              
                                </div>
                        </Form.Group>

                        </Form>
                        )}
                </Formik>
        </Container>
    :null}
    </PageBody>

)
}