import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { FullPageLoader } from "../Component/Loader";

export const SpecialRoute = ({ isPrivate, role, permission, ...props }) => {
  const { user, isLoading, isReady, user_type, permissions, alert } =
    useContext(AuthContext);

  if (isLoading || !isReady) {
    return <FullPageLoader isLoading message="Loading" />;
  }

  if (role !== undefined) {
    const roletype = role.includes(user_type);
    if (roletype === false) {
      alert.error("You are not allowed access to that page");

      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      );
    }
  }

  if (permission !== undefined && permissions !== undefined) {
    const userpermission = permissions.includes(permission);

    if (permissions !== null && userpermission === false) {
      // console.log("PEERMIT", userpermission);

      alert.error("You Do not have Permission to access this  pages");

      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      );
    }
  }

  if (user && !isPrivate && user.client_user !== null) {
    return (
      <Redirect
        to={{
          pathname: `/manage-business/${user.client_user.business_id}`,

          state: { from: props.location },
        }}
      />
    );
  } else if (user && !isPrivate) {
    return (
      <Redirect
        to={{
          pathname: "/Dashboard",
          state: { from: props.location },
        }}
      />
    );
  } else if (!user && isPrivate) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: props.location },
        }}
      />
    );
  } else {
    return <Route {...props} />;
  }
};

const AuthedRoute = (isPrivate) => (props) =>
  <SpecialRoute {...{ isPrivate, ...props }} />;

export const InternalRoute = AuthedRoute(true);
export const ExternalRoute = AuthedRoute(false);
