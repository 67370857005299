import React, { useContext, useState, useEffect } from "react";
import {
  Col,
  Container,
  Button,
  Card,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { AuthContext } from "../../../../Context/AuthContext";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { PageBody } from "../../../../Component/Loader";
// import { formattCurrency } from "../../../../Util/formattCurrency";
import { ClientSalesService } from "../../../../Services/clientSalesService";
import { formattCurrency } from "../../../../Util/formattCurrency";

const Styles = styled.div``;

export const Edit_payment = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const history = useHistory();
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState([]);

  const getSaleReport = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getSaleReport(
      match.params.order_id
    );
    setReport(response.data.data);
    updateLoader(false);
  };

  console.log(report);

  let total_balance =
    report.total_amount && report.total_amount - report.total_paid;

  useEffect(() => {
    getSaleReport();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          {reports !== null ? (
            <Formik
              initialValues={{
                paid_amount: "",
                payment_type: "Cash",
                reference_no: "",
                order_id: "",
              }}
              validationSchema={Yup.object({
                paid_amount: Yup.string().required("Amount paid is required"),

                payment_type: Yup.string().required(
                  "Payment method is required"
                ),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                if (values.paid_amount <= 0) {
                  setSubmitting(false);
                  return alert.error("You can't enter a value less than 1");
                }

                // if (values.paid_amount % 5 !== 0) {
                //   setSubmitting(false);
                //   return alert.error("");
                // }

                if (values.payment_type === "POS") {
                  if (values.reference_no === "") {
                    alert.error("reference number is needed for pos payment");
                  }
                }

                if (report.total_amount === report.total_paid) {
                  setSubmitting(false);
                  return alert.error("You have already paid the full payment");
                }

                const total_b = formattCurrency(total_balance);
                if (values.paid_amount > total_b) {
                  setSubmitting(false);
                  return alert.error(
                    "The amount you entered is higher the balance"
                  );
                }
                values.order_id = match.params.order_id;
                // values.total_paid = total_paid += values.paid_amount;
                console.log("VAL", values);
                const response = await ClientSalesService.mangeclientpayment(
                  values
                );
                if (response.status === 200) {
                  alert.success(response.data.message);
                  setSubmitting(false);
                  await history.goBack();
                }
                if (response.response.data.data)
                  return alert.error(response.response.data.data[0]);
              }}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row className="justify-content-md-center">
                    <h5 className=" mb-3">
                      {" "}
                      <strong>Manage Payment</strong>{" "}
                    </h5>
                  </Form.Row>
                  <h5 className="">
                    <strong>
                      Balance: {`₦ ${formattCurrency(total_balance)}`}
                    </strong>
                  </h5>
                  <Form.Row className="mb-3">
                    <Form.Group as={Col} md={12}>
                      <Form.Label>Paid amount</Form.Label>
                      <Form.Control
                        type="number"
                        name="paid_amount"
                        value={values.paid_amount}
                        onChange={handleChange}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Check
                        type="radio"
                        label="Cash"
                        value="Cash"
                        name="payment_type"
                        id="Radios1"
                        defaultChecked={
                          values.payment_type === "Cash" ? true : false
                        }
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Check
                        type="radio"
                        label="POS"
                        value="POS"
                        onChange={handleChange}
                        name="payment_type"
                        id="Radios2"
                      />
                    </Form.Group>
                  </Form.Row>

                  {values.payment_type === "POS" ? (
                    <Form.Row className="mb-3">
                      <Form.Label>Reference Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="reference_no"
                        value={values.reference_no}
                        onChange={handleChange}
                      />
                      {touched.reference_no && errors.reference_no ? (
                        <div className="text-danger">{errors.reference_no}</div>
                      ) : null}
                    </Form.Row>
                  ) : null}
                  <Form.Row className="justify-content-md-center">
                    <Form.Group as={Col} md={9}>
                      <div className="mt-3 text-center">
                        <Link onClick={history.goBack}>
                          <Button className="btn-cancel m-3 shadow">
                            Cancel
                          </Button>
                        </Link>
                        <Button
                          type="submit"
                          className="btn-save m-3 shadow"
                          disabled={isSubmitting}
                          disabled={!dirty}
                        >
                          Pay
                          {isSubmitting ? (
                            <span
                              id="login_spinner"
                              className="fa fa-spin fa-spinner"
                            ></span>
                          ) : null}
                        </Button>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : null}
        </Container>
      </Styles>
    </PageBody>
  );
};
