import {ApiClient} from '../Util/ApiClient'


export class ClientService{


    static async updateclient(id,body){
        try{
            const response = await ApiClient.patch(`/clients/update-client/${id}`,body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getclientscustomers(id){
        try{
            const response = await ApiClient.get(`/clients/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async getclients(){
        try{
            const response = await ApiClient.get(`/clients`)
           return response
        }catch(err){
            return err
        }
       
    }

    static async createclient(body){
        try{
            const response = await ApiClient.post('/clients/create-client',body)
           return response
        }catch(err){
            return err
        }
       
    }

    static async registerclient(body){
        try{
            const response = await ApiClient.post('/client/register-client',body)
           return response
        }catch(err){
            return err
        }
       
    }

    


    static async getactiveclients(){
        try{
            const response = await ApiClient.get('/clients/get-active-clients')
           return response
        }catch(err){
            return err
        }
       
    }
    static async getinactiveclients(){
        try{
            const response = await ApiClient.get('/clients/get-inactive-clients')
           return response
        }catch(err){
            return err
        }
       
    }

    static async getpaidclients(){
        try{
            const response = await ApiClient.get('/clients/get-paid')
           return response
        }catch(err){
            return err
        }
       
    }

    static async getclient(id){
        try{
            const response = await ApiClient.get(`/clients/${id}`)
           return response
        }catch(err){
            return err
        }
       
    }
   
    
}
