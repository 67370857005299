import React, { useContext } from "react";
import { Col, Container, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
// import { ConfigService } from '../../../../../Services/systemConfigService';
import { AuthContext } from "../../../../../Context/AuthContext";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";
import { NigerianBanks } from "../../../../../Util/Constants/constant";

const Styles = styled.div``;

export const Create_account = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Styles>
      <Navbar />

      <Container className="mt-5">
        <Formik
          initialValues={{
            bank_name: "",
            account_name: "",
            account_number: "",
            inventory_location: "",
            default: false,
          }}
          validationSchema={Yup.object({
            bank_name: Yup.string()
              .min(4, "please select an option")
              .required("bank name is required"),

            account_name: Yup.string()
              .trim()
              .required("account name is required"),

            account_number: Yup.number()
              .test(
                "len",
                "Must be exactly 10 characters",
                (val) => val && val.toString().length === 10
              )
              .required("account number is required"),

            inventory_location: Yup.string().nullable(),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.business_id = match.params.id;
            const response = await ConfigCustomerService.createaccounts(values);
            if (response.status === 200) {
              alert.success(response.data.message);
              setSubmitting(false);
              history.push(`/business-settings/${match.params.id}`);
            } else {
              alert.error("Account not created");
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row className="justify-content-md-center">
                <h5 className=" mb-3">
                  {" "}
                  <strong>Account Set Up</strong>{" "}
                </h5>
              </Form.Row>

              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Bank name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="bank_name"
                    value={values.bank_name}
                    onChange={handleChange}
                  >
                    <option value={0}>Choose...</option>
                    {NigerianBanks.map((bank, i) => (
                      <option value={bank.name} key={i}>
                        {bank.name}
                      </option>
                    ))}
                  </Form.Control>
                  {touched.bank_name && errors.bank_name ? (
                    <div className="text-danger">{errors.bank_name}</div>
                  ) : null}
                </Form.Group>

                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Account name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter account name"
                    name="account_name"
                    value={values.account_name}
                    onChange={handleChange}
                  />
                  {touched.account_name && errors.account_name ? (
                    <div className="text-danger">{errors.account_name}</div>
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Row className="justify-content-md-center">
                <Form.Group as={Col} md={5}>
                  <Form.Label>
                    Account number <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // type="number"
                    placeholder="Enter account number"
                    name="account_number"
                    value={values.account_number}
                    onChange={handleChange}
                  />
                  {touched.account_number && errors.account_number ? (
                    <div className="text-danger">{errors.account_number}</div>
                  ) : null}
                </Form.Group>
                <Form.Group as={Col} md={5}>
                  
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <div className="mt-3 text-center">
                  <Link to={`/business-settings/${match.params.id}`}>
                    <Button className="btn-cancel m-3 shadow">Cancel</Button>
                  </Link>
                  <Button
                    type="submit"
                    className="btn-save m-3 shadow"
                    disabled={isSubmitting}
                  >
                    Save{" "}
                    {isSubmitting ? (
                      <span
                        id="login_spinner"
                        className="fa fa-spin fa-spinner"
                      ></span>
                    ) : null}
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Container>
    </Styles>
  );
};
