import { userType } from "../../actionTypes";

const initialState = {
  userss: [],
  AllUsers: [],
  user: {},
};

export const usersReducers = (state = initialState, action) => {
  switch (action.type) {
    case userType.GET_ALL_USERS: {
      return {
        ...state,
        userss: action.payload,
        AllUsers: action.payload,
      };
    }

    case userType.GET_ONE_USER: {
      return { ...state, user: action.payload };
    }

    case userType.GET_ACTIVE_USERS: {
      return { ...state, AllUsers: action.payload };
    }

    case userType.GET_IN_ACTIVE_USERS: {
      return { ...state, AllUsers: action.payload };
    }
    default: {
      return state;
    }
  }
};
