import {ApiClient} from '../Util/ApiClient'


export class AuthService{


    static async authUser(){
        try{
            const response = await ApiClient.get('/users/user')
           return response
        }catch(err){
            return err
        }
       
    }

    static async login(model){
        try{
            const response = await ApiClient.post('/users/login',model)
           return response
        }catch(err){
            return err
        }
       
    }

    static async register(model){
        try{
            const response = await ApiClient.post('/auth/register',model)
           return response
        }catch(err){
            return err
        }
       
    }

    static async sendResetEmail(body) {
        try{
          const response = await ApiClient.post('/password/forgot', body )
         return response
      }catch(err){
          return err
      }
      }

    static async forgetPassword(
       model
      ) {
        return ApiClient.post(
          '/password/reset',
          model
        )
      }

      static async firtLogin(
        model
       ) {
         return ApiClient.post(
           '/users/first-time-login',
           model
         )
       }

    static async logout(){
        try{
            await ApiClient.get('/users/logout')
            const response = ApiClient.unsetToken()
           return response
        }catch(err){
            return err
        }
       
    }
}
