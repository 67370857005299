import React, { useContext, useEffect } from "react";
import { Col, Container, Button, Form, Card, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../../Context/AuthContext";
import { useLoadingMessage } from "../../../../../Component/useLoadingMessage";
import { PageBody } from "../../../../../Component/Loader";
import { ClientInventoryService } from "../../../../../Services/clientInventoryService";
import { ConfigCustomerService } from "../../../../../Services/systemConfigClient";
import DatePicker from "react-datepicker";
// import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

const Styles = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }

  .p-img {
    height: 300px;
  }
`;

export const Create_product = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const history = useHistory();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [locations, setLocation] = React.useState(null);
  const [categories, setCategory] = React.useState(null);

  // const imagePreview = (srcObject, image) => {
  //   const mediaStream = new MediaStream();
  //   const sream = image;
  //   sream.srcObject = mediaStream;
  // };

  const getLocationCategory = async () => {
    updateLoader(true, "Loading");
    const getlocation = await ClientInventoryService.getlocations(
      match.params.id
    );
    const getcategory = await ConfigCustomerService.getproductcategories(
      match.params.id
    );
    setCategory(getcategory.data.data);
    setLocation(getlocation.data.data);
    updateLoader(false);
  };

  const fileTobase64 = async (file, cb) => {
    var content = "";
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      content = reader.result;
      cb(content);
    };
  };

  useEffect(() => {
    getLocationCategory();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />

        <Container className="mt-5">
          <Formik
            initialValues={{
              product_name: "",
              product_id: "",
              product_description: "",
              product_category_id: "",
              quantity: "",
              image: "",
              cost_price: "",
              selling_price: "",
              production_date: "",
              expiration_date: "",
              reorder_level: "",
              inventory_location_id: "",
              default: false,
            }}
            validationSchema={Yup.object({
              product_name: Yup.string()
                .trim()
                .required("product name is required"),

              product_id: Yup.string().required("product id is required"),

              product_description: Yup.string()
                .trim()
                .required("product description is required"),

              product_category_id: Yup.number()
                .min(1, "please select from the options")
                .required("product category is required"),

              quantity: Yup.number()
                .min(1, "please quantity has to be minimum of 1")
                .required("quantity is required"),

              cost_price: Yup.number()
                .typeError("Cost price must be a number")
                .min(1, "please price has to be minimum of 1")
                .required("cost price is required"),

              selling_price: Yup.number()
                .typeError("Selling price must be a number")
                .min(1, "please price has to be minimum of 1")
                .required("selling price is required"),

              // production_date: Yup.string().required(
              //   "production date is required"
              // ),

              // expiration_date: Yup.string().required(
              //   "expiration date is required"
              // ),

              reorder_level: Yup.number()
                .typeError("reorder level must be a number")
                .min(0, "minimum reorder level is 0")
                .required("Reorder level is required"),

              inventory_location_id: Yup.number()
                .min(1, "please select from the options")
                .nullable(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (
                values.inventory_location_id === "" &&
                values.default === false
              ) {
                alert.error(
                  "you have to select global or an inventory location"
                );
              }

              values.business_id = match.params.id;
              if (values.default === true) {
                values.inventory_location_id = null;
              }

              if (values.image) {
                fileTobase64(values.image, async function (content) {
                  values.image = content;
                  const response = await ClientInventoryService.createproduct(
                    values
                  );
                  if (response.status === 200) {
                    console.log(response);
                    alert.success(response.data.message);
                    history.push(`/inventory/${match.params.id}`);
                  } else {
                    alert.error(response.response.data.message);
                    history.push(`/inventory/${match.params.id}`);
                  }
                });
              } else {
                const response = await ClientInventoryService.createproduct(
                  values
                );
                if (response.status === 200) {
                  alert.success(response.data.message);
                  setSubmitting(false);
                  history.push(`/inventory/${match.params.id}`);
                } else {
                  alert.error(response.response.data.message);
                }
              }
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="">
                  <h5 className="mb-5">
                    {" "}
                    <strong>Create New Product</strong>{" "}
                  </h5>
                </Form.Row>
                <Container>
                  <Row>
                    <Col md={8}>
                      <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col}>
                          <Form.Label>
                            {" "}
                            Product name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter product name"
                            name="product_name"
                            value={values.product_name}
                            onChange={handleChange}
                          />
                          {touched.product_name && errors.product_name ? (
                            <div className="text-danger">
                              {errors.product_name}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            Product ID <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter product id"
                            name="product_id"
                            value={values.product_id}
                            onChange={handleChange}
                          />
                          {touched.product_id && errors.product_id ? (
                            <div className="text-danger">
                              {errors.product_id}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {" "}
                            Product category{" "}
                            <span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="product_category_id"
                            value={values.product_category_id}
                            onChange={handleChange}
                          >
                            <option value="0">Choose...</option>
                            {categories.map((category, i) => (
                              <option value={category.id} key={i}>
                                {category.category_name}
                              </option>
                            ))}
                          </Form.Control>
                          {touched.product_category_id &&
                          errors.product_category_id ? (
                            <div className="text-danger">
                              {errors.product_category_id}
                            </div>
                          ) : categories.length < 1 ? (
                            <div className="text-danger">
                              you have to create product category first
                              {
                                <Link
                                  style={{
                                    color: "red",
                                    textDecoration: "underline",
                                  }}
                                  to={`/create-category/${match.params.id}`}
                                >
                                  {" "}
                                  here
                                </Link>
                              }
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            Reorder level <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter reorder level"
                            name="reorder_level"
                            value={values.reorder_level}
                            onChange={handleChange}
                          />
                          {touched.reorder_level && errors.reorder_level ? (
                            <div className="text-danger">
                              {errors.reorder_level}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col}>
                          <Form.Label>
                            {" "}
                            Product description{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter product description"
                            name="product_description"
                            value={values.product_description}
                            onChange={handleChange}
                          />
                          {touched.product_description &&
                          errors.product_description ? (
                            <div className="text-danger">
                              {errors.product_description}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            Quantity <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter quantity"
                            name="quantity"
                            value={values.quantity}
                            onChange={handleChange}
                          />
                          {touched.quantity && errors.quantity ? (
                            <div className="text-danger">{errors.quantity}</div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col}>
                          <Form.Label>
                            {" "}
                            Cost price <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter cost price"
                            name="cost_price"
                            value={values.cost_price}
                            onChange={handleChange}
                          />
                          {touched.cost_price && errors.cost_price ? (
                            <div className="text-danger">
                              {errors.cost_price}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            Selling price <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter selling price"
                            name="selling_price"
                            value={values.selling_price}
                            onChange={handleChange}
                          />
                          {touched.selling_price && errors.selling_price ? (
                            <div className="text-danger">
                              {errors.selling_price}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row className="justify-content-md-center">
                        <Form.Group as={Col} md={6}>
                          <Form.Label> Expiration date</Form.Label>
                          <DatePicker
                            selected={values.expiration_date}
                            dateFormat="MMMM d, yyyy"
                            className="form-control"
                            name="expiration_date"
                            minDate={new Date()}
                            onChange={(date) =>
                              setFieldValue("expiration_date", date)
                            }
                          />
                          {/* {touched.expiration_date && errors.expiration_date ? (
                      <div className="text-danger">
                        {errors.expiration_date}
                      </div>
                    ) : null} */}
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label> Production date</Form.Label>
                          <DatePicker
                            selected={values.production_date}
                            dateFormat="MMMM d, yyyy"
                            className="form-control"
                            name="production_date"
                            maxDate={new Date()}
                            onChange={(date) =>
                              setFieldValue("production_date", date)
                            }
                          />

                          {/* {touched.production_date && errors.production_date ? (
                      <div className="text-danger">
                        {errors.production_date}
                      </div>
                    ) : null} */}
                        </Form.Group>
                      </Form.Row>

                      <Form.Row className="justify-content-md-center mt-5">
                        <Form.Group as={Col} className="mt-4">
                          {["checkbox"].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                              <Form.Check
                                inline
                                label="Globally"
                                type={type}
                                id={`inline-${type}-1`}
                                name="default"
                                checked={values.default ? true : false}
                                onChange={handleChange}
                              />
                            </div>
                          ))}
                        </Form.Group>

                        <Form.Group as={Col}>
                          {values.default === false ? (
                            <div>
                              <Form.Label>
                                {" "}
                                Inventory location{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="inventory_location_id"
                                value={values.inventory_location_id}
                                onChange={handleChange}
                              >
                                <option value="0">Choose...</option>
                                {locations.map((location, i) => (
                                  <option value={location.id} key={i}>
                                    {location.inventory_location}
                                  </option>
                                ))}
                              </Form.Control>
                              {touched.inventory_location_id &&
                              errors.inventory_location_id ? (
                                <div className="text-danger">
                                  {errors.inventory_location_id}
                                </div>
                              ) : locations.length < 1 ? (
                                <div className="text-danger">
                                  you have to create inventory location first
                                  {
                                    <Link
                                      style={{
                                        color: "red",
                                        textDecoration: "underline",
                                      }}
                                      to={`/create-location/${match.params.id}`}
                                    >
                                      {" "}
                                      here
                                    </Link>
                                  }
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>
                    </Col>

                    <Col md={4}>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Product image </Form.Label>
                          <Form.File
                            custom
                            className="form-control"
                            id="image"
                            name="image"
                            placeholder="CLick here to upload product image"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "image",
                                event.currentTarget.files[0]
                              );
                            }}
                          ></Form.File>
                          <Card className="p-img">
                            <Card.Body>
                              <img
                                height="100%"
                                width="100%"
                                src={
                                  values.image && window.URL
                                    ? window.URL.createObjectURL(values.image)
                                    : ""
                                }
                                alt="product image"
                              />
                            </Card.Body>
                          </Card>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                  </Row>
                </Container>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/inventory/${match.params.id}`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      Save{" "}
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
