import React, { useContext, useState } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Jumbotron as Jumbo,
  Card,
  Table,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../Context/AuthContext";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { PaymentService } from "../../../Services/paymentService";
import product from "../../../Assets/Img/products/shoes.png";
import { PageBody } from "../../../Component/Loader";
import { ConfigService } from "../../../Services/systemConfigService";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const Make_payment = ({ match }) => {
  const { alert } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [subscripitions, setSubscription] = useState([]);

  const history = useHistory();

  const getsubscriptionPlan = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigService.getsubscriptions();
    setSubscription(response.data.data);
    updateLoader(false);
  };

  React.useEffect(() => {
    getsubscriptionPlan();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Navbar />
        <Jumbo fluid></Jumbo>
        <Container fluid>
          <Formik
            initialValues={{
              subscription_plan_id: "",
              payment_plan: "",
              no_of_products: "",
            }}
            validationSchema={Yup.object({
              payment_plan: Yup.string()
                .min(3, "please select an option")
                .required("payment plan is a required field"),

              no_of_products: Yup.number()
                .min(1, "please select an option")
                .required("number of product is a required field"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              values.subscription_plan_id = values.no_of_products;
              const response = await PaymentService.makePayment(values);
              console.log(response);
              if (response.status === 200) {
                setSubmitting(false);
                window.open(
                  `https://testnrsapi.remsys.com.ng/payment-page/${response.data.data.client_id}/${response.data.data.subscription_plan_id}/${response.data.data.payment_plan}`,
                  "_blank"
                );
                history.push(`/client-payment`);
              }

              if (response.response.data.message)
                return alert.error(response.response.data.message);
            }}
          >
            {({
              isSubmitting,
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Number products <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="no_of_products"
                      value={values.no_of_products}
                      onChange={handleChange}
                    >
                      <option value="0">Choose...</option>
                      {subscripitions.map((sub, i) => (
                        <option value={sub.id} key={i}>
                          {"From " + sub.product_min + "-" + sub.product_max}
                        </option>
                      ))}
                      {touched.no_of_products && errors.no_of_products ? (
                        <div className="text-danger">
                          {errors.no_of_products}
                        </div>
                      ) : null}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={5}>
                    <Form.Label>
                      Payment plan <span className="text-danger">*</span>{" "}
                      <small className="text-muted">
                        ( Monthly or Annually )
                      </small>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="payment_plan"
                      value={values.payment_plan}
                      onChange={handleChange}
                    >
                      <option value={0}>Choose...</option>
                      <option value="monthly">Monthly</option>
                      <option value="annually">Annually</option>
                    </Form.Control>
                    {touched.payment_plan && errors.payment_plan ? (
                      <div className="text-danger">{errors.payment_plan}</div>
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-md-center">
                  <Form.Group as={Col} md={5}>
                    <div className="shadow p-2">
                      <h6>
                        Subscription Plan:{" "}
                        {subscripitions.map((sub) =>
                          values.no_of_products == sub.id ? sub.name : null
                        )}
                      </h6>
                      <h6>
                        Total:{" "}
                        {subscripitions.map((sub) =>
                          values.no_of_products == sub.id
                            ? values.payment_plan === "annually"
                              ? sub.price * 12
                              : sub.price
                            : null
                        )}
                      </h6>
                    </div>
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <div className="mt-3 text-center">
                    <Link to={`/client-payment/`}>
                      <Button className="btn-cancel m-3 shadow">Cancel</Button>
                    </Link>
                    <Button
                      type="submit"
                      className="btn-save m-3 shadow"
                      disabled={isSubmitting}
                    >
                      PAY
                      {isSubmitting ? (
                        <span
                          id="login_spinner"
                          className="fa fa-spin fa-spinner"
                        ></span>
                      ) : null}
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Container>
      </Styles>
    </PageBody>
  );
};
