import { useState } from 'react'

export const useLoadingMessage = () => {
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const updateLoader = (newIsLoading, newMessage) => {
    setIsLoading(newIsLoading)
    setMessage(newMessage)
  }

  return { message, isLoading, updateLoader }
}
