import { businessType } from "../../actionTypes";

const initialState = {
  businesses: [],
  AllBusiness: [],
  busines: {},
};

export const BusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case businessType.GET_ALL_BUSINESS: {
      return {
        ...state,
        businesses: action.payload,
        AllBusiness: action.payload,
      };
    }

    case businessType.GET_ONE_BUSINESS: {
      return { ...state, busines: action.payload };
    }

    case businessType.GET_ACTIVE_BUSINESS: {
      return { ...state, AllBusiness: action.payload };
    }

    case businessType.GET_IN_ACTIVE_BUSINESS: {
      return { ...state, AllBusiness: action.payload };
    }
    default: {
      return state;
    }
  }
};
