import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Container,
  Card,
  Jumbotron as Jumbo,
  Form,
} from "react-bootstrap";
import styled from "styled-components";
import Nav from "../../../Component/Layouts/Nav";
// import User from '../../../Assets/Img/user-icon.png';
import { Line, Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { AuthContext } from "../../../Context/AuthContext";
import { roles } from "../../../Util/Constants/constant";
import { UserService } from "../../../Services/userService";
import { ClientService } from "../../../Services/clientService";
import { ClientCustomerService } from "../../../Services/clientCustomerService";
import { PaymentService } from "../../../Services/paymentService";

const Styles = styled.div`
  .jumbotron-fluid {
    height: 130px;
  }
`;

export const Reports = ({ match }) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [clients, setClients] = useState([]);
  const [paidclients, setClientsPaid] = useState([]);
  const { user_type } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [income, setIncome] = useState([]);

  const IncomeGen = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Income",
        data: income,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const IncomeExp = {
    labels: ["Trial Clients", "Paid Clients"],
    datasets: [
      {
        label: "Clients",
        data: [clients.length - paidclients.length, paidclients.length],
        fill: true,
        backgroundColor: ["#FCD277", "#B4E080"],
      },
    ],
  };
  const getUsers = async () => {
    updateLoader(true, "Loading");
    if (match.params.id !== undefined) {
      const response = await UserService.getclientusers(match.params.id);
      setUsers(response.data.data);
      updateLoader(false);
    } else {
      const response = await UserService.getusers();
      setUsers(response.data.data);
      updateLoader(false);
    }
  };

  const getClients = async () => {
    updateLoader(true, `Loading`);
    if (match.params.id !== undefined) {
      const response = await ClientCustomerService.getallcustomers(
        match.params.id
      );
      setClients(response.data.data);
      updateLoader(false);
    } else {
      if (user_type === roles.Client) {
        const response = await ClientCustomerService.getallauthcustomers();
        setClients(response.data.data);
        updateLoader(false);
      } else {
        const response = await ClientService.getclients();
        setClients(response.data.data);
        updateLoader(false);
      }
    }
  };
  const getpaidclients = async () => {
    updateLoader(true, "Loading");
    const response = await ClientService.getpaidclients();
    setClientsPaid(response.data.data);
    updateLoader(false);
  };

  const getincome = async () => {
    updateLoader(true, "Loading");
    const response = await PaymentService.getincome();
    setIncome(response.data.data);
    updateLoader(false);
  };
  useEffect(() => {
    getClients();
    getUsers();
    getpaidclients();
    getincome();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        <Jumbo fluid></Jumbo>
        <Container fluid>
          <Form inline>
            <Col md={4}></Col>
            <Col md={4}></Col>
            <Col md={4}>
              <div className="breadcrum">
                <ul>
                  <Link to="/dashboard">
                    <li>Home</li>
                  </Link>
                  <li>Report</li>
                </ul>
              </div>
            </Col>
          </Form>
        </Container>

        <Container fluid>
          <Card className="text-center">
            <Card.Body>
              <Row>
                <Col md={3}>
                  <h3>{users.length}</h3>
                  <Card.Text className="text-danger">Users</Card.Text>
                </Col>
                <Col md={3}>
                  <h3>{clients.length}</h3>
                  <Card.Text className="text-danger">Clients</Card.Text>
                </Col>
                <Col md={3}>
                  <h3>{clients.length - paidclients.length}</h3>
                  <Card.Text className="text-danger">Trial</Card.Text>
                </Col>
                <Col md={3}>
                  <h3>{paidclients.length}</h3>
                  <Card.Text className="text-danger">Paid</Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Row className="mt-4 mb-4">
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Line data={IncomeGen} />
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <Card.Body>
                  <Doughnut data={IncomeExp} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Styles>
    </PageBody>
  );
};
