import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Button, Card, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../../../Component/Layouts/Navbar";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../Context/AuthContext";
import { PageBody } from "../../../../Component/Loader";
import { useLoadingMessage } from "../../../../Component/useLoadingMessage";
import { ClientCustomerService } from "../../../../Services/clientCustomerService";
import { ClientSalesService } from "../../../../Services/clientSalesService";
import { ClientInventoryService } from "../../../../Services/clientInventoryService";
import { ConfigCustomerService } from "../../../../Services/systemConfigClient";
import { formattCurrency } from "../../../../Util/formattCurrency";

const Styles = styled.div`
.quantity input {
    display: inline-block;
    width: 50px;
    height: 30px;
    padding 0;
    margin: 10px;
    text-align:center;
    background: #fff;
    border: 1px solid red;
      border-right: none;
  }

`;

export const Service_pay = ({ match }) => {
  const [service, setService] = useState([]);

  const { alert } = useContext(AuthContext);
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [users, setUsers] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [dis, setDis] = useState(0);
  const [Tax, setTax] = useState(0);
  const [toggleForm, setToggleForm] = useState({ show: false });
  const [showDisc, setShowDisc] = useState({ showDis: false });
  const [showCust, setShowCust] = useState({ showCustomer: false });
  const [showPart, setShowPart] = useState({ showPartPayment: false });
  const history = useHistory();

  const getusers = async () => {
    updateLoader(true, "Fetching Customers");
    const response = await ClientCustomerService.getusers(match.params.id);
    const res = await ConfigCustomerService.getdefaulttax(match.params.id);
    setUsers(response.data.data);
    if (res.data !== undefined) {
      setTax(res.data.data.rate);
    }
    updateLoader(false);
  };

  const getService = async () => {
    updateLoader(true, "Loading");
    const response = await ClientInventoryService.getservice(
      match.params.service_id
    );
    setService(response.data.data);
    updateLoader(false);
  };

  const getDiscounts = async () => {
    updateLoader(true, "Loading");
    const response = await ConfigCustomerService.getdiscounts(match.params.id);
    setDiscounts(response.data.data);
    updateLoader(false);
  };

  const toggleFormCustomer = () => {
    setToggleForm({ show: !toggleForm.show });
  };

  const toggleDiscountForm = () => {
    setShowDisc({ showDis: !showDisc.showDis });
  };

  const toggleCustomer = () => {
    setShowCust({ showCustomer: !showCust.showCustomer });
  };

  const togglePartpayment = () => {
    setShowPart({ showPartPayment: !showPart.showPartPayment });
  };

  const handleDiscount = (e) => {
    setDis(e.target.value);
  };

  let price = service.cost_of_service;
  let taxAmount = (price /= Tax / 100 + 1);
  let percentageAmount = (Tax / 100) * taxAmount;

  useEffect(() => {
    getusers();
    getService();
    getDiscounts();
  }, []);
  return (
    <Styles>
      <PageBody isLoading={isLoading} message={message}>
        <Navbar />
        <Container fluid className="mt-5">
          <h3>Pay For Service</h3>
          <Row>
            <Col md={8}>
              <Card>
                <Card.Body>
                  <Card.Title className="float-left">
                    {" "}
                    <strong>{service.service_name}</strong>{" "}
                  </Card.Title>
                  <div className="clearfix"></div>

                  <h5 className="text-danger">
                    ₦ {formattCurrency(service.cost_of_service)}
                  </h5>
                  <div className="text-truncate">
                    <small>{service.description}</small>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="clearfix mt-3 float-right">
                      {service.part_payment > 0 ? (
                        <p className="text-success">Part payment</p>
                      ) : (
                        <p className="text-muted">No Part payment</p>
                      )}
                    </div>
                    {/* <div className="clearfix mt-3">
                      <Link to={`/service/${match.params.id}/${service.id}`}>
                        <Button className="btn bg-blue">pay</Button>
                      </Link>{" "}
                    </div> */}
                  </div>
                </Card.Body>
              </Card>

              <div>
                <Link to={`/sales/${match.params.id}`}>
                  <Button className="btn-create float-right mt-2">
                    Pay other services
                  </Button>
                </Link>
                <div className="clearfix mt-3">
                  {toggleForm.show ? (
                    <Button onClick={toggleFormCustomer} className="btn-create">
                      Hide form
                    </Button>
                  ) : (
                    <Button onClick={toggleFormCustomer} className="btn-create">
                      Show form
                    </Button>
                  )}
                </div>
                <Row>
                  <Col md="12">
                    <Formik
                      initialValues={{
                        first_name: "",
                        phone_number: "",
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        first_name: Yup.string().required("name is required"),

                        phone_number: Yup.string().required(
                          "phone number is required"
                        ),

                        email: Yup.string().required("email is required"),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        values.business_id = match.params.id;
                        const response = await ClientCustomerService.createuser(
                          values
                        );
                        if (response.status === 200) {
                          alert.success(response.data.message);
                          setSubmitting(false);
                          getusers();
                        }

                        alert.error(response.response.data.data.email);
                        if (response.response.data.data.phonenumber)
                          alert.error(response.response.data.data.phonenumber);
                      }}
                    >
                      {({
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) =>
                        toggleForm.show && (
                          <>
                            <Card className="mt-4">
                              <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                  <Form.Row>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Name{" "}
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                      />
                                      {touched.first_name &&
                                      errors.first_name ? (
                                        <div className="text-danger">
                                          {errors.first_name}
                                        </div>
                                      ) : null}
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Phone number
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter phone number"
                                        name="phone_number"
                                        value={values.phone_number}
                                        onChange={handleChange}
                                      />
                                      {touched.phone_number &&
                                      errors.phone_number ? (
                                        <div className="text-danger">
                                          {errors.phone_number}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Form.Row>

                                  <Form.Row>
                                    <Form.Group as={Col}>
                                      <Form.Label>
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                      />
                                      {touched.email && errors.email ? (
                                        <div className="text-danger">
                                          {errors.email}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Form.Row>

                                  <Form.Group>
                                    <div className="text-center">
                                      <Link to={`/sales/${match.params.id}`}>
                                        {/* <Button className="btn-cancel m-3 shadow">
                                      Cancel
                                    </Button> */}
                                      </Link>
                                      <Button
                                        type="submit"
                                        className="btn-save m-3 shadow"
                                        disabled={isSubmitting}
                                      >
                                        Save{" "}
                                        {isSubmitting ? (
                                          <span
                                            id="login_spinner"
                                            className="fa fa-spin fa-spinner"
                                          ></span>
                                        ) : null}
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </Form>
                              </Card.Body>
                            </Card>
                          </>
                        )
                      }
                    </Formik>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title>Summary</Card.Title>

                  <div className="clearfix">
                    <Card.Text className="float-left">Sub total</Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency(taxAmount)}
                    </Card.Text>
                  </div>
                  {/* <div className="clearfix">
                    <Card.Text className="float-left">Tax</Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency((service.cost_of_service / 100) * Tax)}
                    </Card.Text>
                  </div> */}
                  <div className="clearfix">
                    <Card.Text className="float-left">
                      Tax <strong>( {Tax}% )</strong>
                    </Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency(percentageAmount)}
                    </Card.Text>
                  </div>
                  <div className="clearfix">
                    <Card.Text className="float-left">Total</Card.Text>
                    <Card.Text className="float-right">
                      ₦ {formattCurrency(taxAmount + percentageAmount)}
                    </Card.Text>
                  </div>

                  <Formik
                    initialValues={{
                      customer_id: "",
                      discount: "",
                      payment_type: "Cash",
                      reference_no: "",
                      part_payment: "",
                    }}
                    // validationSchema={Yup.object({
                    //   customer_id: Yup.number()
                    //     .min(1, "please select from the options")
                    //     .required("Customer is a required field"),
                    // })}
                    onSubmit={async (values, { setSubmitting }) => {
                      if (values.payment_type === "POS") {
                        if (values.reference_no === "") {
                          setSubmitting(false);
                          return alert.error(
                            "reference number is needed for pos payment"
                          );
                        }
                      }
                      let totalAmount = (values.total_amount =
                        taxAmount + percentageAmount);
                      values.total_tax = Tax;
                      let balance = 0;
                      values.part_payment
                        ? (values.paid_amount = values.part_payment)
                        : (values.paid_amount = totalAmount);

                      values.business_id = match.params.id;

                      let serviceData = {
                        name: service.service_name,
                        id: service.id,
                        cost_of_service: service.cost_of_service,
                        part_payment: service.part_payment,
                      };
                      values.total_amount = totalAmount - values.discount;
                      values.services = new Array(serviceData);
                      values.type = "service";
                      values.total_tax = Tax;
                      console.log(values);

                      const response = await ClientSalesService.checkout(
                        values
                      );
                      if (response.status === 200) {
                        alert.success(response.data.message);
                        setSubmitting(false);
                        history.push(
                          `/sales/receipt/${match.params.id}/${response.data.data[0].order_id}`
                        );
                      }
                      if (response.response.data.data)
                        return alert.error(response.response.data.data[0]);
                    }}
                  >
                    {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Row className="mb-3">
                          {showDisc.showDis ? (
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                              className="text-primary"
                              onClick={toggleDiscountForm}
                            >
                              - Hide discount
                            </p>
                          ) : (
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                              className="text-primary"
                              onClick={toggleDiscountForm}
                            >
                              + Add discount
                            </p>
                          )}
                          {showDisc.showDis && (
                            <>
                              <Form.Control
                                type="text"
                                name="discount"
                                value={values.discount}
                                onChange={handleChange}
                              />
                              {touched.discount && errors.discount ? (
                                <div className="text-danger">
                                  {errors.discount}
                                </div>
                              ) : null}
                            </>
                          )}
                        </Form.Row>
                        {service && service.part_payment > 0 && (
                          <Form.Row className="mb-3">
                            {showPart.showPartPayment ? (
                              <p
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-primary"
                                onClick={togglePartpayment}
                              >
                                - Part payment
                              </p>
                            ) : (
                              <p
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-primary"
                                onClick={togglePartpayment}
                              >
                                + Part payment
                              </p>
                            )}
                            {showPart.showPartPayment && (
                              <>
                                <Form.Control
                                  type="number"
                                  name="part_payment"
                                  value={values.part_payment}
                                  onChange={handleChange}
                                />
                                {touched.part_payment && errors.part_payment ? (
                                  <div className="text-danger">
                                    {errors.discount}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </Form.Row>
                        )}

                        <Form.Row>
                          <Form.Group as={Col} md={12}>
                            {showCust.showCustomer ? (
                              <p
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-primary"
                                onClick={toggleCustomer}
                              >
                                - Customer
                              </p>
                            ) : (
                              <p
                                style={{
                                  cursor: "pointer",
                                }}
                                className="text-primary"
                                onClick={toggleCustomer}
                              >
                                + Customer
                              </p>
                            )}
                            {showCust.showCustomer && (
                              <Form.Control
                                as="select"
                                name="customer_id"
                                value={values.customer_id}
                                onChange={handleChange}
                              >
                                <option value="0">Choose...</option>
                                {users.map((user, i) => (
                                  <option value={user.id} key={i}>
                                    {user.first_name + " " + user.last_name}
                                  </option>
                                ))}
                              </Form.Control>
                            )}
                          </Form.Group>
                        </Form.Row>

                        {/* <div className="clearfix mt-4">
                          <Card.Text className="float-left">Total</Card.Text>
                          <Card.Text className="float-right">
                            ₦ {formattCurrency(total + (total / 100) * Tax)}
                          </Card.Text>
                        </div> */}

                        <Form.Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Check
                              type="radio"
                              label="Cash"
                              value="Cash"
                              defaultChecked={
                                values.payment_type === "Cash" ? true : false
                              }
                              name="payment_type"
                              id="Radios1"
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group as={Col} md={6}>
                            <Form.Check
                              type="radio"
                              label="POS"
                              value="POS"
                              onChange={handleChange}
                              name="payment_type"
                              id="Radios2"
                            />
                          </Form.Group>
                        </Form.Row>
                        {values.payment_type === "POS" ? (
                          <Form.Row className="mb-3">
                            <Form.Label>Reference Number</Form.Label>
                            <Form.Control
                              type="text"
                              name="reference_no"
                              value={values.reference_no}
                              onChange={handleChange}
                            />
                            {touched.reference_no && errors.reference_no ? (
                              <div className="text-danger">
                                {errors.reference_no}
                              </div>
                            ) : null}
                          </Form.Row>
                        ) : null}

                        <Button
                          className="bg-blue"
                          size="md"
                          type="submit"
                          block
                        >
                          CHECKOUT{" "}
                          {isSubmitting ? (
                            <span
                              id="login_spinner"
                              className="fa fa-spin fa-spinner"
                            ></span>
                          ) : null}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </PageBody>
    </Styles>
  );
};
