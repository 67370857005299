import React from 'react'

export const Nofile = () => (

    <div>
        <h4> We are lost bro!</h4>
    </div>
)
   
       

