import React, { useState, useEffect, createContext } from "react";
import { AuthService } from "../Services/authService";
import { ApiClient } from "../Util/ApiClient";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

const defaultProps = {
  login: async (model) => { },
  logout: () => { },
  isLoading: false,
  user: null,
  isReady: false,
  user_type: null,
  permissions: null,
  alert: () => { },
  refresh: () => { },
  SetisLoading: () => { },
  SetisReady: () => { },
};

export const AuthContext = createContext(defaultProps);
export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [isLoading, SetisLoading] = useState(false);
  const [isReady, SetisReady] = useState(false);
  const [user, setUser] = useState(null);
  const [user_type, setUsertype] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const alert = useAlert();
  const refresh = async () => {
    SetisLoading(true);
    try {
      const response = await AuthService.authUser();
      SetisReady(true);
      if (response.status === 200) {
        if (response.data.data.user.first_login === 1) {
          history.push("/first-login");
        }

        if (response.data.data.user.role !== null) {
          const permits = response.data.data.user.role.permissions;
          const permiss = [];
          const permitlength = permits.length;
          for (var i = 0; i < permitlength; i++) {
            if (permits !== []) {
              permiss.push(permits[i].id);
            } else {
              break;
            }
          }

          setPermissions(permiss);
        }
        setUser(response.data.data.user);
        setUsertype(response.data.data.user_type);
      } else {
        const unset = await ApiClient.unsetToken();
        if (unset === true) {
          setUser(null);
          setUsertype(null);
        }
      }
    } catch (err) {
      const unset = await ApiClient.unsetToken();
      if (unset === true) {
        setUser(null);
      }
    }
    SetisLoading(false);
    SetisReady(true);
  };

  const login = async (model) => {
    const response = await AuthService.login(model);

    if (response.status === 200) {
      if (response.data.data.user.first_login === 1) {
        setUser(response.data.data.user);
        setUsertype(response.data.data.user_type);
        await ApiClient.setToken(response.data.data.token);
        history.push("/first-login");
      } else {
        const token = await ApiClient.setToken(response.data.data.token);
        if (token === true) {
          setUser(response.data.data.user);
          setUsertype(response.data.data.user_type);
          alert.success("Login Successful");
          refresh();
        }
      }
    } else {
      alert.error("Invalid Credentials");
    }

  };

  const logout = async () => {
    await AuthService.logout();
    await ApiClient.unsetToken();
    setUser(null);
    alert.success("User logged out successfully");
    history.push("/login");
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        user_type,
        permissions,
        logout,
        refresh,
        isLoading,
        isReady,
        SetisReady,
        alert,
        SetisLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
