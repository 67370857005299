import React from "react";
import { Col, Container, Button, Card, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Nav from "../../../Component/Layouts/Nav";
import User from "../../../Assets/Img/user-icon.png";
import { PageBody } from "../../../Component/Loader";
import { useLoadingMessage } from "../../../Component/useLoadingMessage";
import { AuthContext } from "../../../Context/AuthContext";
import { permission, roles } from "../../../Util/Constants/constant";
import { usersThunk } from "../../../redux/action/users/users.thunk";
import { useDispatch, useSelector } from "react-redux";

const Styles = styled.div`
  .card-body p {
    font-size: 10px;
  }
`;

export const Users = ({ match }) => {
  const { permissions, user_type } = React.useContext(AuthContext);
  const [active, setActive] = React.useState();
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [search, setSearch] = React.useState("");
  const { userss, AllUsers } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const loadUsers = React.useCallback(async () => {
    updateLoader(true, "Fetching Users");
    if (match.params.id !== undefined) {
      await dispatch(usersThunk.loadUsers(match.params.id));
      setActive(1);
      updateLoader(false);
    } else {
      await dispatch(usersThunk.loadUsers());
      setActive(1);
      updateLoader(false);
    }
  }, [updateLoader, match.params.id, userss]);

  const getuserss = React.useCallback(async () => {
    updateLoader(true, "Fetching Users");
    if (match.params.id !== undefined) {
      await dispatch(usersThunk.getClinetUser(match.params.id, userss));
      setActive(1);
      updateLoader(false);
    } else {
      await dispatch(usersThunk.getAllUsers(userss));
      setActive(1);
      updateLoader(false);
    }
  }, [updateLoader, match.params.id, userss]);

  const getactiveusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Active Users");
    await dispatch(usersThunk.getActiveUsers(userss));
    setActive(2);
    updateLoader(false);
  }, [updateLoader, userss]);

  const getinactiveusers = React.useCallback(async () => {
    updateLoader(true, "Fetching Inactive Users");
    await dispatch(usersThunk.getInActiveUsers(userss));
    setActive(3);
    updateLoader(false);
  }, [updateLoader, match.params.id, userss]);

  const allUsers =
    AllUsers.data &&
    AllUsers.data.filter((user) => {
      return (
        user.first_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        user.last_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    });

  React.useEffect(() => {
    loadUsers();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Styles>
        <Nav />
        {allUsers && (
          <>
            <Container className="mt-4" fluid>
              <Form inline>
                <Col md={2}>
                  {user_type === roles.Admin &&
                  match.params.id === undefined ? (
                    permissions &&
                    permissions.includes(permission.user_create) === true ? (
                      <Link to="/create-user">
                        <Button className="btn-create shadow p-2 m-3">
                          Create User
                        </Button>
                      </Link>
                    ) : null
                  ) : null}
                </Col>

                <Col md={3}>
                  <div className="input-group">
                    <input
                      className="form-control py-2 border-right-0 border"
                      type="search"
                      placeholder="search"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="input-group-append">
                      <div className="input-group-text bg-transparent">
                        <i className="fa fa-search"></i>
                      </div>
                    </span>
                  </div>
                </Col>
                <Col className="" md={4}>
                  <Button
                    className={
                      active === 1 ? "btn-filter-all shadow m-1" : "btn-filter"
                    }
                    onClick={() => {
                      getuserss();
                    }}
                  >
                    All
                  </Button>
                  {/* {user_type === roles.Admin && match.params.id === undefined ? 
                    <> */}
                  <Button
                    className={
                      active === 2 ? "btn-filter-all shadow m-1" : "btn-filter"
                    }
                    onClick={() => {
                      getactiveusers();
                    }}
                  >
                    Active
                  </Button>
                  <Button
                    className={
                      active === 3 ? "btn-filter-all shadow m-1" : "btn-filter"
                    }
                    onClick={() => {
                      getinactiveusers();
                    }}
                  >
                    Inactive
                  </Button>
                  {/* </>
                     :null} */}
                </Col>
                <Col md={3}>
                  <div className="breadcrum">
                    <ul>
                      <Link to="/dashboard">
                        <li>Home</li>
                      </Link>
                      <li>Users</li>
                    </ul>
                  </div>
                </Col>
              </Form>
            </Container>
            <Container fluid>
              <Row className="mt-5">
                {allUsers < 1 ? (
                  <div className="text-center m-auto">
                    <h1>No record found</h1>
                  </div>
                ) : (
                  allUsers.map((user, i) => (
                    <Col md={3} key={i} className="mb-5">
                      <Card className="text-center card-user">
                        <center>
                          <img
                            className="user-img"
                            src={User}
                            alt="user icon"
                          />
                        </center>
                        <Card.Body className="mt-4">
                          <p className="p-0 mt-3">
                            {" "}
                            <i
                              className={
                                user.status === 0
                                  ? "text-danger fa fa-circle"
                                  : "text-success fa fa-circle"
                              }
                            ></i>
                          </p>

                          <Card.Title>
                            <strong>
                              {user !== null
                                ? user.first_name + " " + user.last_name
                                : null}
                            </strong>
                          </Card.Title>

                          <small>{user.email}</small>
                          <p>{user.phonenumber}</p>
                          {user_type === roles.Admin ? (
                            <em className="text-danger">
                              {user.role && user.role.name}
                            </em>
                          ) : null}
                          <p></p>
                          {user_type === roles.Admin ? (
                            permissions &&
                            permissions.includes(permission.user_edit) ===
                              true ? (
                              <Link to={`/edit-user/${user.id}`}>
                                <Button className="btn-create-two shadow m-3">
                                  <small>Edit</small>
                                </Button>
                              </Link>
                            ) : null
                          ) : null}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                )}
              </Row>
            </Container>
          </>
        )}
      </Styles>
    </PageBody>
  );
};
